import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Button from "CustomButtons/Button.jsx";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

/*import { withStyles } from '@material-ui/core/styles';*/

/*const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 1,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});
*/


export default class QuestionFooter extends React.Component {

render() {
	const { nextQ, prevQ,lastQ, firstQ, markedForReview, markForReviewChanged, handleClearResponse, showSolutions} = this.props;
	return(
		<div style={{position: "fixed", bottom: "0px", width: "75%", margin: "auto", paddingRight: "8px", maxHeight: "50px"}}>
		<GridContainer justify="space-between" style={{margin: "auto", backgroundColor: "rgb(202, 211, 234)"}}>
			<GridItem  xs={12} sm={12} md={2} lg={2} style={{margin: "auto",textAlign: "start"}}>
				{showSolutions ? null	
				: <Button size={"sm"} color="primary" disabled={showSolutions} onClick={handleClearResponse}>Clear Response</Button>
				}
			</GridItem>
			<GridItem  xs={12} sm={12} md={6} lg={6} style={{textAlign: "end"}}>
				{showSolutions ? null
				: <FormControlLabel
		          control={
		            <Checkbox
		              checked={markedForReview ? markedForReview : false}
		              onChange={markForReviewChanged}
		              color="primary"
		              disabled={showSolutions}
		            />
		          }
		          label="Mark For Review"
		        />
		    	}
			</GridItem> 
			<GridItem xs={12} sm={12} md={4} lg={4} style={{margin: "auto",textAlign: "end"}}>
				<Button size={"sm"} 
				color="success" 
				style={{marginRight: "5px"}}
				disabled={firstQ}
				onClick={prevQ}><i className="material-icons">navigate_before</i> Previous</Button>
				<Button size={"sm"} 
				color="success"
				disabled={lastQ}
				onClick={nextQ}>Next <i className="material-icons">navigate_next</i></Button>
			</GridItem> 			
		</GridContainer>
		</div>
		);
}

}