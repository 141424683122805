import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = {
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    //width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5, //10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
};

class CustomSearchBar extends React.Component {
state = {
  searchText: ""
};

handleSearchText = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var obj ={};
    obj[id] = e.target.value;
    this.setState(obj);
    this.props.handleSearch(e.target.value);
  }

handleEnterKeyPress = (e) => {
    if(e.key === 'Enter'){
      this.props.handleSearch(this.state.searchText);
    }
  }

render() {
  const { classes, inputPlaceholder, hideMenuIcon, child1, child2, handleSearch, hintText } = this.props;

  return (
    <React.Fragment>
    <Paper className={classes.root} elevation={1}>
      { !hideMenuIcon ?
      <IconButton className={classes.iconButton} aria-label="Menu">
        <MenuIcon />
      </IconButton>
      : null }
      <InputBase 
      id="searchText"
      onChange={this.handleSearchText}
      onKeyPress={this.handleEnterKeyPress}
      value={this.state.searchText} className={classes.input} placeholder={inputPlaceholder}/>
      <IconButton className={classes.iconButton} aria-label="Search">
        <SearchIcon/>
      </IconButton>
      {/*<Divider className={classes.divider} />
      <IconButton color="primary" className={classes.iconButton} aria-label="Advanced">
         <MenuIcon />
      </IconButton>*/}
      {child1? 
      <Divider className={classes.divider} />
      : null}
      {child1}
      {child2? 
      <Divider className={classes.divider} />
      : null}
      {child2}
    </Paper>
    {hintText && 
      <FormHelperText style={{marginLeft: "10px"}}>{hintText}</FormHelperText>
    }
    </React.Fragment>
  );
}
}

CustomSearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

CustomSearchBar.defaultProps = {
  hideMenuIcon: true
};
export default withStyles(styles)(CustomSearchBar);