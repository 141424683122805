import React from "react";

/*import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";*/

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import QuestionImages from "components/test/view/QuestionImages.jsx";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

/*import test_img1 from "assets/img/image1.png";*/
import QuestionTextInput from "components/test/view/QuestionTextInput.jsx";
import QuestionText from "components/test/view/QuestionText.jsx";


 export default  class SingleAnswerOptions extends React.Component {

	constructor(props) {
	    super(props);
	     this.state = {
		    value: "",
		  };
	  }
	
render() {
	 const { options,answer, answerChanged, AnswerKey,
	 	isEditMode,handleEditTestData,handlePasteEvent,handleRemoveImageComponent,handleImageFileUpload, dontShowLabel,noImageAttachment} = this.props;
	 const showSolutions = true;
	 const keyStyle = {
	 	backgroundColor: "#00800033",
	 	paddingTop: "5px"
	 }
	 const style3={
		fontFamily: "serif",
		fontSize: "1rem",
		color: "rgb(60, 72, 88)",
		fontWeight: "300",
		lineHeight: "1.55em"
		};
	return (
		<GridContainer style={{margin: "auto"}}>
			<GridItem xs={12} sm={12} md={12} lg={12}>
				<RadioGroup
		            aria-label="Gender"
		            name="option"
		            value={answer}
		            onChange={answerChanged}

		            style={{backgroundColor: "transparent"}}
		          >
				{options && options.map((item, index) => (
					<FormControlLabel 
					key={index} 
					value={item.label} 
					style ={ showSolutions && AnswerKey == item.label ? keyStyle : {} }
					disabled={showSolutions} 
					control={<Radio />} 
					label={
						isEditMode ?				
							item.content && item.content.map((itemContent, index2) => (
							  <React.Fragment>
							{ index2%2 == 0 &&
							  <QuestionTextInput text={itemContent} name={"options_"+item.label+"_"+index2}
							  rows="1"
							  isSingleComponent={true}
							  noImageAttachment={noImageAttachment}
							  label={item.label} 
							  handleEditTestData={handleEditTestData}
							  handlePasteEvent={handlePasteEvent}
							  handleImageFileUpload={handleImageFileUpload}
							  />
							}
							{ index2%2 == 1 && itemContent &&
								<QuestionImages 
								src={itemContent} 
								label={item.label} 
								name={"options_"+item.label+"_"+index2}
								isEditMode={isEditMode}
								handleRemoveImageComponent={handleRemoveImageComponent} 
								/>
							}
							</React.Fragment>
							))
						:	<React.Fragment>
							{item.content && item.content.map((itemContent, index3) => (
				 		     	<React.Fragment key={index3}>
				 		     	{ index3%2 == 0 && 
									<QuestionText text={(index3 == 0 && !dontShowLabel) ? item.label +") " +itemContent : itemContent }/>
								}
								{index3%2 == 1 && itemContent &&
								 	<QuestionImages 
									src={itemContent} 
									name={"option_"+item.label+'_'+index3}
									isEditMode={false}/> 
								}
								</React.Fragment>
							))}
							</React.Fragment>
						} 
					/>
				))}
				</RadioGroup>
			</GridItem> 
		</GridContainer>
	);
}

}