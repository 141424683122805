import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';

import PropTypes from "prop-types"

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import CustomInput from "CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

import ShortText from "@material-ui/icons/ShortText";
import MUIDataTable from "mui-datatables";

import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import TestModelFooter from 'components/testModel/TestModelFooter';
import Subjects from 'components/lov/Subjects';
import QuestionPatterns from 'components/lov/QuestionPatterns';


import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "CustomButtons/Button.jsx";

import TestModelFormStyle from "styles/TestModelFormStyle.js";

import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "components/ErrorBoundary.js";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'query-string';
import ErrorLogo from "@material-ui/icons/Error";


const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MuiTableCell:{
        root:{
      		padding: "4px 16px 4px 16px",
      		textAlign: "center"
      	},
      	footer:{
      		textAlign: "left"
      	}
      },
    typography: {
      useNextVariants: true,
    },
    },
    typography: {
      useNextVariants: true,
    }
  })


class TestModelForm extends React.Component {

constructor(props) {
	super(props);
	this.state = {
	  errorMessages: [],
	  testModelData: [], //each JSON array child indicates row matching table elements in order
	  newSectionrowIndexArray: [],
	  testModelTotalQuestions: 0,
	  testModelTotalMarks: 0,
	  testModelInfo: ["","",""], //three elements in order include test model name, number and description
	  existingTestModelId: "",
	  isViewMode: "",
	  viewport: ""
	};
}

addSectionHandler = () => {
	var data = this.state.testModelData;
	data.push([data.length+1,"","","","","","","","",""]); 

	var newSectionrowIndexArray = this.state.newSectionrowIndexArray;
	newSectionrowIndexArray.push(data.length-1);
	this.reorderSectionsAndQuestions(data);
	this.setState({
		testModelData: data,
		newSectionrowIndexArray: newSectionrowIndexArray
	});
}

componentDidMount(){
	//GET EXISITING TEST MODEL DATA IF ANY
	const test_model_id = qs.parse(this.props.location.search).test_model_id;
	const mode = qs.parse(this.props.location.search).mode;
	const viewport = qs.parse(this.props.location.search).viewport;
	if (viewport && viewport == "embedded"){
        this.setState({viewport})
      } 
	if(mode == "view"){
		this.setState({isViewMode: "Y"});
	}

	if(test_model_id){
		this.setState({existingTestModelId: test_model_id})
		$.ajax({
	      type: 'GET',
	      url: getBaseURL() + '/get-template-details.json?id=' + test_model_id,
	    }).done((data, status, jqXHR)=>{
	    	this.populateTestModelDetails(data);
	    }).fail((jqXHR, textStatus, errorThrown) => {
	    	var errorMessages = this.state.errorMessages;
	    	errorMessages.push({message: "An Unexpected Error occured while trying to dulicate the test."})
	        this.setState({errorMessages: errorMessages});
	    })
	}

}

populateTestModelDetails(data){
	if(data["template_info"] && data["template_details"]){
		var testModelInfo = this.state.testModelInfo;
		testModelInfo[0] = data["template_info"].name;
		testModelInfo[1] = data["template_info"].identifier;
		testModelInfo[2] = data["template_info"].description;

		var testModelData = [];
		for(var i in data["template_details"]){
			testModelData.push([data.length+1,"",
				data["template_details"][i].subject_short_name,
				data["template_details"][i].pattern_short_name,
				parseInt(data["template_details"][i].last_question_no)-parseInt(data["template_details"][i].first_question_no) + 1 ,
				data["template_details"][i].positive_marks,
				data["template_details"][i].negative_marks,
				data["template_details"][i].partial_marks,
				data["template_details"][i].required_questions,
				""]); 
		} 
		this.reorderSectionsAndQuestions(testModelData);
		this.recalculateTestModelQuestionsAndMarks(testModelData);
		this.setState({testModelInfo,testModelData})
	}
}

handleOnChangeTestModelInfo = (e) => {
 	e.preventDefault();	
 	var targetId = e.currentTarget.id;
 	var index = targetId.split("_")[1];
 	var data = this.state.testModelInfo;
 	data[index] = e.currentTarget.value;
 	this.setState({
 		testModelInfo: data
 	});
}

handleOnChangeSections = (e) => {
 	e.preventDefault();	
 	var tableIndex = e.currentTarget.id;
 	var rowIndex = tableIndex.split("_")[0];
 	var columnIndex = tableIndex.split("_")[1];
 	var data = this.state.testModelData;
 	data[rowIndex][columnIndex] = e.currentTarget.value;
 	this.setState({
 		testModelData: data
 	});
}

handleLovChangeSections = (e) => {
	e.preventDefault();
	var tableIndex = e.target.name;
	var rowIndex = tableIndex.split("_")[0];
 	var columnIndex = tableIndex.split("_")[1];
 	var data = this.state.testModelData;
 	data[rowIndex][columnIndex] = e.target.value;
	this.setState({
 		testModelData: data
 	});
}

handleSaveSection = (e) => {
 	e.preventDefault();	
 	var newSectionrowIndexArray = this.state.newSectionrowIndexArray;
	var rowIndex = newSectionrowIndexArray.indexOf(parseInt(e.currentTarget.id,10));
	if (rowIndex > -1) {
	  newSectionrowIndexArray.splice(rowIndex, 1);
	}
	
	var data = this.state.testModelData;
	this.reorderSectionsAndQuestions(data);
	this.recalculateTestModelQuestionsAndMarks(data);
 	this.setState({
 		newSectionrowIndexArray: newSectionrowIndexArray,
 		testModelData: data
 	});
}

handleClearSection = (e) => {
 	e.preventDefault();
 	var data = this.state.testModelData;
	data.splice(parseInt(e.currentTarget.id), 1);
	this.reorderSectionsAndQuestions(data);
	this.recalculateTestModelQuestionsAndMarks(data);
	var newSectionrowIndexArray = this.state.newSectionrowIndexArray;
	var rowIndex = newSectionrowIndexArray.indexOf(parseInt(e.currentTarget.id));
	if (rowIndex > -1) {
	  newSectionrowIndexArray.splice(rowIndex, 1);
	  newSectionrowIndexArray = newSectionrowIndexArray.map((item, index) => {
	  	if(index >= rowIndex){
	  		item = parseInt(item)-1;
	  	}
	  	return item;
	  })
	}
 	this.setState({
 		testModelData: data,
 		newSectionrowIndexArray: newSectionrowIndexArray,
 	});
}

reorderSectionsAndQuestions(data){
	var currentRow = null;
	var questionsCount = 0;
	for (var i = 0; i < data.length; i++) {
	      currentRow = data[i]
	      currentRow[0] = (i+1);
	      currentRow[1] = currentRow[4] != "" ? (parseInt(questionsCount)+1) + " - " + (parseInt(questionsCount)+parseInt(currentRow[4])) : "";
	      questionsCount = parseInt(parseInt(questionsCount)+parseInt(currentRow[4]));
	}
}

recalculateTestModelQuestionsAndMarks(data){
	var currentRow = null;
	var questionsCount = 0;
	var totalMarks = 0;
	for (var i = 0; i < data.length; i++) {
		currentRow = data[i]
	   	questionsCount = currentRow[4] != "" ? parseInt(parseInt(questionsCount)+parseInt(currentRow[4]))
	   						: questionsCount;
		
		//Optional Questions handling
		var questions = currentRow[4]
		if(currentRow[4] && currentRow[8] && parseInt(currentRow[8]) < parseInt(currentRow[4])){
			questions = currentRow[8]
		}
		
		totalMarks = questions != "" && currentRow[5] != "" ? parseInt(parseInt(totalMarks)+parseInt(questions*currentRow[5]))
							: totalMarks;
		
	}
	this.setState({
		testModelTotalQuestions: questionsCount,
		testModelTotalMarks: totalMarks
	});
}

handleEditSection = (e) => {
 	e.preventDefault();	
	var newSectionrowIndexArray = this.state.newSectionrowIndexArray;
	newSectionrowIndexArray.push(parseInt(e.currentTarget.id,10));
	
	this.setState({
 		newSectionrowIndexArray: newSectionrowIndexArray
 	});
}

processTestModelDataForSave(testModelData){
	var templateDetails = {};
	var questionsCount = 0;
	var currentRow = '';
	if(testModelData.length > 0){
		for (var i = 0; i < testModelData.length; i++) {
	    	currentRow = testModelData[i];
	    	templateDetails["subjects"] = templateDetails["subjects"] ? templateDetails["subjects"] +','+ currentRow[2] : currentRow[2]
	    	templateDetails["questionTypes"] = templateDetails["questionTypes"] ?  templateDetails["questionTypes"] +','+ currentRow[3] : currentRow[3]
		 	templateDetails["positiveMarks"] = templateDetails["positiveMarks"] ?  templateDetails["positiveMarks"] +','+ currentRow[5] : currentRow[5]
		 	templateDetails["negativeMarks"] = templateDetails["negativeMarks"] ? templateDetails["negativeMarks"] +','+ currentRow[6] : currentRow[6]
		 	templateDetails["partialMarks"] = templateDetails["partialMarks"] ? templateDetails["partialMarks"] +','+ currentRow[7] : currentRow[7]
		 	templateDetails["startQuestions"] = templateDetails["startQuestions"] ? templateDetails["startQuestions"] +','+ (parseInt(questionsCount)+1) : (parseInt(questionsCount)+1)
		 	templateDetails["endQuestions"] = templateDetails["endQuestions"] ? templateDetails["endQuestions"] +','+ (parseInt(questionsCount)+parseInt(currentRow[4])) : (parseInt(questionsCount)+parseInt(currentRow[4]))
		 	var reqQuestions = currentRow[8] ? currentRow[8]: currentRow[4]
		 	templateDetails["requiredQuestions"] = templateDetails["requiredQuestions"] ?  templateDetails["requiredQuestions"] +',' + reqQuestions : reqQuestions
		 	questionsCount = parseInt(parseInt(questionsCount)+parseInt(currentRow[4]));

		}
	}
	return templateDetails;
}

handleSaveTestModel = (e) => {
	e.preventDefault();
	var testModelInfo = this.state.testModelInfo;
	if(testModelInfo[0] == "" || testModelInfo[1] == ""){
		alert("Provide Test Model Name and Identifier");
		return
	}
	var testModelData = this.state.testModelData;
	if(testModelData.length == 0){
		alert("Provide atleast a single section");
		return
	}
	if(this.state.newSectionrowIndexArray.length > 0){
		alert("Please Save All Sections and proceed");
	}
	var templateDetails = this.processTestModelDataForSave(testModelData);
	var testModelPostData = { test_template_info: {
		name: testModelInfo[0],
		identifier: testModelInfo[1],
		description: testModelInfo[2],
		total_marks: this.state.testModelTotalMarks,
		total_questions: this.state.testModelTotalQuestions,
		template_details_question_types_shortNames:templateDetails["questionTypes"],
		template_details_start_question_nos:templateDetails["startQuestions"],
		template_details_end_question_nos:templateDetails["endQuestions"],
		template_details_marks:templateDetails["positiveMarks"],
		template_details_negative_marks:templateDetails["negativeMarks"],
		template_details_partial_marks:templateDetails["partialMarks"],
		template_details_subject_shortNames:templateDetails["subjects"],
		template_details_required_questions:templateDetails["requiredQuestions"],
		}
	}

	if(this.state.existingTestModelId){
		testModelPostData["test_template_info"].action = "update"
	}

    $.ajax({
      type: 'POST',
      url: getBaseURL() + '/test_template_infos',
      data: testModelPostData
    }).done((response, status, jqXHR)=>{
      	this.props.history.push('/test_infos/new');
    }).fail((jqXHR, textStatus, error)=>{
    	alert(jqXHR["responseText"]);
		return
    })
}

handleCancelAction = (e) => {
	e.preventDefault();
	if(this.state.viewport == "embedded"){
      	this.props.history.goBack()
    }else{
		this.props.history.push('/test_infos/new');
	}
}

toggleEditMode = (e) =>{
	e.preventDefault();
	var existingTestModelId = this.state.existingTestModelId;
	if(existingTestModelId){
		this.props.history.push('/test_template_infos?test_model_id='+existingTestModelId+'&mode=edit');
	}else{
		this.props.history.push('/test_template_infos?mode=edit');
	}
	this.setState({isViewMode: false})
}

  render () {
  	const { classes } = this.props;
  	const { testModelInfo, testModelTotalQuestions, testModelTotalMarks,
  		existingTestModelId,isViewMode } = this.state;
  	const titleStyle={
		    color: "rgb(34, 56, 69)",
    		fontWeight: "600",
    		fontSize: "20px",
    		paddingLeft: "20px",
		};
	const subtitleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "18px",
		fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
		paddingBottom: "10px"
	};
	const customColumns = [
	{
		name: "Section"
	},
	"Questions",
	{
		name: "Subject",
		options: {
	  		customBodyRender: (value, tableMeta, updateValue) => {
		    return (
		    <div>
			    { this.state.newSectionrowIndexArray.includes(tableMeta.rowIndex) ? 
			      <Subjects
					name={(tableMeta.rowIndex).toString() +"_"+(tableMeta.columnIndex).toString()}
	                value={value}
	                index={tableMeta.columnIndex}
	                change={this.handleLovChangeSections}
	              />
		        : value 
		        }
	        </div>
		    );
	  		}
		}
	},
	{
		name: "Pattern",
		options: {
	  		customBodyRender: (value, tableMeta, updateValue) => {
		    return (
		    <div>
			    { this.state.newSectionrowIndexArray.includes(tableMeta.rowIndex) ? 
			      <QuestionPatterns
			      	name={(tableMeta.rowIndex).toString() +"_"+(tableMeta.columnIndex).toString()}
	                value={value}
	                index={tableMeta.columnIndex}
	                change={this.handleLovChangeSections}
	              />
		        : value 
		        }
	        </div>
		    );
	  		}
		}
	}, 
	{
		name: "Total Questions",
		options: {
	  		customBodyRender: (value, tableMeta, updateValue) => {
		    return (
		    <div>
			    { this.state.newSectionrowIndexArray.includes(tableMeta.rowIndex) ? 
			      <CustomInput
		            //labelText="Enter Section"
		            id="material2"
		            formControlProps={{
		                fullWidth: true
		            }}
		            inputProps={{
		            	id: (tableMeta.rowIndex).toString() +"_"+(tableMeta.columnIndex).toString(),
		                style: {fontSize: "16px"},
		                value: tableMeta.rowData[4],
		                onChange: this.handleOnChangeSections,
		                placeholder: "Number"
		            }}
		        	/>
		        	: value 
		        }
	        </div>
		    );
	  		}
		}
	},
	{
		name: "Positive Marks",
		options: {
	  		customBodyRender: (value, tableMeta, updateValue) => {
		    return (
		    <div>
			    { this.state.newSectionrowIndexArray.includes(tableMeta.rowIndex) ? 
			      <CustomInput
		            //labelText="Enter Section"
		            id="material2"
		            formControlProps={{
		                fullWidth: true
		            }}
		            inputProps={{
		            	id: (tableMeta.rowIndex).toString() +"_"+(tableMeta.columnIndex).toString(),
		                style: {fontSize: "16px"},
		                value: tableMeta.rowData[5],
		                onChange: this.handleOnChangeSections,
		                placeholder: "+ Marks"
		            }}
		        	/>
		        	: value 
		        }
	        </div>
		    );
	  		}
		}
	},
	{
		name: "Negative Marks",
		options: {
	  		customBodyRender: (value, tableMeta, updateValue) => {
		    return (
		    <div>
			    { this.state.newSectionrowIndexArray.includes(tableMeta.rowIndex) ? 
			      <CustomInput
		            //labelText="Enter Section"
		            id="material2"
		            formControlProps={{
		                fullWidth: true
		            }}
		            inputProps={{
		            	id: (tableMeta.rowIndex).toString() +"_"+(tableMeta.columnIndex).toString(),
		                style: {fontSize: "16px"},
		                value: tableMeta.rowData[6],
		                onChange: this.handleOnChangeSections,
		                placeholder: "- Marks"
		            }}
		        	/>
		        	: value 
		        }
	        </div>
		    );
	  		}
		}
	},
	{
		name: "Partial Marks",
		options: {
	  		customBodyRender: (value, tableMeta, updateValue) => {
		    return (
		    <div>
			    { this.state.newSectionrowIndexArray.includes(tableMeta.rowIndex) ? 
			      <CustomInput
		            //labelText="Enter Section"
		            id="material2"
		            formControlProps={{
		                fullWidth: true
		            }}
		            inputProps={{
		            	id: (tableMeta.rowIndex).toString() +"_"+(tableMeta.columnIndex).toString(),
		                style: {fontSize: "16px"},
		                value: tableMeta.rowData[7],
		                onChange: this.handleOnChangeSections,
		                placeholder: "Marks"
		            }}
		        	/>
		        	: value 
		        }
	        </div>
		    );
	  		}
		}
	},
	{
		name: "Required Questions",
		options: {
	  		customBodyRender: (value, tableMeta, updateValue) => {
		    return (
		    <div>
			    { this.state.newSectionrowIndexArray.includes(tableMeta.rowIndex) ? 
			      <CustomInput
		            //labelText="Enter Section"
		            id="material2"
		            formControlProps={{
		                fullWidth: true
		            }}
		            inputProps={{
		            	id: (tableMeta.rowIndex).toString() +"_"+(tableMeta.columnIndex).toString(),
		                style: {fontSize: "16px"},
		                value: tableMeta.rowData[8] ? tableMeta.rowData[8] : tableMeta.rowData[4],
		                onChange: this.handleOnChangeSections,
		                placeholder: "Number"
		            }}
		        	/>
		        	: value ? value : tableMeta.rowData[4]
		        }
	        </div>
		    );
	  		}
		}
	}, 
	{
		name: "",
		options: {
	  		customBodyRender: (value, tableMeta, updateValue) => {
		    return (
		    	!this.state.isViewMode && 
		    	<React.Fragment>
			    <ErrorBoundary>
			    { this.state.newSectionrowIndexArray.includes(tableMeta.rowIndex) ? 
			    <React.Fragment>
			    <GridContainer style={{marginRight: "0px"}}>
			    <GridItem  xs={12} sm={12} md={6}>   
			        <Tooltip title={"save"}>
			        	<IconButton id={tableMeta.rowIndex} className={classes.iconButton} onClick={this.handleSaveSection}>
			            	<SaveIcon className={classes.deleteIcon} />
			           </IconButton>
			        </Tooltip>
			    </GridItem>
			    <GridItem  xs={12} sm={12} md={6}>
			        <Tooltip title={"delete"}>
			           <IconButton id={tableMeta.rowIndex} className={classes.iconButton} onClick={this.handleClearSection}>
			            	<ClearIcon className={classes.deleteIcon} />
			           </IconButton>
			        </Tooltip>
			    </GridItem>
			    </GridContainer>
			    </React.Fragment>
			    :
			    <React.Fragment>
			    <GridContainer>
			    <GridItem  xs={12} sm={12} md={6}>
      			 <Tooltip title={"Edit"}>
			          <IconButton id={tableMeta.rowIndex} className={classes.iconButton} onClick={this.handleEditSection}>
			            	<EditIcon className={classes.deleteIcon} />
			           </IconButton>   
			        </Tooltip>
      			</GridItem>
			    <GridItem  xs={12} sm={12} md={6}>
			        <Tooltip title={"delete"}>
			           <IconButton id={tableMeta.rowIndex} className={classes.iconButton} onClick={this.handleClearSection}>
			            	<ClearIcon className={classes.deleteIcon} />
			           </IconButton>
			        </Tooltip>
			    </GridItem>
			    </GridContainer>
			    </React.Fragment>
		        }
		        </ErrorBoundary>
		        </React.Fragment>
		        
		    );
	  		}
		}
	}
	];
	const options = {
		filter: false,
		selectableRows: 'none',
		search: false,
		print: false,
		download: false,
		viewColumns: false,
		responsive: 'stacked',
		rowsPerPage: 30,
		setRowProps: (row) => {
	        return {
	          style: {textAlign: 'center'}
	        };
	      },
	    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
	        return (  
	         !this.state.isViewMode && <TestModelFooter changePage={changePage} count={count} 
	          handleAddSection={this.addSectionHandler}/>
	        );
    	}
	}
    return (
      <React.Fragment>
      <ErrorBoundary>
      	<GridContainer style={{
      	backgroundColor: "white",
		paddingTop: "0px",
      	paddingBottom: "20px",
      	width: "100%", 
      	margin: "10px 0px 0px",
      	marginTop: "0px"}}
      	spacing={24} >
      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
      			<h1 style={titleStyle} >{isViewMode ? "View Test Model"   : existingTestModelId ? "Edit Test Model" :"Create Test Model"}</h1>
      			<hr/>
      		</GridItem>
      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px", 
      		marginBottom: "-50px", zIndex: "1"}}>
      			<List className={classes.list}>
      			<ListItem className={classes.listItem}>
      			    <Button
      			      color="transparent"
      			      onClick={this.handleCancelAction}
      			      className={classes.navLink}
      			      style={{ 
          			  paddingTop: "8px",
          			  paddingBottom: "8px",
          			  paddingLeft: "20px",
          			  paddingRight: "20px"}}
      			      name="Cancel"
      			      size="sm"
      			    >
      			       Cancel
      			    </Button>
      			  </ListItem>
      			  {!isViewMode &&
      			  <ListItem className={classes.listItem}>
      			    <Button
      			      color="transparent"
      			      onClick={this.handleSaveTestModel}
      			      style={{ 
          			  paddingTop: "8px",
          			  paddingBottom: "8px",
          			  paddingLeft: "20px",
          			  paddingRight: "20px"}}
      			      className={classes.navLink}
      			      name="Save Test Model"
      			      size="sm"
      			    >
      			       {existingTestModelId ? "Update Test Model" : "Save Test Model"}
      			    </Button>
      			  </ListItem>
      			  }
      			  {isViewMode &&
      			  <ListItem className={classes.listItem}>
      			    <Button
      			      color="transparent"
      			      onClick={this.toggleEditMode}
      			      style={{ 
          			  paddingTop: "8px",
          			  paddingBottom: "8px",
          			  paddingLeft: "20px",
          			  paddingRight: "20px"}}
      			      className={classes.navLink}
      			      name="Edit Test Model"
      			      size="sm"
      			    >
      			       Edit
      			    </Button>
      			  </ListItem>
      			  }
      			</List>
      		</GridItem>
      		{this.state.errorMessages && this.state.errorMessages.length > 0 &&
	    	<React.Fragment>
	    		<GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
	      		</GridItem>
	    		<GridItem xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
	    		{this.state.errorMessages && this.state.errorMessages.length > 0 ? this.state.errorMessages.map((errorMessage, index) => 
	                <div key={index}>
	                <ErrorLogo className={classes.icon} style={{verticalAlign: "middle", marginRight: "5px", color: "#f44336"}}></ErrorLogo>
	                <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit"}} className={classes.robotoFontRegular}>{errorMessage["message"]}</p> 
	                </div>
	                ): null
	              }
	            </GridItem>
	        </React.Fragment>
        	}
      		<GridItem  xs={12} sm={12} md={2}>
      		</GridItem>
      		<GridItem  xs={12} sm={12} md={3}>
      			<h3 style={subtitleStyle} >Test Model Details</h3>
      			<InputLabel shrink htmlFor="material">
          			Test Model Name
        		</InputLabel>
      			<CustomInput
                  //labelText="Enter Test Model Name"
                  id="material"
                  formControlProps={{
                      fullWidth: true,
                      style: {paddingTop: "5px"}
                  }}
                  inputProps={{
                      style: existingTestModelId ? {fontSize: "16px", backgroundColor: "#eeeeee"} : {fontSize: "16px"},
                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
                      placeholder: "Enter Name",
                      value: testModelInfo[0],
                      onChange: this.handleOnChangeTestModelInfo,
                      id: "testModelInfo_0",
                      disabled: existingTestModelId ? true : false
                  }}
              	/>
              	<InputLabel shrink htmlFor="material2">
          			Test Model Number
        		</InputLabel>
              	<CustomInput
                  //labelText="Enter Test Model Identifier"
                  id="material2"
                  formControlProps={{
                      fullWidth: true,
                      style: {paddingTop: "5px"}
                  }}
                  inputProps={{
                      style: existingTestModelId ? {fontSize: "16px", backgroundColor: "#eeeeee"} : {fontSize: "16px"},
                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
                      placeholder: "Enter Unique Key",
                      value: testModelInfo[1],
                      onChange: this.handleOnChangeTestModelInfo,
                      id: "testModelInfo_1",
                      disabled: existingTestModelId ? true : false
                  }}
              	/>
              	<InputLabel shrink htmlFor="material3">
          			Test Model Description
        		</InputLabel>
            	<CustomInput
                  
                  id="material3"
                  formControlProps={{
                      fullWidth: true,
                      style: {paddingTop: "5px"}
                  }}
                  inputProps={{
                      style: isViewMode ? {fontSize: "16px", backgroundColor: "#eeeeee"} : {fontSize: "16px"},
                      rows: "4",
                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
                      placeholder: "Enter Description",
                      value: testModelInfo[2],
                      onChange: this.handleOnChangeTestModelInfo,
                      id: "testModelInfo_2",
                      disabled: isViewMode ? true : false
                  }}
              	/>
      		</GridItem>
      		<GridItem  xs={12} sm={12} md={1}>
      		</GridItem>
      		<GridItem  xs={12} sm={12} md={3}>
      			<div style={{paddingTop: "35px"}}>
      			<InputLabel shrink htmlFor="material">
          			Total Questions
        		</InputLabel>
      			<h3 style={{fontSize: "16px"}}>{testModelTotalQuestions}</h3>
      			<div style={{padding: "10px"}}/>
              	<InputLabel shrink htmlFor="material2">
          			Total Marks
        		</InputLabel>
              	<h3 style={{fontSize: "16px"}}>{testModelTotalMarks}</h3>
              	</div>
      		</GridItem>
      		<GridItem  xs={12} sm={12} md={3}>
      		</GridItem>
      		<GridItem  xs={12} sm={12} md={12} >
      			<MuiThemeProvider theme={getMuiThemeForTable}>
      			<MUIDataTable
      			  title={<p style={subtitleStyle} >Test Model Sections</p>}
                 data={this.state.testModelData}
                 columns={customColumns}
                 options={options}
      			/>
      			</MuiThemeProvider>
      		</GridItem>
      	</GridContainer>
      	</ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default  withStyles(TestModelFormStyle)(TestModelForm);
