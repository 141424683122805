import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import Avatar from '@material-ui/core/Avatar';
import MetadReadLogo from 'assets/img/images/metaread_logo.png'
import Button from '@material-ui/core/Button';

class MetaReadHeader extends React.Component {

  logoutUser =(e)=> {
    $.ajax({
      type: 'GET',
      url: getBaseURL() + '/global_users/sign_out'
    }).done((response, status, jqXHR)=>{
      sessionStorage.removeItem('as_username');
      sessionStorage.removeItem('as_isUserAnalyst');
      sessionStorage.removeItem('as_isUserMetaReadAdmin');
      sessionStorage.removeItem('user');
      this.redirectToLoginPage();
    }) 
  }

  redirectToLoginPage(){
    if(this.props.history){
      this.props.history.push("/");
    }else{
      window.location.href = getBaseURL() + "/"
    }
  }

	render(){
		const {actionButtonLabel,goToLink} = this.props;
		return(
			<React.Fragment>
				<div style={{display: "flex", backgroundColor: "#eeeeee", padding: "5px"}}>
				<div style={{
	              width: "fit-content",
	              height: "fit-content",
	              overflow: "visible",
	              marginRight: "auto",
	              marginLeft: "2%"
	          	}}
	          	>
	          	<img style={{width: "60%"}} src={MetadReadLogo}/>
	          	</div>
	          	<div style={{ marginRight: "5%",  marginTop: "auto", marginBottom: "auto"}}>
	          	<Button 
	          	  color="primary"
	          	  style={{margin: "auto"}}
	          	  onClick={goToLink}
	          	  size="small"
	          	  variant="contained"
	          	>
	          	  {actionButtonLabel}
	          	</Button>
	          	</div>
	          	<Button 
	          	  color="secondary"
	          	  style={{ backgroundColor: "inherit",
	          	  boxShadow: "none", marginRight: "3%"}}
	          	  onClick={this.logoutUser}
	          	>
	          	  Log out
	          	</Button>
	          	</div>
			</React.Fragment>
		);
	}

}

export default MetaReadHeader;