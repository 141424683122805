import React from "react";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from "CustomButtons/Button.jsx";

class SingleAnswerQuestionChoices extends React.Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  render() {
    const { name, value, index, change,formControlWidth,isDisabled,handleClearKey } = this.props;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select value={value} name={name} onChange={change} disabled={isDisabled}
          style={isDisabled ? {fontSize: 'inherit', color: "rgba(0, 0, 0, 0.87)"} : {fontSize: 'inherit'}}>
            <MenuItem key={0} style={{fontSize: "14px"}} value="1">Option A</MenuItem>
            <MenuItem key={1} style={{fontSize: "14px"}} value="2">Option B</MenuItem>
            <MenuItem key={2} style={{fontSize: "14px"}} value="3">Option C</MenuItem>
            <MenuItem key={3} style={{fontSize: "14px"}} value="4">Option D</MenuItem>
        }
        </Select>
        {value && !isDisabled &&
        <Button style={{width: "fit-content", marginTop: "12px", marginLeft: "auto", backgroundColor: "#3f51b5"}} size={"sm"} onClick={handleClearKey}>Clear Key</Button>
        }
      </FormControl>
    );
  }

}


export default SingleAnswerQuestionChoices;