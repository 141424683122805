import TextStyle from "styles/TextStyle.jsx";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import basicsStyle from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";


const myHomeRegionStyle = {
  simpleContainer:{
    background: "#FFFFFF",
    margin: "20px"
  },
  containerHeaderSticker:{
    background: "#203642",
    //margin: "0px",
    width: "auto",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    marginBottom: "-45px",
    marginTop: "10px",
    zIndex: "1",
    marginRight: "auto",
    marginLeft: "30px",
    transform: "skew(15deg)",
    borderLeft: "0px solid transparent"
  },
  upcomingTestContainer:{
    background: "#FFFFFF",
    margin: "20px",
    marginTop: "30px",
    //boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05)",
    borderRadius: "5px"
  },
  programTestContainer:{
    background: "#FFFFFF",
    marginLeft: "20px",
    marginRight: "20px",
    //boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05)",    
    borderRadius: "5px"
  },
  recentTestContainer:{
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05)",    
  },
  programTestContainerPlain:{
    background: "#FFFFFF",
    //marginLeft: "20px",
    //marginRight: "20px",
    //boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    //borderRadius: "5px"
  },
  boxedContainer:{
    background: "#FFFFFF",
    marginRight: "10px",
    marginLeft: "10px",
    //boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    //border: ".1rem solid #eee",
    minHeight: "120px",
    width: "320px",
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05)",    

   },
   fullBoxedContainer:{
    background: "#FFFFFF",
    marginRight: "10px",
    marginLeft: "10px",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    border: ".1rem solid rgba(200, 200, 200, 1)",
    minHeight: "200px",
    width: "100%"
   },
   clipContainerStyle:{
    //backgroundColor: "white", 
    paddingTop: "0px", 
    paddingBottom: "0px",
    margin: "0px",
    padding: "10px"
  },
  clipStyle:{
    backgroundColor: "white", 
    margin: "0px",
    //padding: "10px",
    paddingTop: "0px", 
    paddingBottom: "10px",
    //height: "20vh",
    //boxShadow: "0 0 5px rgba(50, 50, 50, 0.25)",
    borderRadius: "10px",
    //borderColor: "rgba(34, 56, 69, 0.5)",
    //borderImage: "none",
    //borderStyle: "solid solid none",
    //borderWidth: "3px 0px 0",
    height: "100%"
  },
  clipHeader:{
    paddingTop: "10px",
    marginTop: "0px",
    textAlign: "center",
    color: "white"
  },
  clipHeaderStyle:{
    backgroundColor: "#3b4856",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px"

  },
  clipBodyStyle:{
    borderColor: "rgba(34, 56, 69, 0.2)",
    borderImage: "none",
  borderStyle: "solid solid none",
  borderWidth: "1px 0px 0"
  },
  clipInlineDataStyle:{
    marginTop: "20px",
    marginBottom: "10px",
    display: "inline-block",
    lineHeight: "1.5em",
    marginRight: "5px"
  },
  borderRight:{
    borderColor: "rgba(34, 56, 69, 0.2)",
    borderImage: "none",
  borderStyle: "solid solid none",
  borderWidth: "0px 1px 0",
  marginTop: "10px"
  },
    zeroMarginTop:{
    marginTop: "0px"
  },
  subtitleStyle: {
      color: "rgb(34, 56, 69)",
    fontWeight: "600",
    fontSize: "18px",
    fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif"
  },
  borderRight: {
    borderRight: ".1rem solid #eee"
  },
  marginTop20:{
    marginTop: "20px"
  },
  ...TextStyle,
  ...imagesStyle,
  ...basicsStyle
};

export default myHomeRegionStyle;
