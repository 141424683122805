

//Function to Convert server data time frame to client understood format
export function formatDate(date) {
	  var date = new Date(date);
	  //The below step is need when DB timezone is changed to IST
	  date = new Date(date.getTime() + date.getTimezoneOffset()*60*1000)
	  var hours = date.getHours();
	  var minutes = date.getMinutes();
	  var ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours : 12; // the hour '0' should be '12'
	  minutes = minutes < 10 ? '0'+minutes : minutes;
	  var strTime = hours + ':' + minutes + ' ' + ampm;
	  return  date.getDate() + " " + date.toLocaleString("en-in", { month: "short" }) + " " + date.getFullYear() + "  " + strTime;
	}

export function formatDateWithoutTime(date) {
	  var date = new Date(date);
	  //The below step is need when DB timezone is changed to IST
	  date = new Date(date.getTime() + date.getTimezoneOffset()*60*1000)
	  return  date.getDate() + " " + date.toLocaleString("en-in", { month: "short" }) + " " + date.getFullYear();
	}


export function formatDateWithoutTimeAndYear(date) {
	  var date = new Date(date);
	  //The below step is need when DB timezone is changed to IST
	  date = new Date(date.getTime() + date.getTimezoneOffset()*60*1000)
	  return  date.getDate() + " " + date.toLocaleString("en-in", { month: "short" });
	}


export function formatDuration(date) {
	  var date = new Date(date);
	  var hours = date.getUTCHours();
	  var minutes = date.getUTCMinutes();
	  if (hours < 10) 
	  	{hours = '0' + hours;} 
	  if (minutes < 10) 
	  	{minutes = '0' + minutes;} 
	  var strTime = hours + ':' + minutes;
	  return strTime+ ' Hours';
	}

export function isDateOld(date) {
	  var givenDate = new Date(date);
	  //The below step is need when DB timezone is changed to IST
	  givenDate = new Date(givenDate.getTime() + givenDate.getTimezoneOffset()*60*1000)
	  const currentDate = new Date();
	  return (currentDate < givenDate);
	}

export function offsetISTTimezone(date){
	//The below step is need when DB timezone is changed to IST
	var givenDate = new Date(date);
	givenDate = new Date(givenDate.getTime() + givenDate.getTimezoneOffset()*60*1000)
	return givenDate
}

export function getDurationMinutes(date){
	var standardOriginTime = new Date("2000-01-01").getTime();
	var givenDateTime = new Date(date).getTime();

	var timeDiffInMilliSec = givenDateTime - standardOriginTime;
	var timeInMinutes = Math.floor(timeDiffInMilliSec / 1000 / 60);
	return timeInMinutes
}

export function formatDateShortYear(date) {
	  var date = new Date(date);
	  //The below step is need when DB timezone is changed to IST
	  date = new Date(date.getTime())
	  var hours = date.getHours();
	  var minutes = date.getMinutes();
	  var ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours : 12; // the hour '0' should be '12'
	  minutes = minutes < 10 ? '0'+minutes : minutes;
	  var strTime = hours + ':' + minutes + ' ' + ampm;
	  return  date.getDate() + " " + date.toLocaleString("en-in", { month: "short" }) + " " + date.getFullYear().toString().substr(-2) + "  " + strTime;
	}