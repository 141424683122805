import React from "react";

/*import Button from "CustomButtons/Button.jsx";
import Card from "Card/Card.jsx";
import CardBody from "Card/CardBody.jsx";*/

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

//import QuestionImages from "components/test/QuestionImages.jsx";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import customCheckboxRadioSwitch from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";

/*import test_img1 from "assets/img/image1.png";*/


class MultipleAnswerOptions extends React.Component {

	constructor(props) {
	    super(props);
	     this.state = {
		    value: "",
		    checked: [],
		    answer: ""
		  };
	  }

	componentDidMount(){
		if(this.props.userData["question_no"]){
			var answer = this.props.userData["answer"];
			const options =  this.props.options;
			 if(!answer || !options){
			 	this.setState({
		      	checked: []
		    	});
			 	return;
			 }
			const ansArray = answer.split(',');
			var checked = [];
			for(var i in options){
				var value = options[i].label;
				if(ansArray.includes(value)){
					const currentIndex = checked.indexOf(value);
					if (currentIndex === -1) {
				      checked.push(value);
				    } else {
				      checked.splice(currentIndex, 1);
				    }
				} 
			}
			this.setState({
		      checked: checked
		    });
		}
	}

	  //Have to rely on componentDidUpdate as by the time componentDidMount is called userData might be empty
	componentDidUpdate(prevProps){
		 if(this.props.userData["answer"] == "" ){
		 	if(this.state.checked.length > 0)
		 	{	this.setState({ checked: []});
			}
		 	return
		 }
		if(this.props.userData["question_no"] != prevProps.userData["question_no"]){
			var answer = this.props.userData["answer"];
			const options =  this.props.options;
			 if(!answer || !options){
			 	this.setState({
		      	checked: []
		    	});
			 	return;
			 }
			const ansArray = answer.split(',');
			var checked = [];
			for(var i in options){
				var value = options[i].label;
				if(ansArray.includes(value)){
					const currentIndex = checked.indexOf(value);
					if (currentIndex === -1) {
				      checked.push(value);
				    } else {
				      checked.splice(currentIndex, 1);
				    }
				} 
			}
			this.setState({
		      checked: checked
		    });
		}
	}

	handleToggle(value) {
	    var { checked } = this.state;
	    const currentIndex = checked.indexOf(value);
	    const newChecked = [...checked];

	    if (currentIndex === -1) {
	      newChecked.push(value);
	    } else {
	      newChecked.splice(currentIndex, 1);
	    }
	    
	    this.setState({
	      checked: newChecked
	    });

	    var answer = ""
	    for(var i in newChecked){
	    	if(newChecked[i]){
	    		if(!answer){
	    			answer = newChecked[i];
	    		}else{
	    			answer = answer + ',' + newChecked[i];
	    		}
	    	}
	    }

	    this.props.answerChanged(answer);
	  }
	
	render() {
		 const { classes,options, answer, answerChanged,showSolutions, AnswerKey, isDisabled, isOptionsJumbled} = this.props;
		 const keyStyle = {
		 	backgroundColor: "#00800033",
		 	paddingTop: "5px",
		 	paddingRight: "20px"
		 }
		 const style4={
		fontFamily: "serif",
		fontSize: "1rem",
		backgroundColor: "#ffc1073d",
		padding: "5px",
		marginLeft: "10px"
		};
		return (
			<GridContainer style={{margin: "auto"}}>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					{ isDisabled && <p style={style4}>Maximum Questions Attempted in this Section</p>}
					{options.map((item, index) => (
						<div key={index}
	                  className={
	                    classes.checkboxAndRadio
	                  }
	                >
	                  <FormControlLabel
	                    control={
	                      <Checkbox
	                        tabIndex={-1}
	                        checked={this.state.checked.indexOf(item.label) !== -1 ? true : false}
	                        onClick={() => this.handleToggle(item.label)}
	                        checkedIcon={<Check className={classes.checkedIcon} />}
	                        icon={<Check className={classes.uncheckedIcon} />}
	                        classes={{ checked: classes.checked }}
	                      />
	                    }
	                    style ={ showSolutions && AnswerKey && AnswerKey.includes(item.label) ? keyStyle : {} }
	                    disabled={showSolutions || isDisabled} 
	                    label={
            				<React.Fragment>
            				{item.content && item.content.map((itemContent, index3) => (
            	 		     	<React.Fragment key={index3}>
            	 		     	{ index3%2 == 0 && 
            						<QuestionText text={index3 == 0 ? (isOptionsJumbled ?  String.fromCharCode(65 + index) +") " +itemContent : item.label +") " +itemContent) : itemContent }/>
            					}
            					{index3%2 == 1 && itemContent &&
            					 	<QuestionImages 
            						src={itemContent} 
            						name={"option_"+item.label+'_'+index3}
            						isEditMode={false}/> 
            					}
            					</React.Fragment>
            				))}
            				</React.Fragment>
	                    }
	                  />
	                </div>
					))}
				</GridItem> 
			</GridContainer>
		);
	}
}

export default withStyles(customCheckboxRadioSwitch)(MultipleAnswerOptions);

