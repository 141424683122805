import React from "react";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Button from "CustomButtons/Button.jsx";


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
});

class MultipleAnswerQuestionChoices extends React.Component {

  state = {
    selectedOptions: []
  };

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  getStyles(name, selectedOptions, theme) {
    return {
      fontSize: "14px",
      fontWeight: selectedOptions.indexOf(name) === -1 ? 200 : 500,
    };
  };

   handleChange(event) {
    this.setState({selectedOptions: event.target.value});
    this.props.change();
  }


  render() {
    const { classes, name, value, index, change,formControlWidth, isDisabled,handleClearKey} = this.props;
    const {selectedOptions} = this.state;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select
          multiple
          value={value}
          name={name} 
          onChange={change}
          disabled={isDisabled}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={String.fromCharCode(64 + parseInt(value))} className={classes.chip} />
              ))}
            </div>
          )}
        >
            <MenuItem key={0} style={this.getStyles("1", value)} value="1">Option A</MenuItem>
            <MenuItem key={1} style={this.getStyles("2", value)} value="2">Option B</MenuItem>
            <MenuItem key={2} style={this.getStyles("3", value)} value="3">Option C</MenuItem>
            <MenuItem key={3} style={this.getStyles("4", value)} value="4">Option D</MenuItem>
        </Select>
        {value && !isDisabled &&
        <Button style={{width: "fit-content", marginTop: "12px", marginLeft: "auto", backgroundColor: "#3f51b5"}} size={"sm"} onClick={handleClearKey}>Clear Key</Button>
        }
      </FormControl>
    );
  }

}


export default withStyles(styles)(MultipleAnswerQuestionChoices);