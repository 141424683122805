import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import ErrorBoundary from "components/ErrorBoundary.js";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import InputLabel from '@material-ui/core/InputLabel';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import InteractiveList from 'components/misc/InteractiveList';
import ErrorLogo from "@material-ui/icons/Error";
import MarksAndRanksList from "components/analysis/tables/MarksAndRanksList"
import qs from 'query-string';
import Programs from "components/lov/Programs";
import TestTypes from "components/lov/TestTypes";
import $ from 'jquery';



class OverallAnalysisDashboard extends React.Component {

  state = {
    errorMessages: [],
    fullResultsData: "",
    selectedInfo: {}
  }

  componentDidMount(){
/*   const scheduled_test_paper_id = qs.parse(this.props.location.search).scheduled_test_paper_id;
*/
/*    if(scheduled_test_paper_id){ //Process of duplicate only if test_id exists
*/      /* $.ajax({
          type: 'GET',
          url: getBaseURL() + '/overall_analysis.json?test_type_id=3&program_id=2',
        }).done((data, status, jqXHR)=>{
          this.setState({fullResultsData: data});
        
        }).fail((jqXHR, textStatus, errorThrown) => {
          var errorMessages = this.state.errorMessages;
          errorMessages.push({message: "An Unexpected Error occured while trying to dulicate the test."})
            this.setState({errorMessages: errorMessages});
        })*/
  const testTypeId = qs.parse(this.props.location.search).test_type_id;
  const programId = qs.parse(this.props.location.search).program_id;
  var selectedInfo = this.state.selectedInfo;
    if(testTypeId && programId){
      selectedInfo["testTypeId"] = testTypeId;
      selectedInfo["programId"] = programId;
      this.handleRefreshResult();
    }
  }

  handleRefreshResult(){

    var selectedInfo = this.state.selectedInfo;
    if(selectedInfo["testTypeId"] && selectedInfo["programId"]){
      $.ajax({
          type: 'GET',
          url: getBaseURL() + '/overall_analysis.json?test_type_id='+selectedInfo["testTypeId"]+'&program_id='+selectedInfo["programId"],
        }).done((data, status, jqXHR)=>{
          this.setState({fullResultsData: data});
        
        }).fail((jqXHR, textStatus, errorThrown) => {
          //TODO show exact results returned from system
          var errorMessages = this.state.errorMessages;
          errorMessages.push({message: "An Unexpected Error occured while trying to fetch the results."})
            this.setState({errorMessages: errorMessages});
        })
    }
  }

  goToUserOverallAnalysis = (e) => {
    e.preventDefault();
    const user_id = e.currentTarget.name;
    const selectedInfo = this.state.selectedInfo;
    this.props.history.push('/student-analysis-dashboard?user_id=' + user_id+'&program_id='+selectedInfo["programId"]+'&test_type_id='+ selectedInfo["testTypeId"]);
  }


  handleOnChangeLOV(e,obj) {
    e.preventDefault(); 
    var attr = e.target.name;
    var selectedInfo = this.state.selectedInfo;
    selectedInfo[attr] = e.target.value;
    if(obj && obj.id){ //Save Id as for for LOV's
      selectedInfo[attr+"Id"] = obj.id
    }
    this.setState({selectedInfo});
    this.handleRefreshResult();
  }

  render () {
    const{classes} = this.props;
    const{fullResultsData,selectedInfo} = this.state;
    const titleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "20px",
      paddingLeft: "20px",
    };
    const subtitleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "10px"
    }; 
    const subtitleStyle2={
      color: "rgb(34, 56, 69)",
      fontWeight: "400",
      fontSize: "14px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "5px"
    };   
    return (
      <React.Fragment>
        <ErrorBoundary>
          <GridContainer style={{
          backgroundColor: "white",
          paddingTop: "0px",
          paddingBottom: "20px",
          width: "100%", 
          margin: "10px 0px 0px",
          marginTop: "0px"}}
          spacing={24}>
          <GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            <h1 style={titleStyle} >Overall Analysis Dashboard</h1>
            <hr/>
          </GridItem>
        {this.state.errorMessages && this.state.errorMessages.length > 0 &&
        <React.Fragment>
          <GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            </GridItem>
          <GridItem xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
          {this.state.errorMessages && this.state.errorMessages.length > 0 ? this.state.errorMessages.map((errorMessage, index) => 
                  <div key={index}>
                  <ErrorLogo style={{verticalAlign: "middle", marginRight: "5px", color: "#f44336"}}></ErrorLogo>
                  <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit"}} /*TODO className={classes.robotoFontRegular}*/>{errorMessage["message"]}</p> 
                  </div>
                  ): null
                }
              </GridItem>
          </React.Fragment>
          }
          <React.Fragment>
              <GridItem  xs={12} sm={12} md={1}  style={{paddingTop: "0px", paddingBottom: "0px"}}>
              </GridItem>
              <GridItem  xs={12} sm={12} md={11}  style={{paddingTop: "0px", paddingBottom: "0px"}}>
                <h3 style={subtitleStyle2} >Select Program and Test Type</h3>
              </GridItem>
              <GridItem  xs={12} sm={12} md={1}>
              </GridItem>
              <GridItem  xs={12} sm={12} md={3}>
                <InputLabel shrink htmlFor="material2">
                    Program
                </InputLabel>
                <div style={{paddingTop: "5px"}}>
                  <Programs
                    name="program"
                    formControlWidth="60%"
                    value={selectedInfo["program"]}
                    valueId={selectedInfo["programId"]}
                    change={this.handleOnChangeLOV.bind(this)}
                  />
                </div>
              </GridItem>
              <GridItem  xs={12} sm={12} md={3}>
                <InputLabel shrink htmlFor="material2">
                  Test Type
                </InputLabel>
                <div style={{paddingTop: "5px"}}>
                  <TestTypes
                    name="testType"
                    formControlWidth="60%"
                    value={selectedInfo["testType"]}
                    valueId={selectedInfo["testTypeId"]}
                    change={this.handleOnChangeLOV.bind(this)}
                  />
                </div>
              </GridItem>
                <GridItem  xs={12} sm={12} md={12} style={{paddingRight: "24px", paddingLeft: "24px"}}>
                  <MarksAndRanksList 
                  goToUserTestAnalysis={this.goToUserOverallAnalysis} 
                  data={fullResultsData}
                  reportName={"Overall Average"}/>
                </GridItem>
            </React.Fragment>
            </GridContainer>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default OverallAnalysisDashboard;
