import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';
import qs from 'query-string';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Button from "CustomButtons/Button.jsx";
import TestHeader from "components/test/TestHeader.jsx";

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Grid from "@material-ui/core/Grid";

import CheckCircle from "@material-ui/icons/CheckCircle";
import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";
import {getDurationMinutes} from "components/helpers/DateTimeHelper.jsx";



const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 0,
  },
  row: {
  	  height: "36px",
  	  width: "100%",
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.background.default,
    },
  },
  titleRow: {
  	 height: "36px",
    backgroundColor: theme.palette.background.default,
  },
});

class TestInstructions extends React.Component {


	constructor(props) {
	    super(props);

	    this.state = {
	      testInstructionsData: {},
        isTestActive: false
	    };
	  }

	componentDidMount() {
		if(!isUserSessionActive())
		{
		 	this.props.history.push('/login');
		 	return
		}
		
      const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
      /*const question_no = qs.parse(this.props.location.search).question_no;*/ //SCOPING OUT QUESTION NUMBERING FOR NOW
      //TODO what happens if test_paper_id and question_no are not passed
      $.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-test-paper-instructions.json?test_paper_id='+test_paper_id, //Hard Coded TODO
          dataType: "JSON",
          //headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	this.setState({testInstructionsData: data, testId: test_paper_id});
        	if(data["is_test_live"]){
        		this.setState({isTestActive: true});
        	}
        });
        if(localStorage.testUserData && localStorage.activeTestId){
	         var activeTestId = localStorage.activeTestId;
	        if(localStorage.activeTestAutoSaved){
		  		this.handleLocalStorageForAutoSavedTests(test_paper_id)
		  	}else{
		  		if(activeTestId == test_paper_id ){
	          		this.setState({isTestActive: true});
	         	} else {
	  				alert("Another active test is in progress. Please complete the active test before starting the new test.");
	  				return
	         	}
		  	}
        }
  	}

  	handleLocalStorageForAutoSavedTests(test_paper_id){
	  	if(localStorage.activeTestId && localStorage.activeTestAutoSaved){
		  	$.ajax({
		  	  type: 'GET',
		  	  url: getBaseURL() + '/get-live-test-paper-time.json?test_paper_id='+localStorage.activeTestId,
		  	  dataType: "JSON",
		      //headers: JSON.parse(localStorage.user)
		  	}).done((data)=>{
		  		if(data.is_test_live){ //TODO Error handling
		  			if(localStorage.activeTestId==test_paper_id){
		  				this.setState({isTestActive: true});
		  			}else{
		  				alert("Another active test is in progress. Please complete the active test before starting the new test.");
			  			return
			  		}
			  	}else{
			  		//Test AutoSave And Not Live anymore -> clear all sessiond data
			  		localStorage.removeItem("testUserData");
         			localStorage.removeItem("activeTestId");
         			localStorage.removeItem("activeTestQuestionNumber");
         			localStorage.removeItem("activeTestAutoSaved");
			  	}
		  	})
	  	}
	  }

  	 range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);

	render() {
		const style3={
		fontFamily: "sans-serif",
		paddingTop: "20px",
		paddingBottom: "0px",
		textAlign: "center",
		margin: "auto"
		};
		const style4={
		fontFamily: "sans-serif",
		fontSize: 16
		};
		const style={
			 padding: "5px 5px", 
			 minWidth: "48px",
			 borderRadius: "0.2rem",
			 boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 0px 1px, rgba(0, 0, 0, 0.3) 0px 3px 1px -2px, rgba(0, 0, 0, 0.3) 0px 1px 5px 0px",
			 borderRadius:"10",
			 borderWidth: "1",
			 borderColor: "black"
			};
		const style2={
			fontWeight: "400",
			fontFamily: "serif",
			marginBottom: "0px"
			};
		 const { testInstructionsData, isTestActive} = this.state;
		  const { classes} = this.props;
		return (
			<div>  		 	
			 <GridContainer style={{margin: "auto"}} spacing={16} >
			 	<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
			 	<div style={{height: "auto", backgroundColor: "white"}}>
			 		<TestHeader staticMode isTestActive={isTestActive} {...this.props}/>
			 	 </div>
			 	</GridItem>
			 	<GridItem xs={12} sm={12} md={9} style={{paddingRight: "0px"}}>
			 	<div style={{height: "auto", minHeight: "93vh", backgroundColor: "white",paddingBottom: "20px"}}>	
			 		<h3 style={style3}>Test Instructions</h3>
			 		<ul>
				 		<li>
				 		<p style={style4}>The test paper consists of { testInstructionsData.test_template_details && Object.keys(testInstructionsData.test_template_details).length ? Object.keys(testInstructionsData.test_template_details).length : " following "} subject(s) and {testInstructionsData.total_questions} questions</p>
				 		</li>
				 		<li>
				 		<p style={style4}>The test paper carries a total of {testInstructionsData.total_marks} marks</p>
				 		</li>
				 		<li>
				 		<p style={style4}>The duration of the test paper is {getDurationMinutes(testInstructionsData.test_duration)} minutes</p>
				 		</li>
				 		<li>
				 		<p style={style4}>A maximum of attemptable questions can be answered in sections with optional questions</p>
				 		</li>
				 		<li>
				 		<p style={style4}>Each subject has following sections detailed as follows</p>
				 		</li>
			 		</ul>
			 	<GridContainer style={{margin: "auto", paddingRight: "10px", paddingLeft: "10px",textAlign: "center"}} spacing={16}  justify="center">
			 			
			 		<Table className={classes.table} style={{width: "90%"}}>
					    
					    <TableBody>	
					    
					     <TableRow className={classes.row} >
			            <CustomTableCell component="th" colSpan={1} >
			 				Sections
			 			</CustomTableCell>
			 			<CustomTableCell colSpan={3}>
			 			 	Pattern
			 			</CustomTableCell>
			 			<CustomTableCell colSpan={1}>
			 				Questions(Attemptable)
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				Positive
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				Negative
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				Partial
			 			</CustomTableCell>
					      </TableRow>
					    </TableBody>	

			 		{testInstructionsData["test_template_details"] && Object.keys(testInstructionsData["test_template_details"]).map((item, index) => (
			 			<TableBody key={index}>	
			 			
			 			<TableRow className={classes.titleRow} key={index}>
			            <CustomTableCell component="th" scope="row" colSpan={12} style={{textAlign: "center"}}>
			              {item}
			            </CustomTableCell>
			          </TableRow>  
			 			{(testInstructionsData["test_template_details"][item]).map((item, index) => (
			          <TableRow className={classes.row} key={index}>
			            <CustomTableCell component="th" scope="row"  colSpan={1}>
			              Section-{index+1}
			            </CustomTableCell>
			 			<CustomTableCell component="th" scope="row" colSpan={3}>
			 			 	{item["pattern_name"]}
			 			</CustomTableCell>
			 			<CustomTableCell component="th" scope="row" colSpan={1}>
			 				{item["first_question_no"]} - {item["last_question_no"]} ({item["required_questions"]})
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				+ {item["positive_marks"]} 
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				- {item["negative_marks"]} 
			 			</CustomTableCell>
			 			<CustomTableCell numeric colSpan={1}>
			 				{item["partial_marks"]} 
			 			</CustomTableCell>
			          </TableRow>
			 			))}
			 			</TableBody>	
			 		))} 
					</Table>
				</GridContainer>
			 	</div>
			 	</GridItem>
			 	<GridItem xs={12} sm={12} md={3}>
			 	<div style={{height: "auto", minHeight: "93vh", backgroundColor: "white"}}>
			 		<Grid container style={{margin: "auto", paddingLeft: "20px"}} spacing={8} direction="column">
						<h5 style={style3}>Navigation Instructions</h5><br/>
						<Grid  item >
							<Grid container  spacing={8} >
								<Button style={style} 
								size={"sm"} 
								color="transparent"
								><p style={style2}>1</p></Button>
								<p style={{margin: "auto",marginLeft: "10px"}}> Not visited</p>
							</Grid>
						</Grid>
						<Grid  item >
							<Grid container  spacing={8} >
								<Button style={style} 
								size={"sm"} 
								color="danger"
								><p style={style2}>2</p></Button>
								<p style={{margin: "auto",marginLeft: "10px"}}>Visited but unanswered</p>
							</Grid>
						</Grid>
						<Grid  item >
							<Grid container  spacing={8} >
								<Button style={style} 
								size={"sm"} 
								color="success"
								><p style={style2}>3</p></Button>
								<p style={{margin: "auto",marginLeft: "10px"}}>Answered</p>
							</Grid>
						</Grid>
						<Grid  item >
							<Grid container  spacing={8} >
								<Button style={style} 
								size={"sm"} 
								color="primary"
								><p style={style2}>4</p></Button>
								<p style={{margin: "auto",marginLeft: "10px"}}>Marked for review</p>
							</Grid>
						</Grid>
						<Grid  item >
							<Grid container  spacing={8} >
								<Button style={style} 
								size={"sm"} 
								color="primary"
								><p style={style2}>5 <CheckCircle style={{top: "-5px", right: "0px", marginRight: "-10px", color: "lightgreen"}} /></p></Button>
								<p style={{margin: "auto",marginLeft: "10px"}}>Answered and Marked for review</p>
							</Grid>
						</Grid>
						
					</Grid>
			 	</div>
			 	</GridItem>
			 </GridContainer>	
			 </div>	
			);
	}
 

}

export default withStyles(styles)(TestInstructions);
