import React from "react";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
});

class MatrixGridKeyComponent extends React.Component {

  state = {
    selectedOptions: []
  };

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  getStyles(name, selectedOptions, theme) {
    return {
      fontSize: "14px",
      fontWeight: selectedOptions.indexOf(name) === -1 ? 200 : 500,
    };
  };

   handleChange(event) {
    this.setState({selectedOptions: event.target.value});
    this.props.change();
  }


  render() {
    const { classes, name, value, index, change,formControlWidth, isDisabled, list2} = this.props;
    const {selectedOptions} = this.state;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select
          multiple
          value={value}
          name={name} 
          onChange={change}
          disabled={isDisabled}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={ String.fromCharCode(79 + parseInt(value))} className={classes.chip} />
              ))}
            </div>
          )}
        >
          {list2 && list2.map((row,index)=>(
            <MenuItem key={index} style={this.getStyles((index+1).toString(), value)} value={(index+1).toString()}>{"Option " +String.fromCharCode(79 + parseInt(index+1))}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

}


export default withStyles(styles)(MatrixGridKeyComponent);