import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import $ from 'jquery';

class SubjectChapters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subjectChapters: [],
    };
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  componentDidMount(){
    const subjectId = this.props.subjectId;
    const subject = this.props.subject;
    const source = this.props.source;
    var url = '/get-subject-chapters.json?subject_id='+ subjectId;
    if(source == "dataset"){
      url = '/get-subject-chapters.json?subject='+ subject + '&source='+source;
    }
    if(subjectId || subject){
      $.ajax({
        type: 'GET',
        url: getBaseURL() + url
      }).done((response, status, jqXHR)=>{
          this.setState({
            subjectChapters: response
          });

      });
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.subjectId != prevProps.subjectId || this.props.subject != prevProps.subject){
      const subjectId = this.props.subjectId;
      const subject = this.props.subject;
      const source = this.props.source;
      var url = '/get-subject-chapters.json?subject_id='+ subjectId;
      if(source == "dataset"){
        url = '/get-subject-chapters.json?subject='+ subject + '&source='+source;
      }
      if(subjectId || subject){
        $.ajax({
          type: 'GET',
          url: getBaseURL() + url
        }).done((response, status, jqXHR)=>{
            this.setState({
              subjectChapters: response
            });

        });
      }
    }
  }

  getIdForLOV(value){
    const subjectChapters = this.state.subjectChapters;
    const filteredSubjectChapters = subjectChapters.filter(subjectChapter => subjectChapter.name == value);
    if (filteredSubjectChapters && filteredSubjectChapters.length > 0){
     return {id: filteredSubjectChapters[0].id};
    }
    return;
  }


  render() {
    const { name, value, index, change,formControlWidth, includeId, subjectId } = this.props;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select value={value} name={name} onChange={includeId ? (e) => change(e,this.getIdForLOV(e.target.value)) :change} 
          style={{fontSize: 'inherit'}}>
          { this.state.subjectChapters.map((subjectChapter, index) =>
            <MenuItem key={index} style={{fontSize: "14px"}} value={subjectChapter.name}>{subjectChapter.name}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

}


export default SubjectChapters;