import React from "react";
import ErrorBoundary from "./ErrorBoundary.js";
import AppBar from 'components/AppBar';
import NavigationBar from 'components/NavigationBar';
import withStyles from "@material-ui/core/styles/withStyles";
import navigationBarStyle from "components/styles/NavigationBarStyle.js";


class AppLayout extends React.Component {

  render () {
    const{yieldComponent, hideNavigator, hideAppBar, classes} = this.props;
    return (
      <React.Fragment>
        <ErrorBoundary>
        { hideAppBar ?
          ""
         : <AppBar {...this.props} />
        }
        { hideNavigator ?
          ""
         : <NavigationBar {...this.props} />
        }
        <div className={classes.containerCustom}
         style={hideNavigator?{paddingLeft: "0px"}:{}} >
        {
          yieldComponent
        }
        </div>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default withStyles(navigationBarStyle)(AppLayout);
