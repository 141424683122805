import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';

import HorizontalStepper from "components/misc/HorizontalStepper"
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import CustomSearchBar from "components/misc/CustomSearchBar"
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Button from "CustomButtons/Button.jsx";
import InputLabel from '@material-ui/core/InputLabel';
import CustomInput from "CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import ShortText from "@material-ui/icons/ShortText";
import Programs from "components/lov/Programs";
import TestTypes from "components/lov/TestTypes";
import Divider from '@material-ui/core/Divider';
import ErrorLogo from "@material-ui/icons/Error";
import testFormStyle from "styles/TestFormStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Subjects from 'components/lov/Subjects';
import SubjectChapters from 'components/lov/SubjectChapters';
import qs from 'query-string';
import {formatDate} from "components/helpers/DateTimeHelper.jsx";
import TestModelActions from "components/testModel/TestModelActions"


const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MUIDataTableToolbarSelect:{
      	title:{
      		display: "none"
      	},
      	root:{
      		backgroundColor: "none",
      		boxShadow: "none",
      		justifyContent: "normal"
      	}
      },
      MuiTableCell:{
      	root:{
      		padding: "4px 16px 4px 16px"
      	}
      },
      MUIDataTableHeadCell: {
      	fixedHeader:{
      		zIndex: "none"
      	}
      },
    typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	}
  })

 const getMuiThemeForSearchBar = createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation1: {
          boxShadow: "none",
           border: "1px solid #aaacb1"
        },
        rounded:{
        	borderRadius: "10px"
        }
      },
      typography: {
    	useNextVariants: true,
  	  },
    },
    typography: {
    	useNextVariants: true,
  	},
  })


class TestForm extends React.Component {

constructor(props) {
	super(props);
	this.state = {
		activeStep: 0,
		errorMessages: [],
		testModelSearchResults: [],
		testModelRowSelected: "",
		testInfo: {},
		testModelInfo: {},
		isSubjectChapterTest: false,
		isDuplicateFlow: false,
		isTestModelAdvancedSearchMode: false
	};
}

componentDidMount(){
	const test_id = qs.parse(this.props.location.search).test_id;

	if(test_id){ //Process of duplicate only if test_id exists
	    $.ajax({
	      type: 'GET',
	      url: getBaseURL() + '/get-create-test-info.json?test_id=' + test_id,
	    }).done((data, status, jqXHR)=>{
	    	this.copyDuplicateTestInfo(data);
	    }).fail((jqXHR, textStatus, errorThrown) => {
	    	var errorMessages = this.state.errorMessages;
	    	errorMessages.push({message: "An Unexpected Error occured while trying to dulicate the test."})
	        this.setState({errorMessages: errorMessages});
	    })
 	}else{
 		this.initializeTestModels();
 	}
}

copyDuplicateTestInfo(data){
	var testDataToDuplicate = data["test_info"];
	var testChapterDataToDuplicate = data["chapter_info"];
	var isSubjectChapterTest = false
	var testInfo = {};	
	testInfo["testModelId"] = testDataToDuplicate["test_template_info_id"];
	testInfo["testModelName"] = testDataToDuplicate["template_info_name"];
	testInfo["testModelNumber"] = testDataToDuplicate["template_info_identifier"];
	testInfo["name"] = testDataToDuplicate["name"];
	testInfo["number"] = testDataToDuplicate["identifier"];
	testInfo["description"] = testDataToDuplicate["description"];
	testInfo["programId"] = testDataToDuplicate["global_program_id"];
	testInfo["program"] = testDataToDuplicate["program_short_name"];
	testInfo["testTypeId"] = testDataToDuplicate["global_test_type_id"];
	testInfo["testType"] = testDataToDuplicate["test_type_name"];
	testInfo["duplicateSourceTestId"]=testDataToDuplicate["id"];		  

	if(testDataToDuplicate["subject_test_flag"] && testChapterDataToDuplicate){
		isSubjectChapterTest = true;
		testInfo["subjectId"] = testChapterDataToDuplicate["subject_id"]
		testInfo["subject"] = testChapterDataToDuplicate["subject_short_name"]
		testInfo["subjectChapterId"] = testChapterDataToDuplicate["chapter_id"]
		testInfo["subjectChapter"] = testChapterDataToDuplicate["chapter"]
	}

	this.setState({
 		testInfo: testInfo,
 		isSubjectChapterTest: isSubjectChapterTest,
 		isDuplicateFlow: true
 	});
}


activeStepChanged = (activeStep) => {

	var currentActiveStep = this.state.activeStep;
	var testInfo = this.state.testInfo;
	var errorMessages = [];
	if(currentActiveStep == 0){
		if(!testInfo["testModelId"]){	        
			errorMessages.push({message: "Please select a Test Model. Test Model is Required."});
		}
	}
	
	var isSubjectChapterTest = this.state.isSubjectChapterTest;
	if(errorMessages && errorMessages.length > 0){
		this.setState({errorMessages: errorMessages});
		return
	}else{
		this.setState({errorMessages: []});
	}

	this.setState({activeStep: activeStep});
}

validateSteps = (activeStep) => {
	var isDuplicateFlow = this.state.isDuplicateFlow;
	var testInfo = this.state.testInfo;
	var errorMessages = [];
	if(activeStep == 1 && !isDuplicateFlow){
		if(!testInfo["testModelId"]){	        
			errorMessages.push({message: "Please select a Test Model. Test Model is Required."});
		}
	}else if((activeStep == 2 && !isDuplicateFlow) || (activeStep == 1 && isDuplicateFlow) ){
		var isSubjectChapterTest = this.state.isSubjectChapterTest;
		if(!testInfo["name"]){
			errorMessages.push({message: "Test Name is Required."});
		}
		if(!testInfo["number"]){
			errorMessages.push({message: "Test Number is Required."});
		}
		if(!testInfo["programId"]){
			errorMessages.push({message: "Test Program is Required."});
		}
		if(!testInfo["testTypeId"]){
			errorMessages.push({message: "Test Type is Required."});
		}
		if(isSubjectChapterTest && !testInfo["subjectId"]){
			errorMessages.push({message: "Subject is Required for Practice Tests."});
		}
		if(isSubjectChapterTest && !testInfo["subjectChapterId"]){
			errorMessages.push({message: "Chapter is Required for Practice Tests."});
		}

	}

	if(errorMessages && errorMessages.length > 0){
		this.setState({errorMessages: errorMessages});
		return false
	}else{
		this.setState({errorMessages: []});
		return true
	}

}


handleTestModelSearch = (searchText) => {
 	if(searchText && searchText.length > 3){ //Minimum three characters to search	   
	    var url = getBaseURL() + '/search_template.json?name=' + searchText;
	  	this.getTestModelSearchResults(url);
 	} else{
 		this.setState({testModelSearchResults: []});
 	}
}

populateTestModelTableFromResults(data){
	var testModelSearchResults = []
	if(data && data.length > 0){
		for(var i in data){
			testModelSearchResults.push([
				data[i].id, 
				data[i].name,
				data[i].identifier,
				data[i].total_marks,
				data[i].total_questions,
				formatDate(data[i].created_at),
				formatDate(data[i].updated_at),
				<TestModelActions 
				name={data[i].id} 
				errorMessageCallback={this.errorMessageCallback}
				{...this.props}/>]);
		}
		var testInfo = this.state.testInfo;
		testInfo["testModelId"] = "";
		testInfo["testModelName"] = "";
		testInfo["testModelNumber"] = "";
		this.setState({testModelSearchResults,testInfo, testModelRowSelected: ""});
	}else{
		this.setState({testModelSearchResults: []});
	}
}

errorMessageCallback = (errorMessage) => {
	var errorMessages = this.state.errorMessages;
	errorMessages.push(errorMessage)
	this.setState({errorMessages: errorMessages});
}

handleOnChangeTestInfo = (e) => {
 	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var testInfo = this.state.testInfo;
 	testInfo[attr] = e.target.value;
 	this.setState({
 		testInfo: testInfo
 	});
}

handleOnChangeLOVTestInfo(e,obj) {
 	e.preventDefault();	
 	var isSubjectChapterTest = this.state.isSubjectChapterTest;
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var testInfo = this.state.testInfo;
 	testInfo[attr] = e.target.value;
 	if(obj && obj.id){ //Save Id as for for LOV's
 		testInfo[attr+"Id"] = obj.id
 	}
 	//for testType
 	if(attr=="testType" && obj && obj.subject_test_flag && obj.subject_test_flag=="true"){ 
 		isSubjectChapterTest = true;
 	}else if(attr=="testType" && obj && obj.subject_test_flag && obj.subject_test_flag=="false"){
 		isSubjectChapterTest = false;
 	}
 	if(attr=="subject"){
 		testInfo["subjectChapter"] = "";
 		testInfo["subjectChapterId"] = "";
 	}
 	
 	this.setState({
 		testInfo: testInfo,
 		isSubjectChapterTest: isSubjectChapterTest
 	});
}


validateAndProcessTestDataForCreation(mode){
	//validate if all required params are provided and all are in expected data format
	var testInfo = this.state.testInfo;
	var isSubjectChapterTest = this.state.isSubjectChapterTest;
	var errorMessages = [];
	if(!testInfo["testModelId"]){	        
		errorMessages.push({message: "Please select a Test Model. Test Model is Required."});
	}
	if(!testInfo["name"]){
		errorMessages.push({message: "Test Name is Required."});
	}
	if(!testInfo["number"]){
		errorMessages.push({message: "Test Number is Required."});
	}
	if(!testInfo["programId"]){
		errorMessages.push({message: "Test Program is Required."});
	}
	if(!testInfo["testTypeId"]){
		errorMessages.push({message: "Test Type is Required."});
	}
	if(isSubjectChapterTest && !testInfo["subjectId"]){
		errorMessages.push({message: "Subject is Required for Practice Tests."});
	}
	if(isSubjectChapterTest && !testInfo["subjectChapterId"]){
		errorMessages.push({message: "Chapter is Required for Practice Tests."});
	}
	//Reset error messages if no validation failed.
	if(errorMessages && errorMessages.length > 0){
		this.setState({errorMessages: errorMessages});
		return
	}else{
		this.setState({errorMessages: []});
	}

	var testInfoPostData = { 
		test_info: {
		   	"test_template_info_id": testInfo["testModelId"],
		   	"old_test_info_id": testInfo["duplicateSourceTestId"]? testInfo["duplicateSourceTestId"]: "",
		   	"offline_import": (mode=="offline") ? "true" : "false", //TODO
		   	"name": testInfo["name"],
		   	"identifier": testInfo["number"],
		   	"description": testInfo["description"],
		   	"global_program_id": testInfo["programId"],
		   	"global_test_type_id":testInfo["testTypeId"]
		}
	}
	if(isSubjectChapterTest){
		testInfoPostData["test_info"]["subject_id"]= testInfo["subjectId"];
		testInfoPostData["test_info"]["chapter_id"]= testInfo["subjectChapterId"]
	}

	this.postTestInfoData(testInfoPostData, mode);
}

postTestInfoData(testInfoPostData, mode){
	
	if(testInfoPostData && testInfoPostData["test_info"] ){
		$.ajax({
	      type: 'POST',
	      url: getBaseURL() + '/react/test_infos',
	      data: testInfoPostData
	    }).done((response, status, jqXHR)=>{
	      	var test_id =  response["test_id"];
	      	if(mode == "online"){
	      		this.props.history.push("/test_questions/new?test_id=" + test_id);
	      	}else if(mode == "offline"){
	      		this.props.history.push("/offline_test?test_id=" + test_id);;
	      	}

	    }).fail((jqXHR, textStatus, error)=>{
	    	var errorMessages = this.state.errorMessages;
	    	errorMessages.push({message: jqXHR["responseText"]})
	        this.setState({errorMessages: errorMessages});
			//TODO proper error handling
	    })
	}
}

handleCreateOnlineTest = (e) => {
	e.preventDefault();
	this.validateAndProcessTestDataForCreation('online');
}

handleCreateOfflineTest = (e) => {
	e.preventDefault();
	this.validateAndProcessTestDataForCreation('offline');
}

goToNewTestModel = (e) => {
	e.preventDefault();
	this.props.history.push("/test_template_infos");
}

changeTestModelSearchMode = (e) => {
	var isTestModelAdvancedSearchMode = this.state.isTestModelAdvancedSearchMode;
	this.setState({isTestModelAdvancedSearchMode: !isTestModelAdvancedSearchMode});
}

handleOnChangeTestModelInfo = (e) =>{
	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var testModelInfo = this.state.testModelInfo;
 	testModelInfo[attr] = e.target.value;
 	this.setState({testModelInfo});
}


handleTestModelAdvSearch = (e) => {
	e.preventDefault();
	var testModelInfo = this.state.testModelInfo;

	var url = getBaseURL() + "/search_template.json?limit=20";
	if(testModelInfo["name"]){
		url = url + "&name="+testModelInfo["name"]
	}
	if(testModelInfo["number"]){
		url = url + "&identifier="+testModelInfo["number"]
	}
	if(testModelInfo["fromDate"]){
		url = url + "&start_time="+testModelInfo["fromDate"]
	}
	if(testModelInfo["toDate"]){
		url = url + "&end_time="+testModelInfo["toDate"]
	}
	if(!url.includes('&')){
		//Default seatch
		var ninetyDaysInPast = new Date();
	   	ninetyDaysInPast = new Date(ninetyDaysInPast.getTime() - 90*24*60*60*1000) //90 days back
	   	url = url + "&start_time="+ninetyDaysInPast
	}

	this.getTestModelSearchResults(url);
	
}

initializeTestModels(){
	var ninetyDaysInPast = new Date();
	ninetyDaysInPast = new Date(ninetyDaysInPast.getTime() - 90*24*60*60*1000) //90 days back
	var url = getBaseURL() + "/search_template.json?limit=10&start_time="+ninetyDaysInPast
	this.getTestModelSearchResults(url);
}

getTestModelSearchResults(url){
	$.ajax({
    	type: 'GET',
      	url: url,
	}).done((data, status, jqXHR)=>{
	    this.populateTestModelTableFromResults(data);
	}).fail((jqXHR, textStatus, errorThrown) => {
	    var errorMessages = this.state.errorMessages;
	    errorMessages.push({message: "An Unexpected Error occured while searching for Test Model."})
	    this.setState({errorMessages: errorMessages});
	})
}

handleTestModelAdvSearchReset =(e) => {
	e.preventDefault();
	var testModelInfo = this.state.testModelInfo;
 	testModelInfo["name"] = "";
 	testModelInfo["number"] = "";
	testModelInfo["fromDate"] = "";
	testModelInfo["toDate"] = "";
	this.setState({testModelInfo});
}

render () {
	const {testInfo,isSubjectChapterTest,isDuplicateFlow,
		isTestModelAdvancedSearchMode, testModelInfo} = this.state;
	const {classes} = this.props;
	const titleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "20px",
		paddingLeft: "20px",
	};
	const subtitleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "18px",
		fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
		paddingBottom: "10px"
	};
	const tableTitleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "18px",
		fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
		padding: "0px", margin: "0px",
		paddingTop: "10px"
	};
	const testModelTableOptions = {
		filter: false,
		selectableRows:'single',
		search: false,
		print: false,
		download: false,
		viewColumns: false,
		responsive: 'stacked',
		rowsSelected: this.state.testModelRowSelected,
		onRowsSelect:(currentRowsSelected, allRowsSelected) => {
			const testModelSearchResults = this.state.testModelSearchResults;
			const selectedRowData = testModelSearchResults[currentRowsSelected[0].index]; //Only one row is selected
			const testModelId =  selectedRowData[0]; //First column hidden in test Model ID
			const selected = allRowsSelected.map(item => item.index);
			var testInfo = this.state.testInfo;
			testInfo["testModelId"] = testModelId;
			testInfo["testModelName"] = selectedRowData[1];
			testInfo["testModelNumber"] = selectedRowData[2]
			this.setState({testInfo: testInfo,
				testModelRowSelected: selected});
		},
		setRowProps: (row) => {
	        return {
	          style: {textAlign: 'center'}
	        };
	      },
	     customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
	 	    	return (<div style={{paddingLeft: "24px", paddingRight: "24px"}}>
	 	    			<p style={tableTitleStyle} >Test Models</p>
	 	    			<p>Test Model Selected</p>
	 	    			</div>);
	 	    },
	    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
	        return; //No Footer needed overriding defaut footer
    	}
	}
	const testModelTableColumns = [
	{name: "testModelId",
        options: {
          display: false,
        }
    }, 
	{name: "Name"},
	{name: "Identifier"},
	{name: "Total Marks"},
	{name: "Total Questions"},
	{name: "Created At"},
	{name: "Updated At"},
	{name: ""}     
	]
    return (	
    <React.Fragment>
  		<GridContainer style={{
      		backgroundColor: "white",
			paddingTop: "0px",
      		paddingBottom: "20px",
      		width: "100%", 
      		margin: "10px 0px 0px",
      		marginTop: "0px"}}
      		spacing={24}>
      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
      			<h1 style={titleStyle} >{ isDuplicateFlow ? "Duplicate Test" : "Create Test"}</h1>
      			<hr/>
      		</GridItem>
      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
    			<HorizontalStepper 
    			steps={isDuplicateFlow ? ['Enter Test Details', 'Create Test']
    				: ['Select Test Model', 'Enter Test Details', 'Create Test']}
    			activeStepChanged={this.activeStepChanged}
    			validateSteps={this.validateSteps}/>
    		</GridItem>
    		{this.state.errorMessages && this.state.errorMessages.length > 0 &&
	    	<React.Fragment>
	    		<GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
	      		</GridItem>
	    		<GridItem xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
	    		{this.state.errorMessages && this.state.errorMessages.length > 0 ? this.state.errorMessages.map((errorMessage, index) => 
	                <div key={index}>
	                <ErrorLogo className={classes.icon} style={{verticalAlign: "middle", marginRight: "5px", color: "#f44336"}}></ErrorLogo>
	                <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit"}} className={classes.robotoFontRegular}>{errorMessage["message"]}</p> 
	                </div>
	                ): null
	              }
	            </GridItem>
	        </React.Fragment>
        	}
	      	{ this.state.activeStep === 0 && !isDuplicateFlow &&
	      	<React.Fragment>
	      		<GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
      			</GridItem>
	      		<GridItem  xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
	      			<div style={{paddingLeft: "24px", paddingRight: "24px"}}>
	      				<h3 style={subtitleStyle} >Select Test Model</h3>
	      			</div>
      			</GridItem>
	      		<GridItem  style={{backgroundColor: "#ffffff"}}  xs={12} sm={12} md={2}>
      			</GridItem>
	      		<GridItem style={{backgroundColor: "#ffffff", paddingTop: "8px", paddingBottom: "8px"}}  xs={12} sm={12} md={8}>
	      		<div style={{paddingLeft: "24px", paddingRight: "24px"}}>
	      			<MuiThemeProvider theme={getMuiThemeForSearchBar}>
	      			<CustomSearchBar inputPlaceholder={"Search Test Model"}
	      			child2={<Button style={{textTransform: "none", 
	      								backgroundColor: "#3f51b5",
	      							    padding: "8px 20px",
	      								margin: "0px"}}
	      								onClick={this.goToNewTestModel}>
                          				Create New Test Model</Button>}
	      			child1={<Button style={{textTransform: "none", 
	      							backgroundColor: "#3f51b5",
	      							padding: "8px 20px",margin: "0px"}}
	      							onClick={this.changeTestModelSearchMode}>
                          		 	{isTestModelAdvancedSearchMode? "Basic Search" : "Advanced Search"}</Button>}
                    handleSearch={this.handleTestModelSearch}
                    hintText={"Please enter minimum 4 characters"}
	      			/>
	      			</MuiThemeProvider>
	      		</div>
	      		</GridItem>
	      		<GridItem style={{backgroundColor: "#ffffff"}}  xs={12} sm={12} md={2}>
      			</GridItem>
	      		{isTestModelAdvancedSearchMode &&
	      			<React.Fragment>
	      			<GridItem  xs={12} sm={12} md={12}>
	      			</GridItem>
	      			<GridItem  xs={12} sm={12} md={2}>
	      			</GridItem>
	      		<GridItem  xs={12} sm={12} md={3} style={{paddingLeft: "50px", paddingRight: "24px"}}>
	      			<InputLabel shrink htmlFor="material">
	          			Test Model Name
	        		</InputLabel>
	      			<CustomInput
	                  //labelText="Enter Test Model Name"
	                  id="material"
	                  formControlProps={{
	                      fullWidth: true,
	                      style: {paddingTop: "5px"}
	                  }}
	                  inputProps={{
	                      style: {fontSize: "16px"},
	                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
	                      placeholder: "Enter Name",
	                      value: testModelInfo["name"],
	                      onChange: this.handleOnChangeTestModelInfo,
	                      name: "testModelInfo_name"
	                  }}
	              	/>
	              	<InputLabel shrink htmlFor="material2">
	          			Test Model Identifier
	        		</InputLabel>
	              	<CustomInput
	                  //labelText="Enter Test Model Identifier"
	                  id="material2"
	                  formControlProps={{
	                      fullWidth: true,
	                      style: {paddingTop: "5px"}
	                  }}
	                  inputProps={{
	                      style: {fontSize: "16px"},
	                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
	                      placeholder: "Enter Unique Key",
	                      value: testModelInfo["number"],
	                      onChange: this.handleOnChangeTestModelInfo,
	                      name: "testModelInfo_number"
	                  }}
	              	/>
	              	
	      		</GridItem>
	      		<GridItem  xs={12} sm={12} md={1}>
	      		</GridItem>
	      		<GridItem  xs={12} sm={12} md={3}>
	      			<div>
	              	<InputLabel shrink htmlFor="material5">
	          			Test Models Created From
	        		</InputLabel>
		            <CustomInput
			            //labelText="Enter Section"
			            id="material5"
			            formControlProps={{
			                fullWidth: true,
	                      style: {paddingTop: "5px"}
			            }}
			            inputProps={{
			                style: {fontSize: "16px"},
			                type: "date",
			                value: testModelInfo["fromDate"],
			                onChange: this.handleOnChangeTestModelInfo,
			                name: "testModelInfo_fromDate"
			            }}
			        />
	              	<InputLabel shrink htmlFor="material4">
	          			Test Models Created Till
	        		</InputLabel>
		            <CustomInput
			            //labelText="Enter Section"
			            id="material4"
			            formControlProps={{
			                fullWidth: true,
	                      style: {paddingTop: "5px"}
			            }}
			            inputProps={{
			                style: {fontSize: "16px"},
			                type: "date",
			                value: testModelInfo["toDate"],
			                onChange: this.handleOnChangeTestModelInfo,
			                name: "testModelInfo_toDate"
			            }}
			        />     
		            <Button onClick={this.handleTestModelAdvSearch} style={{textTransform: "none", 
			  							backgroundColor: "#3f51b5",
			  							padding: "8px 20px",margin: "5px",marginTop: "30px"}}>
			                  		 	Advanced Search</Button> 
			        <Button onClick={this.handleTestModelAdvSearchReset} style={{textTransform: "none", 
			  							backgroundColor: "#3f51b5",
			  							padding: "8px 20px",margin: "5px",marginTop: "30px"}}>
			                  		 	Reset</Button> 
			        </div>	
	      		</GridItem>
	      		<GridItem  xs={12} sm={12} md={3}>
	      		</GridItem>
	      		</React.Fragment>
	      		}
      			{ this.state.testModelSearchResults && this.state.testModelSearchResults.length > 0 ?
      			<GridItem  xs={12} sm={12} md={12} style={{paddingRight: "24px", paddingLeft: "24px"}}>
      			<MuiThemeProvider theme={getMuiThemeForTable}>
      				<MUIDataTable 
      			  	title={<p style={tableTitleStyle} >Test Models</p>}
      			  	options={testModelTableOptions}
      			  	columns={testModelTableColumns}  
      			  	data={this.state.testModelSearchResults}              
      				/>
      			</MuiThemeProvider>
      			</GridItem>
      			: <GridItem  xs={12} sm={12} md={12} style={{minHeight: "10vh"}}>
      			</GridItem>}
      		</React.Fragment>
      		}
      		{ this.state.activeStep === (isDuplicateFlow?0:1) &&
	      	<React.Fragment>
	      		<GridItem  xs={12} sm={12} md={2}>
	      		</GridItem>
	      		<GridItem  xs={12} sm={12} md={3}>
	      			<h3 style={subtitleStyle} >Test Details</h3>
	      			<InputLabel shrink htmlFor="material">
	          			Test Name
	        		</InputLabel>
	      			<CustomInput
	                  //labelText="Enter Test Model Name"
	                  id="material"
	                  formControlProps={{
	                      fullWidth: true,
	                      style: {paddingTop: "5px"}
	                  }}
	                  inputProps={{
	                      style: {fontSize: "16px"},
	                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
	                      placeholder: "Enter Name",
	                      value: testInfo["name"],
	                      onChange: this.handleOnChangeTestInfo,
	                      name: "testInfo_name"
	                  }}
	              	/>
	              	<InputLabel shrink htmlFor="material2">
	          			Test Number
	        		</InputLabel>
	              	<CustomInput
	                  //labelText="Enter Test Model Identifier"
	                  id="material2"
	                  formControlProps={{
	                      fullWidth: true,
	                      style: {paddingTop: "5px"}
	                  }}
	                  inputProps={{
	                      style: {fontSize: "16px"},
	                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
	                      placeholder: "Enter Unique Key",
	                      value: testInfo["number"],
	                      onChange: this.handleOnChangeTestInfo,
	                      name: "testInfo_number"
	                  }}
	              	/>
	              	<InputLabel shrink htmlFor="material3">
	          			Test Description
	        		</InputLabel>
	            	<CustomInput
	                  
	                  id="material3"
	                  formControlProps={{
	                      fullWidth: true,
	                      style: {paddingTop: "5px"}
	                  }}
	                  inputProps={{
	                      style: {fontSize: "16px"},
	                      rows: "4",
	                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
	                      placeholder: "Enter Description",
	                      value: testInfo["description"],
	                      onChange: this.handleOnChangeTestInfo,
	                      name: "testInfo_description"
	                  }}
	              	/>
	      		</GridItem>
	      		<GridItem  xs={12} sm={12} md={1}>
	      		</GridItem>
	      		<GridItem  xs={12} sm={12} md={3}>
	      			<div style={{paddingTop: "35px"}}>
	      			<InputLabel shrink htmlFor="material2">
	          			Program
	        		</InputLabel>
	      			<div style={{paddingTop: "5px"}}>
	      			<Programs
				      	name="testInfo_program"
				      	formControlWidth="60%"
		                value={testInfo["program"]}
		                change={this.handleOnChangeLOVTestInfo.bind(this)}
		              />
		            </div> 
	      			<div style={{padding: "10px"}}/>
	              	<InputLabel shrink htmlFor="material2">
	          			Test Type
	        		</InputLabel>
	        		<div style={{paddingTop: "5px"}}>
	              	<TestTypes
				      	name="testInfo_testType"
				      	formControlWidth="60%"
		                value={testInfo["testType"]}
		                change={this.handleOnChangeLOVTestInfo.bind(this)}
		              />
		              </div>
	              	<div style={{padding: "10px"}}/>
	              	{isSubjectChapterTest && 
	              		<React.Fragment>
		              	<InputLabel shrink htmlFor="material2">
		          			Subject
		        		</InputLabel>
		        		<div style={{paddingTop: "5px"}}>
		              	<Subjects
					      	name="testInfo_subject"
					      	formControlWidth="60%"
			                value={testInfo["subject"]}
			                change={this.handleOnChangeLOVTestInfo.bind(this)}
			                includeId={true}
			              />
			              </div>
	              		<div style={{padding: "10px"}}/>
		              	{ testInfo["subjectId"] &&
		              	<React.Fragment>
		              	<InputLabel shrink htmlFor="material2">
		          			Chapter
		        		</InputLabel>
		        		<div style={{paddingTop: "5px"}}>
		              	<SubjectChapters
					      	name="testInfo_subjectChapter"
					      	formControlWidth="60%"
			                value={testInfo["subjectChapter"]}
			                change={this.handleOnChangeLOVTestInfo.bind(this)}
			                includeId={true}
			                subjectId={testInfo["subjectId"]}
			              />
			              </div>
			              </React.Fragment>
			              }
			          </React.Fragment>
			          }
	              	</div>
	      		</GridItem>
	      		<GridItem  xs={12} sm={12} md={3}>
	      		</GridItem>
      		</React.Fragment>
      		}
      		{ this.state.activeStep === (isDuplicateFlow?1:2) &&
      			<React.Fragment>
      				<GridItem  xs={12} sm={12} md={2}  style={{paddingTop: "0px", paddingBottom: "0px"}}>
      				</GridItem>
      				<GridItem  xs={12} sm={12} md={10}  style={{paddingTop: "0px", paddingBottom: "0px"}}>
      					<h3 style={subtitleStyle} >New Test Summary</h3>
      				</GridItem>
      				<GridItem  xs={12} sm={12} md={2}>
      				</GridItem>
      				<GridItem  xs={12} sm={12} md={3}>
      				<InputLabel shrink htmlFor="material">
		          			Test Model Name
		        		</InputLabel>
		        		<p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{testInfo["testModelName"] ? testInfo["testModelName"] : "-" }</p>
		        		<InputLabel shrink htmlFor="material">
		          			Test Model Number
		        		</InputLabel>
		        		<p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{testInfo["testModelNumber"] ? testInfo["testModelNumber"] : "-"}</p>
      				</GridItem>
      				<GridItem  xs={12} sm={12} md={3}>
	      				<InputLabel shrink htmlFor="material">
		          			Test Name
		        		</InputLabel>
		        		<p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{testInfo["name"] ? testInfo["name"] : "-"}</p>
		        		<InputLabel shrink htmlFor="material">
		          			Test Number
		        		</InputLabel>
		        		<p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{testInfo["number"] ? testInfo["number"] : "-"}</p>
      				</GridItem>
      				<GridItem  xs={12} sm={12} md={3}>
	      				<InputLabel shrink htmlFor="material">
		          			Test Program
		        		</InputLabel>
		        		<p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{testInfo["program"] ? testInfo["program"] : "-"}</p>
		        		<InputLabel shrink htmlFor="material">
		          			Test Type
		        		</InputLabel>
		        		<p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{testInfo["testType"] ? testInfo["testType"] : "-"}</p>
      				</GridItem>
      				<GridItem  xs={12} sm={12} md={isDuplicateFlow ? 8 : 6}>
      				</GridItem>
      				<GridItem  xs={12} sm={12} md={isDuplicateFlow ? 4 : 6} style={{paddingRight: "24px", paddingLeft: "24px"}}>
      				{!isDuplicateFlow &&
      				<Button style={{textTransform: "none", 
							backgroundColor: "#3f51b5",
							margin: "5px"}}
							onClick={this.handleCreateOfflineTest}>
          				Create Test Offline</Button>
          			}
          			<Button style={{textTransform: "none", 
							backgroundColor: "#3f51b5",
						margin: "5px"}}
						onClick={this.handleCreateOnlineTest}>
          				{isDuplicateFlow ? "Duplicate Test" : "Create Test Online" }
          				</Button>	
      				</GridItem>
      			</React.Fragment>
      		}
		</GridContainer>
		</React.Fragment>
    );
  }
}

export default  withStyles(testFormStyle)(TestForm);
