import React from "react";
import { withStyles } from '@material-ui/core/styles';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

/*import Button from "CustomButtons/Button.jsx";*/

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Card from "Card/Card.jsx";
/*import CardBody from "Card/CardBody.jsx";*/
import { cardTitle } from "assets/jss/material-kit-react.jsx";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  title:{
  	padding: "0 24px",
  	...cardTitle
  },
  paper: {
    height: 140,
    width: 100,
  }
});

class CatalogTestSeriesContainer extends React.Component {
/*
	constructor(props) {
	    super(props);
	}
*/
	render() {
		 const { classes} = this.props;
		return (
			<div>
			<GridContainer style={{margin: "auto"}}>
			{[0, 1].map(value => (
          		<GridItem xs={12} sm={12} md={12} style={{margin: "auto"}}> 
	          		<Card style={{backgroundColor: "#e4e7ed", marginTop: "10px", marginBottom: "10px" }}>
					<h4 className={classes.title}>Catalog 1</h4>
					<ExpansionPanel >
			          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
			            <Typography className={classes.heading}>Test Series 1</Typography>
			            <Typography className={classes.secondaryHeading}>Details...</Typography>
			          </ExpansionPanelSummary>
			          <ExpansionPanelDetails>
			           	<Grid container spacing={16}>
		                  	<Grid item xs={12}>
		                  		<Typography className={classes.secondaryHeading}>More Details...</Typography>
		                  		<hr/>
		                  		<br/>
			                    <Grid container justify="space-around" spacing={16}>
			                      {[0, 1, 2, 3, 4, 5].map(value => (
			                        <Grid key={value} item>
			                          <Paper className={classes.paper} >	
			                          </Paper>
			                          <Typography className={classes.secondaryHeading}>Name</Typography>
			                          <Typography className={classes.secondaryHeading}>Details...</Typography>
			                        </Grid>
			                      ))}
			                    </Grid>
		                	</Grid>
		                </Grid>
			          </ExpansionPanelDetails>
			        </ExpansionPanel>
			        <ExpansionPanel >
			          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
			            <Typography className={classes.heading}>Test Series 2</Typography>
			            <Typography className={classes.secondaryHeading}>Details...</Typography>
			          </ExpansionPanelSummary>
			          <ExpansionPanelDetails>
			           	<Grid container spacing={16}>
		                  	<Grid item xs={12}>
		                  		<Typography className={classes.secondaryHeading}>More Details...</Typography>
		                  		<hr/>
		                  		<br/>
			                    <Grid container justify="space-around" spacing={16}>
			                      {[0, 1, 2, 3, 4, 5].map(value => (
			                        <Grid key={value} item>
			                          <Paper className={classes.paper} >	
			                          </Paper>
			                          <Typography className={classes.secondaryHeading}>Name</Typography>
			                          <Typography className={classes.secondaryHeading}>Details...</Typography>
			                        </Grid>
			                      ))}
			                    </Grid>
		                	</Grid>
		                </Grid>
			          </ExpansionPanelDetails>
			        </ExpansionPanel>
			        </Card>
		        </GridItem>
		    ))}
			</GridContainer>	
			</div>	
			);
	}

}

export default withStyles(styles)(CatalogTestSeriesContainer);
