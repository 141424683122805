import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import $ from 'jquery';

class ChapterTopics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chapterTopics: [],
    };
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  componentDidMount(){
    const chapterId = this.props.chapterId;
    if(chapterId){
      $.ajax({
        type: 'GET',
        url: getBaseURL() + '/get-chapter-topics.json?chapter_id='+ chapterId+'&source=dataset'
      }).done((response, status, jqXHR)=>{
          this.setState({
            chapterTopics: response
          });

      });
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.chapterId != prevProps.chapterId){
      const chapterId = this.props.chapterId;
      if(chapterId){
        $.ajax({
          type: 'GET',
          url: getBaseURL() + '/get-chapter-topics.json?chapter_id='+ chapterId+'&source=dataset'
        }).done((response, status, jqXHR)=>{
            this.setState({
              chapterTopics: response
            });

        });
      }
    }
  }

  getIdForLOV(value){
    const chapterTopics = this.state.chapterTopics;
    const filteredChapterTopics = chapterTopics.filter(chapterTopic => chapterTopic.name == value);
    if (filteredChapterTopics && filteredChapterTopics.length > 0){
     return {id: filteredChapterTopics[0].id};
    }
    return;
  }


  render() {
    const { name, value, index, change,formControlWidth, includeId, chapterId } = this.props;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select value={value} name={name} onChange={includeId ? (e) => change(e,this.getIdForLOV(e.target.value)) :change} 
          style={{fontSize: 'inherit'}}>
          { this.state.chapterTopics.map((chapterTopic, index) =>
            <MenuItem key={index} style={{fontSize: "14px"}} value={chapterTopic.name}>{chapterTopic.name}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

}


export default ChapterTopics;