import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';
import qs from 'query-string';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import TestQuestionsContainer from "components/test/TestQuestionsContainer.jsx";
import TestNavigator from "components/test/TestNavigator.jsx";
import TestHeader from "components/test/TestHeader.jsx";
import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";


class TestRegion extends React.Component {

	constructor(props) {
	    super(props);

	    this.state = {
	      testData: {},
	      currentQuestionData: {},
	      navTestData: [],
         firstQ: true,
         lastQ: false,
         testUserData: [],
         currentQuestionUserData: {},
         navfocusSubjectIndex: 0 ,
         currentQuestionTimeTaken: 0,
         submitRetryCount: 0,
         testSubmissionFailed: false,
         sectionsData: []
	    };
      this.currentQuestionTimer = null;
	  }

	componentDidMount() {
      if(!isUserSessionActive())
      {
        this.props.history.push('/login');
        return
      }

      const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
      /*const question_no = qs.parse(this.props.location.search).question_no;*/ //SCOPING OUT QUESTION NUMBERING FOR NOW
      //TODO what happens if test_id and question_no are not passed

      if(this.props.showSolutions)
        {
          $.ajax({
              type: "GET",
              url: getBaseURL() + '/get-questions-with-key.json?test_id='+test_paper_id, //Hard Coded TODO
              dataType: "JSON",
              //headers: JSON.parse(localStorage.user)
            }).done((data) => {
              if(data && data["questions"] && data["questions"].length > 0){
              this.setState({testData: data, currentQuestionData: data["questions"][0]});
              this.getTestNavigationDataFromTestData(data, true);
              this.intializeUserTestData(data, false, test_paper_id);
              } else {
                //Temporary Workaround TO BE REMOVED
                // localStorage.removeItem("testUserData");
                // localStorage.removeItem("activeTestId");

                //TODO handle all condition
                //1. Server side failure
                //2. Test time expired
                //3. Other cases
              }
            });  

        }else{
          $.ajax({
              type: "GET",
              url: getBaseURL() + '/get-questions.json?test_id='+test_paper_id, //Hard Coded TODO
              dataType: "JSON",
              //headers: JSON.parse(localStorage.user)
            }).done((data) => {
              if(data && data["questions"] && data["questions"].length > 0){
                //optional section handling
                var sections = data["sections"]
                
                //Logic To Jumble questions
                if(data["jumbleQuestionsFlag"] && data["jumbleQuestionsFlag"]=="Y"){
                  if(!localStorage.testUserData){
                    data = this.jumbleTestQuestions(data);   
                  }else{
                    data = this.retrieveJumbledQuestionsFromLocalStorage(data);
                  }
                }
                //Logic to go back to same question number of active test in case of refresh or internal navigation
                var activeTestQuestionNumber = 1;
                if(localStorage.testUserData && localStorage.activeTestQuestionNumber){
                  activeTestQuestionNumber = parseInt(localStorage.activeTestQuestionNumber);
                }
                
                this.setState({testData: data, currentQuestionData: data["questions"][activeTestQuestionNumber-1]});
            	  this.getTestNavigationDataFromTestData(data, true);
                this.intializeUserTestData(data, true,test_paper_id);
                if(sections && sections.length > 0){
                  this.intializeSectionsData(sections);  
                }
                //The following logic is to default correct subject in navigator based on active question number
                var navfocusSubjectIndex = this.state.navfocusSubjectIndex;
                const navTestData = this.state.navTestData;
                for(var i=0; i < navTestData.length; i++) {
                   if( activeTestQuestionNumber > navTestData[i]["last_question"]){
                      navfocusSubjectIndex++;
                   }
                }
                this.setState({navfocusSubjectIndex: navfocusSubjectIndex});


              } else {
                //Temporary Workaround TO BE REMOVED
                // localStorage.removeItem("testUserData");
                // localStorage.removeItem("activeTestId");

                //TODO handle all condition
                //1. Server side failure
                //2. Test time expired
                //3. Other cases
              }
            });  
        }  
  	}

    componentWillUnmount() {
        clearInterval(this.currentQuestionTimer);
      }

    storeUserDataInSession(questionNumber){
      if(!this.props.showSolutions){
        const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
           if(this.state.testUserData){        
             localStorage.setItem('testUserData',
             JSON.stringify(this.state.testUserData));
            localStorage.setItem('activeTestId',test_paper_id);
            localStorage.setItem('activeTestQuestionNumber',questionNumber);
          }
      }
    }

   //For capturing Answers and other details provided by user
   intializeUserTestData(data, localStorageFlag,test_paper_id){
      if(localStorage.testUserData && localStorageFlag && localStorage.activeTestId && localStorage.activeTestId == test_paper_id){
         var sessionData = JSON.parse(localStorage.testUserData)
         var activeTestQuestionNumber = parseInt(localStorage.activeTestQuestionNumber)
         this.setState({testUserData: sessionData, 
          currentQuestionUserData: sessionData[activeTestQuestionNumber-1],
          currentQuestionTimeTaken: sessionData[activeTestQuestionNumber-1].time_taken
          });
      }
      else{
         var size = data["questions"].length;
         var array = []
         var jsonObj = null;
         for(var i=0; i < data["questions"].length; i++) {
            jsonObj = {
               question_no: data["questions"][i].question_no,
               answer: data["questions"][i].answer ?
                  data["questions"][i].pattern_short_name == "MCQ" || data["questions"][i].pattern_short_name == "PASSAGE" ?
                    this.convNumbersToLetters(JSON.parse(data["questions"][i].answer)[0][0]) 
                  : data["questions"][i].pattern_short_name == "MAQ" ?
                    this.convMAQDataNumbersToLetters(JSON.parse(data["questions"][i].answer))
                  : data["questions"][i].pattern_short_name == "NUMERICAL" || data["questions"][i].pattern_short_name == "INTEGER" ?
                    JSON.parse(data["questions"][i].answer)[0][0] 
                  : data["questions"][i].pattern_short_name == "MM" ?
                    JSON.parse(data["questions"][i].answer)
                  : "" 
                : "",
               key: data["questions"][i].key ? 
                data["questions"][i].pattern_short_name == "MCQ" || data["questions"][i].pattern_short_name == "PASSAGE" ?
                  this.convNumbersToLetters(JSON.parse(data["questions"][i].key)[0][0]) 
                : data["questions"][i].pattern_short_name == "MAQ" ?
                    this.convMAQDataNumbersToLetters(JSON.parse(data["questions"][i].key))
                  : data["questions"][i].pattern_short_name == "NUMERICAL" || data["questions"][i].pattern_short_name == "INTEGER" ?
                    JSON.parse(data["questions"][i].key)[0][0] 
                  : data["questions"][i].pattern_short_name == "MM" ?
                    JSON.parse(data["questions"][i].key)
                  : "" 
                : "",
               markedForReview: false,
               visited: false,
               time_taken: data["questions"][i].time_taken ? data["questions"][i].time_taken : 0,
               question_id: data["questions"][i].id,
               subject_id: data["questions"][i].global_subject_id,
               section_id: data["questions"][i].section_id,
               is_answerable: true
            }
            if(i==0){
              jsonObj.visited = true; //CurrentQuestion data visited defaulted to true
            }
            array[i] = jsonObj;
         }
         this.setState({testUserData: array, currentQuestionUserData: array[0]});
         
         this.storeUserDataInSession(1);
      }
      if(!this.props.showSolutions){
        this.currentQuestionTimer = setInterval(this.currentQuestionTimeProgress, 1000);
      }
   };


   intializeSectionsData(sectionsData){
    for(var i in sectionsData){
      var answered_questions = this.getAnsweredQuestionsInSection(sectionsData[i].id)
      // sectionsData[i]["answered_questions"] = answered_questions
      if( sectionsData[i]["required_questions"] && answered_questions >=  sectionsData[i]["required_questions"]){
        this.updateSectionQuestions(sectionsData[i].id, false)
      }
    }
    this.setState({sectionsData})
   }

   getAnsweredQuestionsInSection(section_id){
      let testUserData = this.state.testUserData;
      let sectionUserData = testUserData.filter(qUserData => (qUserData.section_id == section_id))
      let answeredQuestionInSectionData = sectionUserData.filter(qUserData=> ( qUserData.answer.length > 0 ))
      let answered_questions = answeredQuestionInSectionData.length
      return answered_questions
   }

  updateSectionQuestions(section_id, is_answerable){
      let testUserData = this.state.testUserData;
      testUserData.filter(qUserData => (qUserData.section_id == section_id)).map((qUserData)=>{qUserData.is_answerable = is_answerable})
      this.setState({testUserData})
  }

  updateSectionsMap(section_id){
    let sectionsData = this.state.sectionsData;
    let currentSection = sectionsData.filter(section => (section.id == section_id))
    if(currentSection.length > 0){
      var answered_questions = this.getAnsweredQuestionsInSection(section_id)
      // sectionsData.filter(section => (section.id == section_id)).map((sectionData)=>{sectionData["answered_questions"] = answered_questions})
      if(currentSection[0]["required_questions"]){
        if(answered_questions >=  currentSection[0]["required_questions"]){
          this.updateSectionQuestions(section_id, false)
        }else{
          this.updateSectionQuestions(section_id, true)
        }
      }
    }
  }


   //Save current question time and start next question timer
   handleQuestionTimeTaken(Qnumber){
    var currentQuestionTimeTaken = this.state.currentQuestionTimeTaken;
    if(currentQuestionTimeTaken != 0){
      var currQuestionNumber = parseInt(Qnumber);
      var array = this.state.testUserData;
      array[currQuestionNumber-1].time_taken = currentQuestionTimeTaken; 
      this.setState({testUserData:array, currentQuestionTimeTaken:0});
      this.storeUserDataInSession(currQuestionNumber);
    }
   }

   currentQuestionTimeProgress = () => {
    const currentQuestionTimeTaken = this.state.currentQuestionTimeTaken;
    this.setState({currentQuestionTimeTaken: (currentQuestionTimeTaken+1)});
    if((currentQuestionTimeTaken+1)%10 == 0){
      const currQuestionNumber = parseInt(this.state.currentQuestionData.question_no);
      var array = this.state.testUserData;
      array[currQuestionNumber-1].time_taken = currentQuestionTimeTaken+1; 
      this.setState({testUserData:array});
      this.storeUserDataInSession(currQuestionNumber);
    }
   };

   convLettersToNumbers(str) {
    "use strict";
    var out = 0, len = str.length, pos = len;
    while (--pos > -1) {
        out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - 1 - pos);
    }
    return out.toString();
   } ; 

   convNumbersToLetters(int) {
    return String.fromCharCode(64 + parseInt(int));
   } ; 

   convMAQAnswerDataLettersToNumber(answer){
    if(!answer){
        return;
    }
    var  ansNumberArray = new Array();
    const ansLettersArray = answer.split(',');
    for(var i in ansLettersArray){
      ansNumberArray.push(this.convLettersToNumbers(ansLettersArray[i])); 
    }
    return ansNumberArray;
   }

   convMAQDataNumbersToLetters(ansNumberArray){
    if(!ansNumberArray || !ansNumberArray[0]){
        return;
    }
    var ansLetterArray = "";
    for(var i in ansNumberArray[0]){
      if (ansLetterArray != "")
       ansLetterArray = ansLetterArray + ","+(String.fromCharCode(64 + parseInt(ansNumberArray[0][i])));
     else
      ansLetterArray = (String.fromCharCode(64 + parseInt(ansNumberArray[0][i])))
    }
    return ansLetterArray;
   }

   processUserDataForSaveAndSubmit(testUserData){

    var testData = this.state.testData["questions"];
    var  answersArray = new Array();
      for(var i=0; i < testUserData.length; i++) {
        var jsonObj = {
          "question_id": testUserData[i].question_id,
          "subject_id": testUserData[i].subject_id,
          "time_taken": testUserData[i].time_taken
        }
        if(testUserData && testUserData[i] && testData && testData[i] && testUserData[i].answer != null && testUserData[i].answer != ""){
          if(testData[i].pattern_short_name == "MCQ" || testData[i].pattern_short_name == "PASSAGE"){
             jsonObj.answer = [[this.convLettersToNumbers(testUserData[i].answer)]]
          }else if(testData[i].pattern_short_name == "MAQ"){
             jsonObj.answer = [this.convMAQAnswerDataLettersToNumber(testUserData[i].answer)]
          }else if(testData[i].pattern_short_name == "NUMERICAL" || testData[i].pattern_short_name == "INTEGER"){
            jsonObj.answer = [[testUserData[i].answer]]
          }else if(testData[i].pattern_short_name == "MM"){
            jsonObj.answer = testUserData[i].answer
          }
          //jsonObj.answer = [this.convMAQAnswerDataLettersToNumber(testUserData[i].answer)]
        } 
        answersArray.push(jsonObj);
      }
      return answersArray
   }

   //INFO: Any changes in this method make sure if they are needed in handleAutoSave method
   submitTestData = (e) => {
      const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
      const test_id = qs.parse(this.props.location.search).test_id;
      var testUserData = this.state.testUserData;
      var currentQuestionUserData = this.state.currentQuestionUserData;

      var activeTestId = localStorage.activeTestId;
      if(activeTestId != test_paper_id){
        alert("The current test is not active test. You cannot submit a non-active test.")
        this.props.history.push('/home/enterprise/testHome?test_id='+test_id);
        return
      }
      
      if( !(testUserData.length > 0) && localStorage.testUserData && localStorage.activeTestQuestionNumber){
         testUserData = JSON.parse(localStorage.testUserData);
         //TODO what if activeTestQuestionNumber is not present
         var activeTestQuestionNumber = parseInt(localStorage.activeTestQuestionNumber);
         currentQuestionUserData = testUserData[activeTestQuestionNumber-1];
      }
      //TODO Error Handling - when both testUserData is not in state and storage

      //For question duration analysis
      //If submit failed in previous attempt, the timer will be cleard, 
      //but the previousTimeTaken is not lost as we only update timeTaken when it is not 0
      if(currentQuestionUserData && currentQuestionUserData.question_no){
        var currQuestionNumber = currentQuestionUserData.question_no;
        this.handleQuestionTimeTaken(currQuestionNumber);
        clearInterval(this.currentQuestionTimer);
      }

      if(!(testUserData && testUserData.length > 0)){
        alert("Unable to submit the test. No Test data found.");
        this.props.history.push('/home/enterprise/testHome?test_id='+test_id);
        return
      }

      var  answersArray = this.processUserDataForSaveAndSubmit(testUserData)
      
      var postUserData = {
         "answers": answersArray,
         "scheduled_test_paper_id": test_paper_id,
         "isAutosave": false
      };

      //Check if User session Active before submitting data
      if(!isUserSessionActive())
      {
        this.props.history.push('/login');
        return
      }

      $.ajax({
      type: 'POST',
      url: getBaseURL() + '/api/store-user-answers.json',
      data: JSON.stringify(postUserData),
      contentType: 'application/json; charset=utf-8',
      //headers: JSON.parse(localStorage.user)
    }).done((response, status, jqXHR)=>{

      if(response.status == 200){
         localStorage.removeItem("testUserData");
         localStorage.removeItem("activeTestId");
         localStorage.removeItem("activeTestQuestionNumber");
         localStorage.removeItem("activeTestAutoSaved");
         this.props.history.push('/home/enterprise/testHome?finishTest=true&test_id='+test_id);
      }
    }).fail((jqXHR, textStatus, errorThrown) => {         
        var submitRetryCount = this.state.submitRetryCount;
        if ( submitRetryCount <= 3 ){
          this.setState({submitRetryCount: (parseInt(submitRetryCount)+1)})       
          setTimeout(this.submitTestData.bind(this), 5000, e);
        }else{
          this.setState({testSubmissionFailed: true})
        }
    })
      

   }

   handleTestAutoSave = (e) => {
      const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
      const test_id = qs.parse(this.props.location.search).test_id;
      var testUserData = this.state.testUserData;

      var activeTestId = localStorage.activeTestId;
      if(activeTestId != test_paper_id){
        console.log("Active Test doesn't match. Test AutoSave Failed.");
        return
      }
    
      if( !(testUserData.length > 0) && localStorage.testUserData){
         testUserData = JSON.parse(localStorage.testUserData);
      }
      //TODO Error Handling - when both testUserData is not in state and storage

      var  answersArray = this.processUserDataForSaveAndSubmit(testUserData)
      var postUserData = {
         "answers": answersArray,
         "scheduled_test_paper_id": test_paper_id,
         "isAutosave": true
      };
      //Check if User session Active before submitting data
      if(!isUserSessionActive())
      {
        console.log("User Session not active. Test AutoSave Failed.");
        return
      }
      $.ajax({
      type: 'POST',
      url: getBaseURL() + '/api/store-user-answers.json',
      data: JSON.stringify(postUserData),
      contentType: 'application/json; charset=utf-8',
      //headers: JSON.parse(localStorage.user)
      }).done((response, status, jqXHR)=>{

        if(response.status == 200){
          localStorage.setItem('activeTestAutoSaved', true);
          console.log("Test Autosaved.")
        }
      }).fail((jqXHR, textStatus, errorThrown) => {   
      //NEED to  check impact on exisiting localStorage      
        console.log(jqXHR);
      })
      

   }


   getTestNavigationDataFromTestData(data,changeStateFlag){
      if(data["template_details"]!= null){
        var oldSubject = data["template_details"][0].global_subject_id; 
        var oldSubPattern = data["template_details"][0].global_question_pattern_id;
        var subPatternArr = [{pattern: data["template_details"][0].pattern_name, 
                    first_question: data["template_details"][0].first_question_no,
                    last_question: data["template_details"][0].last_question_no,
                    positive_marks: data["template_details"][0].positive_marks,
                    negative_marks: data["template_details"][0].negative_marks,
                    partial_marks: data["template_details"][0].partial_marks}]
        var navTestData = [{
            subject: data["template_details"][0].subject_name,
            first_question: data["template_details"][0].first_question_no,
            patterns: subPatternArr,
            last_question: data["template_details"][0].last_question_no
          }]
         var subjects_count = 0;
         var subLastQuestion = data["template_details"][0].last_question_no;
        for(var i=0; i < data["template_details"].length; i++) {
            var currSubject = data["template_details"][i].global_subject_id;
            var currSubPattern = data["template_details"][i].global_question_pattern_id;
            if(currSubject!=oldSubject){
              navTestData[subjects_count]["last_question"] = subLastQuestion
              subPatternArr = [{pattern: data["template_details"][i].pattern_name, 
                first_question: data["template_details"][i].first_question_no,
                last_question: data["template_details"][i].last_question_no,
                positive_marks: data["template_details"][i].positive_marks,
                negative_marks: data["template_details"][i].negative_marks,
                partial_marks: data["template_details"][i].partial_marks}]
              navTestData.push({
                subject: data["template_details"][i].subject_name,
                first_question: data["template_details"][i].first_question_no,
                patterns: subPatternArr,
                last_question: data["template_details"][i].last_question_no
              });
              subLastQuestion = data["template_details"][i].last_question_no;
              oldSubPattern = currSubPattern; 
              oldSubject = currSubject;
               subjects_count++;
            }else if(currSubPattern!=oldSubPattern ){
              subPatternArr.push({pattern: data["template_details"][i].pattern_name, 
                first_question: data["template_details"][i].first_question_no,
                last_question: data["template_details"][i].last_question_no,
                positive_marks: data["template_details"][i].positive_marks,
                negative_marks: data["template_details"][i].negative_marks,
                partial_marks: data["template_details"][i].partial_marks});
               subLastQuestion = data["template_details"][i].last_question_no;
               oldSubPattern = currSubPattern;
            }else if(i!=0 && currSubPattern==oldSubPattern && currSubject==oldSubject){ 
                subPatternArr.push({pattern: data["template_details"][i].pattern_name, 
                  first_question: data["template_details"][i].first_question_no,
                  last_question: data["template_details"][i].last_question_no});
                 subLastQuestion = data["template_details"][i].last_question_no;
            }
        }
         navTestData[subjects_count]["patterns"] = subPatternArr
         navTestData[subjects_count]["last_question"] = subLastQuestion
        if(changeStateFlag){
          this.setState({navTestData: navTestData});
        }else{
          return navTestData
        }
      }
    }

   // handleNavfocusSubject = (event, value) => {
   //    this.setState({ navfocusSubjectIndex: value });
   // };

    handleNavfocusSubject = (e) => {
      e.preventDefault();
      this.navToQuestion(e);
      this.setState({ navfocusSubjectIndex: e.currentTarget.name});
   };

   navNextQuestion = (e) =>{
     var currQuestionNumber = this.state.currentQuestionData.question_no; //TODO Error handling if there is not currentQuestionData
     if(!(this.props.showSolutions)){
      this.handleQuestionTimeTaken(currQuestionNumber);
      }

     var data = this.state.testData; //TODO Error handling if testData is not present
      if(currQuestionNumber < data["questions"].length)
     this.setState({currentQuestionData: data["questions"][currQuestionNumber], 
        currentQuestionUserData: this.state.testUserData[currQuestionNumber],
        currentQuestionTimeTaken: this.state.testUserData[currQuestionNumber].time_taken
        });
      
      if(currQuestionNumber == data["questions"].length-1)
       this.setState({lastQ: true});  
      else this.setState({lastQ: false, firstQ: false});  

      const navTestData = this.state.navTestData;
      const nextQuestionNumber = currQuestionNumber+1;
      var navfocusSubjectIndex = 0 
      for(var i=0; i < navTestData.length; i++) {
         if( nextQuestionNumber > navTestData[i]["last_question"]){
            navfocusSubjectIndex++;
         }
      }
      this.setState({navfocusSubjectIndex: navfocusSubjectIndex});
      //Change visited property of the question
      if(!(this.props.showSolutions)){
      this.handleQuestionVisited(currQuestionNumber+1);
      }  
   }

   navPrevQuestion = (e) =>{
     var currQuestionNumber = this.state.currentQuestionData.question_no; //TODO Error handling if there is not currentQuestionData 
     if(!(this.props.showSolutions)){
      this.handleQuestionTimeTaken(currQuestionNumber);
      }

     var data = this.state.testData; //TODO Error handling if testData is not present
     if(currQuestionNumber-2 >= 0)
     this.setState({currentQuestionData: data["questions"][currQuestionNumber-2], 
      currentQuestionUserData: this.state.testUserData[currQuestionNumber-2],
      currentQuestionTimeTaken: this.state.testUserData[currQuestionNumber-2].time_taken
      });
     
     if(currQuestionNumber-2 == 0)
       this.setState({firstQ: true}); 
     else this.setState({firstQ: false, lastQ: false}); 

     const navTestData = this.state.navTestData;
      const prevQuestionNumber = currQuestionNumber-1;
      var navfocusSubjectIndex = 0 
      for(var i=0; i < navTestData.length; i++) {
         if( prevQuestionNumber > navTestData[i]["last_question"]){
            navfocusSubjectIndex++;
         }
      }
      this.setState({navfocusSubjectIndex: navfocusSubjectIndex}); 
      //Change visited property of the question
      if(!(this.props.showSolutions)){
        this.handleQuestionVisited(currQuestionNumber-1);
      }
   }

   navToQuestion = (e) =>{

    if(!(this.props.showSolutions)){
     var currQuestionNumber = this.state.currentQuestionData.question_no;
      this.handleQuestionTimeTaken(currQuestionNumber);
    }

     var qNumber = e.currentTarget.id; //TODO Error Handling 
     var data = this.state.testData; //TODO Error handling if testData is not present
     if(qNumber >= 1 && qNumber <= data["questions"].length)
     this.setState({currentQuestionData: data["questions"][qNumber-1], 
      currentQuestionUserData: this.state.testUserData[qNumber-1],
      currentQuestionTimeTaken: this.state.testUserData[qNumber-1].time_taken
     });
      if(qNumber == data["questions"].length)
       this.setState({lastQ: true, firstQ: false});  
      else if(qNumber == 1)
       this.setState({lastQ: false, firstQ: true}); 
      else  this.setState({lastQ: false, firstQ: false}); 
      //Change visited property of the question
      if(!(this.props.showSolutions)){
      this.handleQuestionVisited(qNumber);
      }
   }

     handleQuestionVisited(Qnumber){
      var currQuestionNumber = parseInt(Qnumber);
      var array = this.state.testUserData;
      array[currQuestionNumber-1].visited = true; 
      this.setState({testUserData:array, currentQuestionUserData: array[currQuestionNumber-1]});
      this.storeUserDataInSession(currQuestionNumber);
     };

    handleAnswerChange = event => {
      var currQuestionNumber = this.state.currentQuestionUserData.question_no;
      var array = this.state.testUserData;
      array[currQuestionNumber-1].answer = event.target.value; //Array starts from 0
      this.setState({testUserData:array, currentQuestionUserData: array[currQuestionNumber-1]});
      this.updateSectionsMap(array[currQuestionNumber-1].section_id);
      this.storeUserDataInSession(currQuestionNumber);
     };

     handleMultipleAnswerChange = (answer) => {
      var currQuestionNumber = this.state.currentQuestionUserData.question_no;
      var array = this.state.testUserData;
      array[currQuestionNumber-1].answer = answer; //Array starts from 0
      this.setState({testUserData:array, currentQuestionUserData: array[currQuestionNumber-1]});
      this.updateSectionsMap(array[currQuestionNumber-1].section_id);
      this.storeUserDataInSession(currQuestionNumber);
     }

     handleMatrixAnswerChange = (answer) => {
      var currQuestionNumber = this.state.currentQuestionUserData.question_no;
      var array = this.state.testUserData;
      array[currQuestionNumber-1].answer = answer; //Array starts from 0
      this.setState({testUserData:array, currentQuestionUserData: array[currQuestionNumber-1]});
      this.updateSectionsMap(array[currQuestionNumber-1].section_id);
      this.storeUserDataInSession(currQuestionNumber);
     }

     handleMarkForReviewChange = event => {
      var currQuestionNumber = this.state.currentQuestionUserData.question_no;
      var array = this.state.testUserData;
      array[currQuestionNumber-1].markedForReview = event.target.checked ; //Array starts from 0
      this.setState({testUserData:array, currentQuestionUserData: array[currQuestionNumber-1]});
      this.storeUserDataInSession(currQuestionNumber);
     };

     handleClearResponse = event => {
      var currQuestionNumber = this.state.currentQuestionUserData.question_no;
      var array = this.state.testUserData;
      array[currQuestionNumber-1].answer = "" //Clearing Answer
      this.setState({testUserData:array, currentQuestionUserData: array[currQuestionNumber-1]});
      this.updateSectionsMap(array[currQuestionNumber-1].section_id);
      this.storeUserDataInSession(currQuestionNumber);
      console.log('response cleared')
     };

     jumbleTestQuestions(data){
      var jumbledTestData = data;
      var jumbledTestQuestionsData = [];
      var testModelData = this.getTestNavigationDataFromTestData(data, false);
      for(var i=0; i< testModelData.length;i++ ){
        var subjectData = testModelData[i]
        var subPatterns = subjectData["patterns"]
        for(var j=0; j< subPatterns.length; j++){
          var currentPatternQuestions = data["questions"].filter(question => (question.question_no >= subPatterns[j].first_question &&  question.question_no <= subPatterns[j].last_question));
          var jumbledPatternQuestions = this.shuffle(currentPatternQuestions);
          jumbledTestQuestionsData.push(...jumbledPatternQuestions);
        }
      }
      for(var i=0; i < jumbledTestQuestionsData.length; i++) {
          jumbledTestQuestionsData[i].question_no = i+1
          // jumber options as well
          let options = jumbledTestQuestionsData[i]?.question_content?.options
          if(options && options?.length > 0){
            jumbledTestQuestionsData[i].question_content.options = this.shuffle(options);
            jumbledTestQuestionsData[i].question_content.isOptionsJumbled = true
          }
        }

      jumbledTestData["questions"] = jumbledTestQuestionsData
      return jumbledTestData
     }

     shuffle(array) {
       var currentIndex = array.length, temporaryValue, randomIndex;
       while (0 !== currentIndex) {
         randomIndex = Math.floor(Math.random() * currentIndex);
         currentIndex -= 1;
         temporaryValue = array[currentIndex];
         array[currentIndex] = array[randomIndex];
         array[randomIndex] = temporaryValue;
       }
       return array;
     }

     retrieveJumbledQuestionsFromLocalStorage(data){
      var prevJumbledTestData = data;
      var prevJumbledTestQuestions = []
      var testUserData = JSON.parse(localStorage.testUserData)
      for(var i=0; i< testUserData.length;i++){
        var qId = testUserData[i].question_id;
        var currentQ = data["questions"].filter(question => question.id == qId)
        currentQ[0]["question_no"] = testUserData[i].question_no;
        prevJumbledTestQuestions.push(...currentQ)
      }
        prevJumbledTestData["questions"] = prevJumbledTestQuestions
        return prevJumbledTestData
     }


	render() {
		/* const { classes} = this.props;*/
		return (
			<div>  		 	
			 <GridContainer style={{margin: "auto"}} spacing={16} >
			 	<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
			 	<div style={{height: "auto", backgroundColor: "white"}}>
			 		<TestHeader data={this.state.testData} handleTestAutoSave={this.handleTestAutoSave} 
          submitTestData={this.submitTestData}
          testSubmissionFailed={this.state.testSubmissionFailed} {...this.props}/>
			 	 </div>
			 	</GridItem>
			 	<GridItem xs={12} sm={12} md={9} style={{paddingRight: "0px"}}>
			 	<div style={{height: "100%", backgroundColor: "white"}}>
			 		<TestQuestionsContainer testData={this.state.currentQuestionData} 
               nextQ={this.navNextQuestion} 
               prevQ={this.navPrevQuestion}
               lastQ={this.state.lastQ}
               firstQ={this.state.firstQ}
               userData={this.state.currentQuestionUserData}
               handleAnswerChange={this.handleAnswerChange}
               handleMultipleAnswerChange ={this.handleMultipleAnswerChange}
               handleMatrixAnswerChange={this.handleMatrixAnswerChange}
               handleMarkForReviewChange={this.handleMarkForReviewChange}
               handleClearResponse={this.handleClearResponse} {...this.props}/>
			 	 </div>
			 	</GridItem>
			 	<GridItem xs={12} sm={12} md={3}>
			 	<div style={{height: "90vh", backgroundColor: "white"}}>
			 		<TestNavigator data={this.state.navTestData} 
               navToQ={this.navToQuestion}
               currentQuestionData={this.state.currentQuestionData}
               navfocusSubjectIndex={this.state.navfocusSubjectIndex} 
               handleNavfocusSubject={this.handleNavfocusSubject}
               testUserData={this.state.testUserData} {...this.props}/>
			 	</div>
			 	</GridItem>
			 </GridContainer>	
			 </div>	
			);
	}

}

export default TestRegion;

