import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

/*import Button from "CustomButtons/Button.jsx";*/

import TestsListingContainer from "components/enterprise/components/TestsListingContainer.jsx"

import TextStyle from "styles/TextStyle.jsx";
import { withStyles } from '@material-ui/core/styles';


class UserTestsRegion extends React.Component {

	 goToHome = (e) =>{
  		this.props.history.push('/home');
  	}

	render() {
		 const { classes} = this.props;
		return (
			<div>	
			  	<GridContainer style={{margin: "auto"}} justify="center" alignItems="center" direction="row">
			 		
			 		<GridItem xs={12} sm={12} md={12}>
					 <div style={{height: "auto", backgroundColor: "white"}}>
					 	<h3 style={{padding: "20px", paddingBottom: "0px"}}
					 	className={classes.robotoFontBold}>
			 	          My Tests
			 	    	</h3>
			 	    	<hr className={classes.lineBreak} />
					 	<TestsListingContainer {...this.props}/>
					 </div>
			 		</GridItem>
			 	</GridContainer>
			 </div>	
			);
	}

}

export default withStyles(TextStyle)(UserTestsRegion);
