import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import CustomSearchBar from "components/misc/CustomSearchBar"
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Button from "CustomButtons/Button.jsx";
import InputLabel from '@material-ui/core/InputLabel';
import CustomInput from "CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import ShortText from "@material-ui/icons/ShortText";
import Programs from "components/lov/Programs";
import TestTypes from "components/lov/TestTypes";
import ErrorLogo from "@material-ui/icons/Error";
import testFormStyle from "styles/TestFormStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import AutoSuggestSelect from "components/misc/AutoSuggestSelect"
import ScheduleStatus  from "components/lov/ScheduleStatus"
import ExpandableRowSchTestPaper from "components/schedule/ExpandableRowSchTestPaper"

import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MUIDataTableToolbarSelect:{
      	title:{
      		display: "none"
      	},
      	root:{
      		backgroundColor: "none",
      		boxShadow: "none",
      		justifyContent: "normal"
      	}
      },
      MUIDataTableHeadCell: {
      	fixedHeader:{
      		zIndex: "none"
      	}
      },
    typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	}
  })

 const getMuiThemeForSearchBar = createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation1: {
          boxShadow: "none",
           border: "1px solid #aaacb1"
        },
        rounded:{
        	borderRadius: "10px"
        }
      },
      typography: {
    	useNextVariants: true,
  	  },
    },
    typography: {
    	useNextVariants: true,
  	},
  })


class ManageSchedules extends React.Component {

constructor(props){
	super(props);
	this.state = {
		errorMessages: [],
		scheduledTestSearchResults: [],
		scheduledTestRowSelected: "",
		scheduledTestInfo: {},
		userGroupSelectList: [],
	  	userGroupAdvSearchResults: {},
	  	isAdvancedSearchMode: false,
	  	searchResultsDataForStateRetention: {}
	};
}

storeStateCallback(){
	sessionStorage.setItem('scheduleSearchResults', JSON.stringify(this.state.searchResultsDataForStateRetention));
}

componentDidMount(){

	if(sessionStorage.scheduleSearchResults){
		var data = JSON.parse(sessionStorage.scheduleSearchResults);
		if(data && data['response'] && data['response']["scheduled_test_info"] && data['response']["scheduled_test_info"].length > 0){
			this.populateSchedulesTableFromResults(data['response']);
			this.setState({searchResultsDataForStateRetention: data});
			sessionStorage.removeItem('scheduleSearchResults');
			return
		}
	}
	this.fetchInitialSchedules()
}

fetchInitialSchedules(){
	var ninetyDaysInPast = new Date();
	ninetyDaysInPast = new Date(ninetyDaysInPast.getTime() - 90*24*60*60*1000) //90 days back

	$.ajax({
	    type: 'GET',
	    url: getBaseURL() + '/get-schedule-tests.json?limit=20&start_time='+ ninetyDaysInPast,
    }).done((data, status, jqXHR)=>{
    	this.populateSchedulesTableFromResults(data['response']);
    	this.setState({searchResultsDataForStateRetention: data});
    }).fail((jqXHR, textStatus, errorThrown) => {
    	var errorMessages = this.state.errorMessages;
    	errorMessages.push({message: "An Unexpected Error occured while searching for Test Model."})
        this.setState({errorMessages: errorMessages});
    })

}


handleScheduledTestSearch = (searchText) => {
 	if(searchText && searchText.length > 3){ //Minimum three characters to search
	    $.ajax({
	      type: 'GET',
	      url: getBaseURL() + '/get-schedule-tests.json?limit=20&name=' + searchText,
	    }).done((data, status, jqXHR)=>{
	    	this.populateSchedulesTableFromResults(data['response']);
	    	this.setState({searchResultsDataForStateRetention: data});
	    }).fail((jqXHR, textStatus, errorThrown) => {
	    	var errorMessages = this.state.errorMessages;
	    	errorMessages.push({message: "An Unexpected Error occured while searching for Test Model."})
	        this.setState({errorMessages: errorMessages});
	    })
 	} else{
 		this.setState({scheduledTestSearchResults: []});
 	}
}


populateSchedulesTableFromResults(data){
	if(! (data && data["scheduled_test_info"])){
		//this.props.history.goBack()
		return
	}
	var scheduledTestInfo = data["scheduled_test_info"];
	var scheduledTestSearchResults = []
	if(scheduledTestInfo && scheduledTestInfo.length > 0){
		for(var i in scheduledTestInfo){
			scheduledTestSearchResults.push([
				scheduledTestInfo[i].id, 
				scheduledTestInfo[i].name,
				scheduledTestInfo[i].identifier,
				scheduledTestInfo[i].status,
				scheduledTestInfo[i].program_short_name,
				scheduledTestInfo[i].test_type_short_name,
				scheduledTestInfo[i].user_group_info_identifier,
				scheduledTestInfo[i].description,
				scheduledTestInfo[i].scheduled_test_paper_id_arr +'|'+ scheduledTestInfo[i].paper_no_arr +'|'+
				scheduledTestInfo[i].test_paper_name_arr +'|'+ scheduledTestInfo[i].start_time_arr +'|'+
				scheduledTestInfo[i].end_time_arr +'|'+ scheduledTestInfo[i].duration_arr +'|'+ scheduledTestInfo[i].no_of_attempts_arr,
				scheduledTestInfo[i].is_test_active == 'Y' ? true : false
				]);
		}
		this.setState({scheduledTestSearchResults});
	}else{
		this.setState({scheduledTestSearchResults: []});
	}
	this.setState({errorMessages: []});
}

//changelisterner For scheduled test info except program and testtype lov
handleOnChangeScheduleTestInfo = (e) => {
 	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var scheduledTestInfo = this.state.scheduledTestInfo;
 	scheduledTestInfo[attr] = e.target.value;
 	this.setState({scheduledTestInfo});
}

//changelisterner for scheduled test info program and test type
handleOnChangeLOVScheduleTestInfo(e,obj) {
 	e.preventDefault();	
 	var targetName = e.target.name;
 	var targetNameComp  = targetName.split("_");
 	var attr = targetNameComp[1];
 	var scheduledTestInfo = this.state.scheduledTestInfo;
 	scheduledTestInfo[attr] = e.target.value;
 	if(obj && obj.id){ //Save Id as for for LOV's
 		scheduledTestInfo[attr+"Id"] = obj.id
 	}
 	this.setState({scheduledTestInfo});
}

handleUserGroupChange =(selectedUserGroup) => {
 	var scheduledTestInfo = this.state.scheduledTestInfo;
 	scheduledTestInfo["userGroupId"] = selectedUserGroup.value;
 	scheduledTestInfo["userGroupIdentifier"] = selectedUserGroup.label;
 	this.setState({scheduledTestInfo});
}


handleUserGroupAdvSearch = (data) =>{
	var scheduledTestInfo = this.state.scheduledTestInfo;
	var userGroupIdentifier = scheduledTestInfo["userGroupIdentifier"];
	var userGroupName = scheduledTestInfo["userGroupName"];
	if(!userGroupName){
		userGroupName = "";
	}
	if(!userGroupIdentifier){
		userGroupIdentifier = "";
	}

	$.ajax({
      type: 'GET',
      url: getBaseURL() + '/search-user-groups.json?name='+userGroupName+'&identifier='+userGroupIdentifier+'&limit=30',
    }).done((data, status, jqXHR)=>{	
		this.populateUserGroupAdvSearchResults(data);
    }).fail((jqXHR, textStatus, errorThrown) => {
    	var errorMessages = this.state.errorMessages;
    	errorMessages.push({message: "An Unexpected Error occured while searching for Test Model."})
        this.setState({errorMessages: errorMessages});
    })
}

populateUserGroupAdvSearchResults(data){
	var userGroupAdvSearchResults = {}
	var userGroupAdvSearchColumns = [
			{name: "userGroupId",
		        options: {
		          display: false,
		        }
		    }, 
			{name: "Name"},
			{name: "Identifier"},
			{name: "Description"},
	]
	userGroupAdvSearchResults["columns"] = userGroupAdvSearchColumns;
	
	var userGroupAdvSearchData = []
	if(data && data.length > 0){
		for(var i in data){
			userGroupAdvSearchData.push([data[i].id, data[i].name, data[i].identifier, data[i].description])
		}		
		userGroupAdvSearchResults["data"] = userGroupAdvSearchData;
		this.setState({userGroupAdvSearchResults});
	}else{
		this.setState({userGroupAdvSearchResults});
	}
} 

changeSearchMode = (e) => {
	var isAdvancedSearchMode = this.state.isAdvancedSearchMode;
	this.initUserGroupListData();
	this.setState({isAdvancedSearchMode: !isAdvancedSearchMode});
	
}

initUserGroupListData(){
	//Load data for User Groups for create flow(not edit flow)
	 $.ajax({
      type: 'GET',
      url: getBaseURL() + '/search-user-groups.json?limit=30',
    }).done((data, status, jqXHR)=>{
    	if(data && data.length > 0){
	    	var userGroupSelectList = data.map((userGroup) => ({
			label: userGroup.identifier, value: userGroup.id}))
			this.setState({userGroupSelectList})
		}
    }).fail((jqXHR, textStatus, errorThrown) => {
    	var errorMessages = this.state.errorMessages;
    	errorMessages.push({message: "An Unexpected Error occured while searching for Test Model."})
        this.setState({errorMessages: errorMessages});
    })  
}

handleScheduledTestAdvSearch = (e) => {
	e.preventDefault();
	var scheduledTestInfo = this.state.scheduledTestInfo;

	var url = getBaseURL() + "/get-schedule-tests.json?limit=20";
	if(scheduledTestInfo["name"]){
		url = url + "&name="+scheduledTestInfo["name"]
	}
	if(scheduledTestInfo["number"]){
		url = url + "&identifier="+scheduledTestInfo["number"]
	}
	if(scheduledTestInfo["fromDate"]){
		url = url + "&start_time="+scheduledTestInfo["fromDate"]
	}
	if(scheduledTestInfo["toDate"]){
		url = url + "&end_time="+scheduledTestInfo["toDate"]
	}
	if(scheduledTestInfo["status"]){
		url = url + "&status="+scheduledTestInfo["status"]
	}
	if(scheduledTestInfo["programId"]){
		url = url + "&global_program_id="+scheduledTestInfo["programId"]
	}
	if(scheduledTestInfo["testTypeId"]){
		url = url + "&test_type_id="+scheduledTestInfo["testTypeId"]
	}
	if(scheduledTestInfo["userGroupId"]){
		url = url + "&global_user_groups_info_id="+scheduledTestInfo["userGroupId"]
	}

	 $.ajax({
	    type: 'GET',
	    url: url,
    }).done((data, status, jqXHR)=>{
    	this.populateSchedulesTableFromResults(data['response']);
    	this.setState({searchResultsDataForStateRetention: data});
    }).fail((jqXHR, textStatus, errorThrown) => {
    	var errorMessages = this.state.errorMessages;
    	errorMessages.push({message: "An Unexpected Error occured while searching for Test Model."})
        this.setState({errorMessages: errorMessages});
    })
}


handleScheduledTestAdvSearchReset =(e) => {
	e.preventDefault();
	var scheduledTestInfo = this.state.scheduledTestInfo;
 	scheduledTestInfo["name"] = "";
 	scheduledTestInfo["number"] = "";
	scheduledTestInfo["fromDate"] = "";
	scheduledTestInfo["toDate"] = "";
	scheduledTestInfo["status"] = "";
	scheduledTestInfo["program"] = "";
	scheduledTestInfo["programId"] = "";
	scheduledTestInfo["testType"] = "";
	scheduledTestInfo["testTypeId"] = "";
	scheduledTestInfo["userGroupId"] = "";
	scheduledTestInfo["userGroupIdentifier"] = "";
	this.setState({scheduledTestInfo});
}

errorMessageCallback = (errorMessage) => {
	var errorMessages = this.state.errorMessages;
	errorMessages.push(errorMessage)
	this.setState({errorMessages: errorMessages});
}

handleActivateTest = (e) => {
	var confirm_flag = confirm('Are you sure?')
	if(!confirm_flag){  
		e.preventDefault();
		return;
	}
	const targetComponents = e.target.name.split('_');
	const scheduledTestId = targetComponents[0];
	const rowIndex = targetComponents[1];
	if(!scheduledTestId){
		var errorMessages = this.state.errorMessages;
		errorMessages.push("The operation failed trying to activate the scheduled test.")
		this.setState({errorMessages: errorMessages});
		e.preventDefault();
		return;
	}
	var checkedFlag = e.target.checked;

	$.ajax({
	    type: 'GET',
	    url: "/activate-test.json?scheduled_test_id="+scheduledTestId +"&activate_flag="+checkedFlag,
    }).done((data, status, jqXHR)=>{
    	var scheduledTestSearchResults = this.state.scheduledTestSearchResults;
    	scheduledTestSearchResults[rowIndex][9] = checkedFlag
    	this.setState({scheduledTestSearchResults});
    	//TODO show some better indicator that the test was activated 
    }).fail((jqXHR, textStatus, errorThrown) => {
    	var errorMessages = this.state.errorMessages;
    	errorMessages.push({message: "An unexpected error occured while trying to activate the scheduled test."})
        this.setState({errorMessages: errorMessages});
    })

}

refreshSchedules = () => {
	sessionStorage.removeItem('scheduleSearchResults');
	this.fetchInitialSchedules()
}

render () {
	const {scheduledTestInfo, userGroupSelectList,userGroupAdvSearchResults,isAdvancedSearchMode} = this.state;
	const {classes} = this.props;
	const titleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "20px",
		paddingLeft: "20px",
	};
	const subtitleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "18px",
		fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
		paddingBottom: "10px"
	};
	const tableTitleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "18px",
		fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
		padding: "0px", margin: "0px",
		paddingTop: "10px"
	};
	const schedulesTableOptions = {
		filter: false,
		selectableRows: 'none',
		search: false,
		print: false,
		download: false,
		viewColumns: false,
		responsive: 'stacked',
		expandableRows: true,
		renderExpandableRow: (rowData, rowMeta) => {
	        // const colSpan = rowData.length + 1;
	        var allPaperData = []
	        if(rowData[8]){
	        	var schPaperElements = rowData[8].split('|');
	        	if(schPaperElements){
	        		var numberOfPapers = schPaperElements[0].split(',').length;
	        		for(var i=0; i < numberOfPapers; i++){
	        			var paperData = [];
	        			for(var j in schPaperElements){
	        				var elements = schPaperElements[j].split(',');
	        				paperData.push(elements[i]);	
	        			}
	        			allPaperData.push(paperData);
	        		}
	        	}
	        }
	       	return(
	       		<ExpandableRowSchTestPaper {...this.props}
	       		schTestPapersData={allPaperData} 
	       		schTestInfoData={rowData}
	       		storeStateCallback={this.storeStateCallback.bind(this)}
	       		errorMessageCallback={this.errorMessageCallback}
	       		refreshSchedules={this.refreshSchedules}/>
	       	);
	    },
		setRowProps: (row) => {
	        return {
	          style: {textAlign: 'center'}
	        };
	      }
	}
	const schedulesTableColumns = [
		{name: "scheduledTestId",
	        options: {
	          display: false,
	        }
	    }, 
		{name: "Name"},
		{name: "Identifier"},
		{name: "Status"},
		{
			name: "Program",
			options: {
			   filter: true,
			   sort: false,
			}
		},
		{	name: "Test Type",
			options: {
			   filter: true,
			   sort: false,
			}
		},
		{name: "User Group"},
		//TODO add description as needed in future
		{name: "Description",
			options: {
	          display: false,
	        }
	    },
		{name: "paper_info",
	        options: {
	          display: false,
	        }
	    },
	    {name: "Active Test",
	        options: {
	         	customBodyRender: (value, tableMeta, updateValue) => {
				    return (
				    <div>
				    { tableMeta && tableMeta.rowData[3] == 'ENDED' &&
				      <Checkbox
				      	style= {{border: "1px solid #c2c4c6", padding: "3px"}}
				      	name={ tableMeta && tableMeta.rowData[0] ? tableMeta.rowData[0]+'_'+tableMeta.rowIndex : ""}
                        tabIndex={-1}
                        checked={value}
                        onClick={this.handleActivateTest}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{ checked: classes.checked }}
                      />
                  	}
			        </div>
				    );
		  		}
	        }
	    },   
	]
	const userGroupAdvSearchForm =  (
		<React.Fragment>
	      	<GridContainer style={{
	      	backgroundColor: "white",
			paddingTop: "0px",
	      	width: "100%", 
	      	margin: "10px 0px 0px",
	      	marginTop: "0px"}}
	      	spacing={24} >	
			<GridItem  xs={12} sm={12} md={6}>
  			<InputLabel shrink htmlFor="material12">
      			User Group Identifier
    		</InputLabel>
  			<CustomInput
              //labelText="Enter Test Model Name"
              id="material12"
              formControlProps={{
                  fullWidth: true,
                  style: {paddingTop: "5px"}
              }}
              inputProps={{
                  style: {fontSize: "16px"},
                  endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
                  placeholder: "Enter Name",
                  value: scheduledTestInfo["userGroupIdentifier"],
                  onChange: this.handleOnChangeScheduleTestInfo,
                  name: "scheduleTestInfo_userGroupIdentifier"
              }}
          	/>
          	<InputLabel shrink htmlFor="material13">
      			User Group Name
    		</InputLabel>
          	<CustomInput
              //labelText="Enter Test Model Identifier"
              id="material13"
              formControlProps={{
                  fullWidth: true,
                  style: {paddingTop: "5px"}
              }}
              inputProps={{
                  style: {fontSize: "16px"},
                  endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
                  placeholder: "Enter Unique Key",
                  value: scheduledTestInfo["userGroupName"],
                  onChange: this.handleOnChangeScheduleTestInfo,
                  name: "scheduleTestInfo_userGroupName"
              }}
          	/>
          	</GridItem>
          	</GridContainer>
        </React.Fragment>		
    );
    return (	
    <React.Fragment>
  		<GridContainer style={{
      		backgroundColor: "white",
			paddingTop: "0px",
      		paddingBottom: "20px",
      		width: "100%", 
      		margin: "10px 0px 0px",
      		marginTop: "0px"}}
      		spacing={24}>
      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
      			<h1 style={titleStyle} >Manage Schedules</h1>
      			<hr/>
      		</GridItem>
    		{this.state.errorMessages && this.state.errorMessages.length > 0 &&
	    	<React.Fragment>
	    		<GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
	      		</GridItem>
	    		<GridItem xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
	    		{this.state.errorMessages && this.state.errorMessages.length > 0 ? this.state.errorMessages.map((errorMessage, index) => 
	                <div key={index}>
	                <ErrorLogo className={classes.icon} style={{verticalAlign: "middle", marginRight: "5px", color: "#f44336"}}></ErrorLogo>
	                <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit"}} className={classes.robotoFontRegular}>{errorMessage["message"]}</p> 
	                </div>
	                ): null
	              }
	            </GridItem>
	        </React.Fragment>
        	}
	      	<React.Fragment>
	      		<GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
      			</GridItem>
	      		<GridItem  xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
	      			<div >
	      				<h3 style={subtitleStyle} >Search Schedules</h3>
	      			</div>
      			</GridItem>
	      		<GridItem  style={{backgroundColor: "#ffffff"}}  xs={12} sm={12} md={2}>
      			</GridItem>
	      		<GridItem style={{backgroundColor: "#ffffff", paddingTop: "8px", paddingBottom: "8px"}}  xs={12} sm={12} md={7}>
	      		<div>
	      			<MuiThemeProvider theme={getMuiThemeForSearchBar}>
	      			<CustomSearchBar inputPlaceholder={"Search Schedule Name"}
	      			// child2={<Button style={{textTransform: "none", 
	      			// 					backgroundColor: "#3f51b5",
	      			// 				    padding: "8px 20px",
	      			// 					margin: "0px"}}>
                    //                 	Schedule Test</Button>}
	      			child1={<Button onClick={this.changeSearchMode} style={{textTransform: "none", 
	      							backgroundColor: "#3f51b5",
	      							padding: "8px 20px",margin: "0px"}}>
                          		 	{isAdvancedSearchMode? "Basic Search" : "Advanced Search"} </Button>}
                    handleSearch={this.handleScheduledTestSearch}
                    hintText={"Please enter minimum 4 characters"}
	      			/>
	      			</MuiThemeProvider>
	      		</div>
	      		</GridItem>
	      		<GridItem style={{backgroundColor: "#ffffff"}}  xs={12} sm={12} md={3}>
      			</GridItem>
      		{isAdvancedSearchMode &&
      			<React.Fragment>
      			<GridItem  xs={12} sm={12} md={12}>
      			</GridItem>
      			<GridItem  xs={12} sm={12} md={2}>
      			</GridItem>
      		<GridItem  xs={12} sm={12} md={3} style={{paddingLeft: "24px", paddingRight: "24px"}}>
      			<InputLabel shrink htmlFor="material">
          			Schedule Name
        		</InputLabel>
      			<CustomInput
                  //labelText="Enter Test Model Name"
                  id="material"
                  formControlProps={{
                      fullWidth: true,
                      style: {paddingTop: "5px"}
                  }}
                  inputProps={{
                      style: {fontSize: "16px"},
                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
                      placeholder: "Enter Name",
                      value: scheduledTestInfo["name"],
                      onChange: this.handleOnChangeScheduleTestInfo,
                      name: "scheduleTestInfo_name"
                  }}
              	/>
              	<InputLabel shrink htmlFor="material2">
          			Schedule Number
        		</InputLabel>
              	<CustomInput
                  //labelText="Enter Test Model Identifier"
                  id="material2"
                  formControlProps={{
                      fullWidth: true,
                      style: {paddingTop: "5px"}
                  }}
                  inputProps={{
                      style: {fontSize: "16px"},
                      endAdornment: (<InputAdornment position="end"><ShortText/></InputAdornment>),
                      placeholder: "Enter Unique Key",
                      value: scheduledTestInfo["number"],
                      onChange: this.handleOnChangeScheduleTestInfo,
                      name: "scheduleTestInfo_number"
                  }}
              	/>
              	<InputLabel shrink htmlFor="material5">
          			Scheduled From
        		</InputLabel>
	            <CustomInput
		            //labelText="Enter Section"
		            id="material5"
		            formControlProps={{
		                fullWidth: true,
                      style: {paddingTop: "5px"}
		            }}
		            inputProps={{
		                style: {fontSize: "16px"},
		                type: "date",
		                value: scheduledTestInfo["fromDate"],
		                onChange: this.handleOnChangeScheduleTestInfo,
		                name: "scheduleTestInfo_fromDate"
		            }}
		        />
              	<InputLabel shrink htmlFor="material4">
          			Scheduled Till
        		</InputLabel>
	            <CustomInput
		            //labelText="Enter Section"
		            id="material4"
		            formControlProps={{
		                fullWidth: true,
                      style: {paddingTop: "5px"}
		            }}
		            inputProps={{
		                style: {fontSize: "16px"},
		                type: "date",
		                value: scheduledTestInfo["toDate"],
		                onChange: this.handleOnChangeScheduleTestInfo,
		                name: "scheduleTestInfo_toDate"
		            }}
		        />     
              	
      		</GridItem>
      		<GridItem  xs={12} sm={12} md={1}>
      		</GridItem>
      		<GridItem  xs={12} sm={12} md={3}>
      			<div>
      			<InputLabel shrink htmlFor="material3">
          			Status
        		</InputLabel>
        		<div style={{paddingTop: "5px"}}>
      			<ScheduleStatus 
			      	name="scheduleTestInfo_status"
			      	formControlWidth="75%"
	                value={scheduledTestInfo["status"]}
	                change={this.handleOnChangeScheduleTestInfo}
	              />
	            </div>
	            <div style={{padding: "10px"}}/>
      			<InputLabel shrink htmlFor="material2">
          			Program
        		</InputLabel>
      			<div style={{paddingTop: "5px"}}>
      			<Programs
			      	name="scheduleTestInfo_program"
			      	formControlWidth="75%"
	                value={scheduledTestInfo["program"]}
	                change={this.handleOnChangeLOVScheduleTestInfo.bind(this)}
	              />
	            </div> 
      			<div style={{padding: "10px"}}/>
              	<InputLabel shrink htmlFor="material2">
          			Test Type
        		</InputLabel>
        		<div style={{paddingTop: "5px"}}>
              	<TestTypes
			      	name="scheduleTestInfo_testType"
			      	formControlWidth="75%"
	                value={scheduledTestInfo["testType"]}
	                change={this.handleOnChangeLOVScheduleTestInfo.bind(this)}
	              />
	              </div>
	              <div style={{padding: "10px"}}/>
              	<InputLabel shrink htmlFor="material2">
          			User Group
        		</InputLabel>
      			<div style={{paddingTop: "5px"}}>
      				<AutoSuggestSelect
	      				options={userGroupSelectList}
	      				value={{value: scheduledTestInfo["userGroupId"], label: scheduledTestInfo["userGroupIdentifier"]}}
	      				formControlWidth="75%"
	      				onValueChange={this.handleUserGroupChange}
	      				name="scheduleTestInfo_userGroupId"
	      				advSearchExecuteCallback={this.handleUserGroupAdvSearch}
	      				advSearchFormComponent={userGroupAdvSearchForm}
	      				advSearchResults={userGroupAdvSearchResults}
      				/>
	            </div>
	            <Button onClick={this.handleScheduledTestAdvSearch} style={{textTransform: "none", 
		  							backgroundColor: "#3f51b5",
		  							padding: "8px 20px",margin: "5px",marginTop: "30px"}}>
		                  		 	Advanced Search</Button> 
		        <Button onClick={this.handleScheduledTestAdvSearchReset} style={{textTransform: "none", 
		  							backgroundColor: "#3f51b5",
		  							padding: "8px 20px",margin: "5px",marginTop: "30px"}}>
		                  		 	Reset</Button> 
		        </div>	
      		</GridItem>
      		<GridItem  xs={12} sm={12} md={3}>
      		</GridItem>
      		</React.Fragment>
      		}
      			<GridItem  xs={12} sm={12} md={12} style={{paddingRight: "24px", paddingLeft: "24px"}}>
      			<MuiThemeProvider theme={getMuiThemeForTable}>
      				<MUIDataTable 
      			  	title={<p style={tableTitleStyle} >Schedules</p>}
      			  	options={schedulesTableOptions}
      			  	columns={schedulesTableColumns}  
      			  	data={this.state.scheduledTestSearchResults}              
      				/>
      			</MuiThemeProvider>
      			</GridItem>
      		</React.Fragment>
		</GridContainer>
		</React.Fragment>
    );
  }
}

export default  withStyles(testFormStyle)(ManageSchedules);
