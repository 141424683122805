import React from "react";

/*import Button from "CustomButtons/Button.jsx";
import Card from "Card/Card.jsx";
import CardBody from "Card/CardBody.jsx";*/

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

// import QuestionImages from "components/test/QuestionImages.jsx";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";
/*import test_img1 from "assets/img/image1.png";*/


 export default  class SingleAnswerOptions extends React.Component {

	constructor(props) {
	    super(props);
	  }

	
render() {
	 const { options,answer, answerChanged,showSolutions, AnswerKey, isDisabled, isOptionsJumbled} = this.props;
	 const keyStyle = {
	 	backgroundColor: "#00800033",
	 	paddingTop: "5px"
	 }
	 const style4={
		fontFamily: "serif",
		fontSize: "1rem",
		backgroundColor: "#ffc1073d",
		padding: "5px",
		width: "fit-content"
		};
	return (
		<GridContainer style={{margin: "auto"}}>
			<GridItem xs={12} sm={12} md={12} lg={12}>
				{ isDisabled && <p style={style4}>Maximum Questions Attempted in this Section</p>}
				<RadioGroup
		            aria-label="Gender"
		            name="option"
		            value={answer}
		            onChange={answerChanged}

		            style={{backgroundColor: "transparent"}}
		          >
				{options && options.map((item, index) => (
					<FormControlLabel 
					key={index} 
					value={item.label} 
					style ={ showSolutions && AnswerKey == item.label ? keyStyle : {} }
					disabled={showSolutions || isDisabled } 
					control={<Radio />} 
					label={
						<React.Fragment>
						{item.content && item.content.map((itemContent, index3) => (
			 		     	<React.Fragment key={index3}>
			 		     	{ index3%2 == 0 && 
								<QuestionText text={index3 == 0 ? (isOptionsJumbled ?  String.fromCharCode(65 + index) +") " +itemContent : item.label +") " +itemContent) : itemContent }/>
							}
							{index3%2 == 1 && itemContent &&
							 	<QuestionImages 
								src={itemContent} 
								name={"option_"+item.label+'_'+index3}
								isEditMode={false}/> 
							}
							</React.Fragment>
						))}
						</React.Fragment>
					} 
					/>
				))}
				</RadioGroup>
			</GridItem> 
		</GridContainer>
	);
}

}
