import { title } from "assets/jss/material-kit-react.jsx";


const TextStyle = {
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    // marginTop: "auto",//30px
    minHeight: "32px",
    color: "#555",
    textDecoration: "none",
    width: "100%",
    //textAlign: "center",
    fontSize: "1.5rem"
  },
  subtitle: {
    fontWeight: "300",
    fontSize: "1.2rem",
    fontFamily: "'Noto Serif', serif"
  },
   robotoFontBold:{
    //fontFamily: "'Roboto', serif",
    //fontWeight: "400",
    fontSize: "1.6rem",
    fontFamily: "'Noto Serif', serif",
    fontWeight: "700",
    whiteSpace: "nowrap", 
    overflow: "hidden", 
    textOverflow: "ellipsis"
  },
   robotoFontRegular:{
    //fontFamily: "'Roboto', sans-serif;",
    fontWeight: "100",
    fontSize: "1rem",
    fontFamily: "'Noto Serif', serif",
    whiteSpace: "nowrap", 
    overflow: "hidden", 
    textOverflow: "ellipsis"
  },
  smallRobotoFontRegular:{
    //fontFamily: "'Roboto', sans-serif;",
    fontWeight: "100",
    fontSize: "0.9rem",
    fontFamily: "'Noto Serif', serif",
    whiteSpace: "nowrap", 
    overflow: "hidden", 
    textOverflow: "ellipsis"

  },
  robotoFontRegularLabel:{
    //fontFamily: "'Roboto', sans-serif;",
    fontWeight: "100",
    fontSize: "0.9rem",
    fontFamily: "'Roboto', serif",
    minWidth: "100px"
  },
  lineBreak:{
    backgroundColor: "#203642",
    margin: "20px"
  }
};

export default TextStyle;
