import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";


export default class QuestionText extends React.Component {

	render() {
		const { text } = this.props;
		const style3={
		fontFamily: "serif",
		fontSize: "1rem",
		color: "rgb(60, 72, 88)",
		fontWeight: "300",
		lineHeight: "1.55em",
		//marginLeft: "15px",
		//marginRight: "15px",
		display: "inline",
		userSelect: "none",
		marginLeft: "0px"//"0.25rem"
		};
		return(
			<h5 style={style3}>{text}</h5>
			// {<div style={{display: "inline-block"}}>
			// <GridContainer style={{margin: "auto"}}>
			// 	<GridItem xs={12} sm={12} md={12} lg={12} >
			// 		<h5 style={style3}>{text}</h5>
			// 	</GridItem> 
			// </GridContainer>	
			// </div>}
		);
	}

}