import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import ErrorBoundary from "components/ErrorBoundary.js";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import InputLabel from '@material-ui/core/InputLabel';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import InteractiveList from 'components/misc/InteractiveList';
import ErrorLogo from "@material-ui/icons/Error";
import MarksAndRanksList from "components/analysis/tables/MarksAndRanksList"
import QuestionsErrorSummary from "components/analysis/tables/QuestionsErrorSummary"
import UnattemptedStudentsList from "components/analysis/tables/UnattemptedStudentsList"
import qs from 'query-string';
import Button from "CustomButtons/Button.jsx";
import {formatDate} from "components/helpers/DateTimeHelper.jsx";
import $ from 'jquery';

const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MUIDataTableToolbarSelect:{
        title:{
          display: "none"
        },
        root:{
          backgroundColor: "none",
          boxShadow: "none",
          justifyContent: "normal"
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader:{
          zIndex: "none"
        }
      },
    typography: {
      useNextVariants: true,
    },
    },
    typography: {
      useNextVariants: true,
    }
  })


class TestAnalysisDashboard extends React.Component {

  state = {
    errorMessages: [],
    testResults:[],
    scheduledTestId: "",
    scheduledTestPaperId: "",
    fullResultsData: "",
    selectedReport: 0,
    scheduledTestData: "",
    viewport: ""
  }

  componentDidMount(){
      const scheduled_test_id = qs.parse(this.props.location.search).scheduled_test_id; 
      const scheduled_test_paper_id = qs.parse(this.props.location.search).scheduled_test_paper_id;
      const viewport = qs.parse(this.props.location.search).viewport; 
      if (viewport && viewport == "embedded"){
        this.setState({viewport})
      } 
    if(scheduled_test_paper_id){ //Process of duplicate only if test_id exists
       $.ajax({
          type: 'GET',
          url: getBaseURL() + '/react/test-paper-analysis.json?scheduled_test_paper_id=' + scheduled_test_paper_id,
        }).done((data, status, jqXHR)=>{
          this.setState({scheduledTestData: data["scheduled_test_info"],
            fullResultsData: data,
            scheduledTestPaperId: scheduled_test_paper_id});
        
        }).fail((jqXHR, textStatus, errorThrown) => {
            var errorMessages = this.state.errorMessages;
            errorMessages.push({message: jqXHR["responseJSON"].message})
            this.setState({errorMessages: jqXHR["responseJSON"].message});
        })
    }else if(scheduled_test_id){
      $.ajax({
          type: 'GET',
          url: getBaseURL() + '/react/total-test-analysis.json?scheduled_test_id=' + scheduled_test_id,
        }).done((data, status, jqXHR)=>{
          this.setState({scheduledTestData: data["scheduled_test_info"],
            fullResultsData: data,
            scheduledTestId: scheduled_test_id});
        
        }).fail((jqXHR, textStatus, errorThrown) => {
            var errorMessages = this.state.errorMessages;
            errorMessages.push({message: jqXHR["responseJSON"].message})
            this.setState({errorMessages: jqXHR["responseJSON"].message});
        })

    }
  }

  selectedReportChanged = (e) => {
    e.preventDefault();
    const newSelectedReport = parseInt(e.currentTarget.id);
    this.setState({selectedReport: newSelectedReport});
  }


  goToUserTestAnalysis = (e) => {
    e.preventDefault();
    var user_id = e.currentTarget.name;
    var scheduledTestData = this.state.scheduledTestData;
    var scheduledTestPaperId = this.state.scheduledTestPaperId;
    if(user_id && scheduledTestData["scheduled_test_id"]){
      if(scheduledTestPaperId){
        this.props.history.push('/student-test-dashboard?user_id='+user_id+'&test_id='+scheduledTestData["scheduled_test_id"]+'&test_paper_id='+scheduledTestPaperId);
      }else{
        this.props.history.push('/student-test-dashboard?user_id='+user_id+'&test_id='+scheduledTestData["scheduled_test_id"]);
      }
    } else{
      var errorMessages = this.state.errorMessages;
      this.setState({errorMessages: errorMessages});
    }
  }

  handleDoneAction = (e) => {
    e.preventDefault();
    if(this.state.viewport == "embedded"){
      this.props.history.goBack()
    }else{
      this.props.history.push('/schedule-tests');
    }
  }

  render () {
    const{classes} = this.props;
    const{scheduledTestData,scheduledTestId, scheduledTestPaperId,fullResultsData,selectedReport} = this.state;
    const titleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "20px",
      paddingLeft: "20px",
    };
    const subtitleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "10px"
    };    
    const buttonStyle = {
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "capitalize",
    background: "#3f51b5",
      lineHeight: "20px",
      borderRadius: "3px",
      marginBottom: "auto"
  };
    return (
      <React.Fragment>
        <ErrorBoundary>
          <GridContainer style={{
          backgroundColor: "white",
          paddingTop: "0px",
          paddingBottom: "20px",
          width: "100%", 
          margin: "10px 0px 0px",
          marginTop: "0px"}}
          spacing={24}>
          <GridItem xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            <h1 style={titleStyle} >Test Analysis Dashboard</h1>
            
          </GridItem>
          <GridItem xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px", margin: "auto"}}>
            <Button 
              style={buttonStyle}
              onClick={this.handleDoneAction}>Done
            </Button> 
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            <hr style={{marginTop: "10px", marginBottom: "10px"}}/>
          </GridItem>
        {this.state.errorMessages && this.state.errorMessages.length > 0 &&
        <React.Fragment>
          <GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            </GridItem>
          <GridItem xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
          {this.state.errorMessages && this.state.errorMessages.length > 0 ? this.state.errorMessages.map((errorMessage, index) => 
                  <div key={index}>
                  <ErrorLogo style={{verticalAlign: "middle", marginRight: "5px", color: "#f44336"}}></ErrorLogo>
                  <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit"}} /*TODO className={classes.robotoFontRegular}*/>{errorMessage["message"]}</p> 
                  </div>
                  ): null
                }
              </GridItem>
          </React.Fragment>
          }
          <React.Fragment>
              <GridItem  xs={12} sm={12} md={1}  style={{paddingTop: "0px", paddingBottom: "0px"}}>
              </GridItem>
              <GridItem  xs={12} sm={12} md={11}  style={{paddingTop: "0px", paddingBottom: "0px"}}>
                <h3 style={subtitleStyle} >Test Summary</h3>
              </GridItem>
              <GridItem  xs={12} sm={12} md={2}>
              </GridItem>
              <GridItem  xs={12} sm={12} md={3}>
              <InputLabel shrink htmlFor="material">
                    Schedule Test Name
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{scheduledTestData["scheduled_test_name"]}</p>
                <InputLabel shrink htmlFor="material">
                    Schedule Test Number
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{scheduledTestData["scheduled_test_identifier"]}</p>
              </GridItem>
              <GridItem  xs={12} sm={12} md={3}>
                <InputLabel shrink htmlFor="material">
                    User Group
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{scheduledTestData["user_group_indentifier"]}</p>
                <InputLabel shrink htmlFor="material">
                    Scheduled At
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{formatDate(scheduledTestData["schedule_start_time"])}</p>
              </GridItem>
              <GridItem  xs={12} sm={12} md={3}>
                <InputLabel shrink htmlFor="material">
                    Test Program
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{scheduledTestData["program_short_name"]}</p>
                <InputLabel shrink htmlFor="material">
                    Test Type
                </InputLabel>
                <p style={{fontSize: "16px", color: "rgb(34, 56, 69)"}} >{scheduledTestData["test_type_short_name"]}</p>
              </GridItem>
              <GridItem  xs={12} sm={12} md={12} style={{paddingRight: "24px", paddingLeft: "24px"}}>
              <InteractiveList selectedListIndex={selectedReport} onSelectedListChange={this.selectedReportChanged}/>
              </GridItem>
              {(scheduledTestPaperId || scheduledTestId)&& fullResultsData &&  selectedReport == 0 &&
                <GridItem  xs={12} sm={12} md={12} style={{paddingRight: "24px", paddingLeft: "24px"}}>
                  <MarksAndRanksList reportName={scheduledTestData["scheduled_test_name"]} goToUserTestAnalysis={this.goToUserTestAnalysis} data={fullResultsData} paperId={scheduledTestPaperId}/>
                </GridItem>
              }
              {(scheduledTestPaperId || scheduledTestId) && fullResultsData && selectedReport == 1 &&
                <GridItem  xs={12} sm={12} md={12} style={{paddingRight: "24px", paddingLeft: "24px"}}>
                  <QuestionsErrorSummary reportName={scheduledTestData["scheduled_test_name"]} goToUserTestAnalysis={this.goToUserTestAnalysis} data={fullResultsData} paperId={scheduledTestPaperId}/>
                </GridItem>
              }
              {scheduledTestPaperId && selectedReport == 2 &&
                <GridItem  xs={12} sm={12} md={12} style={{paddingRight: "24px", paddingLeft: "24px"}}>
                <UnattemptedStudentsList reportName={scheduledTestData["scheduled_test_name"]} paperId={scheduledTestPaperId}/>
                </GridItem>
              }
              { (!scheduledTestPaperId) && scheduledTestId && selectedReport == 2 &&
                <p style={{margin: "auto", marginTop: "20px" ,width:"fit-content", fontWeight: "500",fontSize: "16px", color: "rgb(34, 56, 69)"}} >Report only available for Test Paper</p>
              }
            </React.Fragment>
            </GridContainer>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default TestAnalysisDashboard;
