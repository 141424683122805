/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import CustomDropdown from "CustomDropdown/CustomDropdown.jsx";
import Button from "CustomButtons/Button.jsx";

import headerLinksStyle from "./HeaderLinksStyle.jsx";

class HeaderLinks extends React.Component{



  render(){
  const { classes, scrollToSection } = this.props;
  return (
    <List className={classes.list}>
    <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          onClick={scrollToSection}
          className={classes.navLink}
          name="solution"
        >
           Solution
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          onClick={scrollToSection}
          className={classes.navLink}
          name="features"
        >
           Features
        </Button>
      </ListItem>
     {/* <ListItem className={classes.listItem}>
        <Button
         color="transparent"
          onClick={scrollToSection}
          className={classes.navLink}
          name="howitworks"
        >
          How it works
        </Button>
      </ListItem>*/}
    <ListItem className={classes.listItem}>
        <Button
         color="transparent"
          onClick={scrollToSection}
          className={classes.navLink}
          name="contactus"
        >
           Contact Us
        </Button>
      </ListItem>
       <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          onClick={scrollToSection}
          className={classes.navLink}
          name="aboutus"
        >
           About Us
        </Button>
      </ListItem>
    </List>
  );
}
}

export default withStyles(headerLinksStyle)(HeaderLinks);
