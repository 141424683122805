import React from "react";
import TestRegion from "components/TestRegion.jsx"; 
import TestInstructions from "components/test/TestInstructions.jsx"; 




class TestMain extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      testFlow: ""
	    };
	  }

	componentDidMount() {
		this.routeTestFlow();
    
  	}

  	routeTestFlow(){
  		if(this.props.location.pathname == "/home/test/instructions")
		{
			this.setState({testFlow: "TestInstructions"})
		} else if(this.props.location.pathname == "/home/test"){
			this.setState({testFlow: "TestRegion"})
		} else if(this.props.location.pathname == "/home/test/solutions"){
			this.setState({testFlow: "TestSolutions"})
		}
  	}

  	componentDidUpdate(prevProps){
    	if(this.props.location.pathname !== prevProps.location.pathname)
  		{
	  		this.routeTestFlow();
  		}
  	}

	render() {
		const {testFlow} = this.state;
		return (
			<div>
			{ testFlow && testFlow=="TestInstructions" ? <TestInstructions {...this.props} /> : ''}
			{ testFlow && testFlow=="TestRegion" ? <TestRegion {...this.props}/> : ''}
			{ testFlow && testFlow=="TestSolutions" ? <TestRegion showSolutions {...this.props}/> : ''}
			</div>
			);
	}

}

export default TestMain;
