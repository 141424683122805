import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';


class MetaReadCreateProjectDialog extends React.Component {

	state = {
		name: "",
		type: "QP"
	}

	handleClose = () => {
    	this.props.onClose();
  	};

  	handleInput = (e) => {
	    e.preventDefault();
	    var id = e.target.id;
	    var obj ={};
	    obj[id] = e.target.value;
	    this.setState(obj);
	}
 	
 	createProject = (e) =>{
 		var formData = new FormData();
 		formData.append('name', this.state.name);
 		formData.append('project_type', this.state.type);
 		$.ajax({
          type: 'POST',
          url: "/metaread_projects",
          data: formData,
          cache: false,
          contentType: false,
          processData: false
        }).done((data, status, jqXHR)=>{
        	this.props.onSuccess()
        }).fail((jqXHR, textStatus, errorThrown) => {
        	var errorMessages = jqXHR["responseJSON"]
        	if( errorMessages && errorMessages["name"] && errorMessages["name"].length>0){
        		this.props.onFailure(errorMessages["name"][0])
        	}else{
        		this.props.onFailure("Couldn't create Project")
        	}
        	//Something went wrong
        })
 	}

	render(){
		const { onClose, open, onSuccess,onFailure } = this.props;
		return(
			<React.Fragment>
				<Dialog onClose={this.handleClose} open={open}>
			      <DialogTitle >Create Project</DialogTitle>
			      <DialogContent>
			      		<TextField
			      		    variant="outlined"
			      		    margin="normal"
			      		    required
			      		    fullWidth
			      		    id="name"
			      		    label="Project Name"
			      		    name="name"
			      		    autoFocus
			      		    value={this.state.name}
			      		    onChange={this.handleInput}
			      		/>
			      		<div style={{marginTop: "10px"}}>
				      	<FormControl fullWidth>
					        <InputLabel id="type">Type</InputLabel>
					        <Select id="type" value={this.state.type}  name="type"  
					        onChange={ 
					          (event) => {this.setState({type: event.target.value})}
					        }
					          >
					            <MenuItem value="QP">Question Paper</MenuItem>
    				            <MenuItem value="SINGLE">Questions (Image) </MenuItem>
					            <MenuItem value="DOUBTS">Doubt (Hand written)</MenuItem>
					            <MenuItem value="BOOK">Book</MenuItem>
					            <MenuItem value="FREESTYLE">Freestyle</MenuItem>
					        </Select>
				      	</FormControl>
				      	</div>
			      </DialogContent>
			      <DialogActions>
	                <Button onClick={this.handleClose} color="primary">
	                  Cancel
	                </Button>
	                <Button onClick={this.createProject} color="primary">
	                  Create
	                </Button>
	              </DialogActions>
			    </Dialog>	
			</React.Fragment>
		);
	}

}

export default MetaReadCreateProjectDialog;