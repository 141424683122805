import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachFile from '@material-ui/icons/AttachFile';
import Clear from '@material-ui/icons/Clear';
import Add from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';

export default class QuestionTextInput extends React.Component {


	render() {
		const { text, name, handleEditTestData,handleAddTextComponent,
			handleRemoveTextComponent,handlePasteEvent, rows, 
			isSingleComponent, label,noImageAttachment,handleImageFileUpload} = this.props;
		return(
			<div>
			<GridContainer style={{margin: "auto"}}>
				<GridItem xs={12} sm={12} md={12} lg={12} >
					<TextField
			        id="filled-full-width"
			        label={label ? label : ""}
			        style={{ margin: 8 }}
			        placeholder="Enter Question Content"
			        //helperText="Full width!"
			        multiline
			        rows={rows}
			        fullWidth
			        name={name}
			        onChange={handleEditTestData}
			        onPaste={handlePasteEvent}
			        value={text}
			        margin="normal"
			        variant="outlined"
			        InputLabelProps={{
			          shrink: true,
			        }}
			        InputProps={{
			        	style: {lineHeight: "1.55em"},
	                  endAdornment: (
	                    <InputAdornment position="end">
	                     {!noImageAttachment &&
	                     	<React.Fragment>
	                     	<input
		                      //className={classes.input}
		                      style={{ display: 'none' }}
		                      name={name}
		                      id={name}
		                      multiple
		                      type="file"
		                      onChange={handleImageFileUpload}
		                    />
		                  <label htmlFor={name}>
	                      <IconButton
	                      	component="span"
	                      	style={{color: "rgb(53, 101, 180)",
	                       //	borderLeft: "1px solid rgba(0, 0, 0, 0.13)",
	                   		borderRadius: "0%",
	                   		paddingTop: "6px",
	                   		paddingBottom: "6px"}}
	                        edge="end"
	                        //onClick={}
	                      >
	                        {<AttachFile />}
	                      </IconButton>
	                      </label> 
	                      </React.Fragment>
	                  	}
	                      {!isSingleComponent && 
	                      <React.Fragment> 
	                      { name && name.split('_').length > 0 && name.split('_')[1] > 0 &&
		                      <IconButton
		                      	name={name+"_remove"}
		                      	style={{color: "rgb(53, 101, 180)",
		                      	borderRadius: "0%",
		                      	paddingTop: "6px",
		                   		paddingBottom: "6px"}}
		                        edge="end"
		                        onClick={handleRemoveTextComponent}
		                      >
		                        {<Clear />}
		                      </IconButton>
	                      }
	                      <hr/>
	                       <IconButton
	                       name={name+"_add"}
	                       style={{color: "rgb(53, 101, 180)",
	                       	borderLeft: "1px solid rgba(0, 0, 0, 0.13)",
	                   		borderRadius: "0%",
	                   		paddingTop: "6px",
	                   		paddingBottom: "6px"}}
	                        edge="end"
	                        onClick={handleAddTextComponent}
	                      >
	                        {<Add />}
	                      </IconButton>
	                      </React.Fragment>
	                  	 }
	                    </InputAdornment>
	                  ),
	                }}
			      />
				</GridItem> 
			</GridContainer>	
			</div>
		);
	}

}