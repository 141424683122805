import React from "react";


export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log( "Error: " + error + " Info: " + info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div></div>;
    }

    return this.props.children; 
  }
}