import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

/*import test_img1 from "assets/img/image1.png";*/
import CircularProgress from '@material-ui/core/CircularProgress';
import QuestionText from "components/test/view/QuestionText.jsx";


const style = {
  ...imagesStyle
};

 class QuestionImages extends React.Component {
/*
	constructor(props) {
	    super(props);
	  }
*/

state = {
	clearIconVisible: false,
	clearIconWidth: "",
	inlineImg: false,
	imgSrc: "",
	renderImg: true,
	imageErrorHandled: false,
	imgUrlWidth: "",
	imgUrlHeight: ""
}

componentDidMount(){
	var src = this.props.src;
	if(src.includes("width") && src.includes("height")){
		const params = new URL(getBaseURL()+src).searchParams;
		this.setState({imgUrlWidth: params.get('width'), imgUrlHeight: params.get('height')})
	}
	var inlineImg = src.includes("inline"); 
	if(inlineImg){
		this.setState({inlineImg})
	}
}

componentDidUpdate(prevProps){
	if(this.props.src != prevProps.src){
		var src = this.props.src;
		if(src.includes("width") && src.includes("height")){
			const params = new URL(getBaseURL()+src).searchParams;
			this.setState({imgUrlWidth: params.get('width'), imgUrlHeight: params.get('height')})
		}else{
			this.setState({imgUrlWidth: "", imgUrlHeight: ""})
		}
		var inlineImg = src.includes("inline"); 
		if(inlineImg){
			this.setState({inlineImg})
		}
	}
}

handleImageLoaded = (e) => {
	this.setState({
		clearIconVisible: true,
		clearIconWidth: e.target.offsetWidth - 50
	})
}

handleImageError = (e) => {
	this.setState({renderImg: false,imageErrorHandled: true});
	setTimeout(this.reloadImg.bind(this), 5000, e);	
}

reloadImg = (e) =>{
	this.setState({renderImg: true, imgSrc: this.props.src});
}

render() {
	const { classes, src, name, label, content, imgWidth, maxWidth, backgroundColor,isEditMode, handleRemoveImageComponent } = this.props;
	const {inlineImg,imgSrc,renderImg,imageErrorHandled,imgUrlWidth,imgUrlHeight} = this.state;
	const imageClasses = classNames(
      //classes.imgRaised,
      //classes.imgRoundedCircle,
      classes.imgFluid,
      classes.imgCardTop
    );
    const blockImgStyle = { 	
    	padding: "20px", 
     	height: "auto", 
     	maxHeight: "500px", 
     	width: imgWidth, 
     	maxWidth: maxWidth, 
     	display: "block", 
     	backgroundColor: backgroundColor
    }
    const inlineImgStyle = { 	
    	padding: "0px", 
    	paddingLeft: "2px",
    	paddingRight: "2px",
     	height: imgUrlHeight ? imgUrlHeight.toString()+"px" : "auto", 
     	maxHeight: "500px", 
     	width: imgUrlWidth ? imgUrlWidth.toString()+"px" : imgWidth, 
     	maxWidth: maxWidth, 
     	display: "inline-block", 
     	backgroundColor: backgroundColor,
     	marginBottom : "5px"
    }
    const style3={
		fontFamily: "serif",
		fontSize: "1rem",
		color: "rgb(60, 72, 88)",
		fontWeight: "300",
		lineHeight: "1.55em",
		display: "inline-block",
		//paddingLeft: "30px"
		};
	return(
		<div style={ inlineImg 	? 	{position: "relative", display: "inline-block"}
								:   {position: "relative"}} >
		{ label ?
			<QuestionText text={content ? label +") " +content : label +") " }/>
			: null
		}
		{ renderImg ?
			<img
			  style={inlineImg ? inlineImgStyle : blockImgStyle}
			  className={imageClasses}
			  src={imgSrc? imgSrc: getBaseURL()+src}
			  alt="Invalid/Broken Image"
			  onError={imageErrorHandled ? "" :this.handleImageError}
			  onLoad={isEditMode ? this.handleImageLoaded: null}
			/>
			:  <CircularProgress style={{width: "25px", height: "25px", marginRight: "20px", float: "right"}}/>

		}
			{ this.state.clearIconVisible && isEditMode &&
			<IconButton
				name={name}
			    style={{backgroundColor: "#F44336",
		    	color: "white",
				borderRadius: "50%",
				padding: "3px",
				top: "30px",
				left: this.state.clearIconWidth,
				position: "absolute",
				boxShadow:"rgba(33, 33, 33, 0.16) 0px -5px 20px 0px, rgba(33, 33, 33, 0.16) 0px 5px 12px -5px"
				}}
				onClick={handleRemoveImageComponent}
		    	edge="end">
		     {<Clear style={{fontSize: "20px"}}/>}
		   </IconButton>
		   }
			<br />
		</div>
		);
}
}

QuestionImages.defaultProps = {
  imgWidth: "auto",
  maxWidth: "100%",
  backgroundColor: "white"
}

export default withStyles(style)(QuestionImages);