import React from "react";
import ReactDOM from "react-dom";
import MetaReadHeader from 'metaread/MetaReadHeader';
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography';
import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";
import SingleAnswerOptions from "components/test/view/SingleAnswerOptions.jsx";
import QuestionTextInput from "components/test/view/QuestionTextInput.jsx";
import Button from '@material-ui/core/Button';
import {formatDateShortYear,offsetISTTimezone} from "components/helpers/DateTimeHelper.js";
import qs from 'query-string';

class MetaReadContentStore extends React.Component {

	state = {
		projects: [],
		projectFragments: [],
		errorsReported: [],
		questionData: [],
		selectedProjectId: "",
		selectedProjectFragmentId: "",
		isEditMode: false,
		offsetHeightForData: 0,
		total_requests: "",
		selectProjectType: "",
		pageData: []
	}

	componentDidMount(){
	  this.getProjects();
	  this.loadProjectId();
	  if(window.MathJax)
	  window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
	}

	componentDidUpdate(){
	  if(window.MathJax)
	  window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
	}


	loadProjectId(){
		const selectedProjectId = qs.parse(this.props.location.search).projectId;
		if(selectedProjectId){
			this.setState({selectedProjectId})
			this.getProjectFragments(selectedProjectId);
		}
	}

	getProjects(){
	    $.ajax({
	      type: 'GET',
	      url: '/metaread_projects.json?type=all&limit=20',
	    }).done((data, status, jqXHR)=>{
	      this.setState({projects: data["projects"], total_requests: data["total_requests"]})
	    }).fail((jqXHR, textStatus, errorThrown) => {
	      var errorsReported = this.state.errorsReported;
	      errorsReported.push({message: "An unexpected error occured while fetching projects."})
	        this.setState({errorsReported: errorsReported});
	    })
	}

	selectProject = (e) => {
		var projectId = e.currentTarget.id;
		this.setState({selectedProjectId: projectId})
		this.getProjectFragments(projectId);
	}

	getProjectFragments(projectId){
		$.ajax({
	      type: 'GET',
	      url: '/metaread_project_contents.json?project_id='+projectId+'&limit=50',
	    }).done((data, status, jqXHR)=>{
	      this.setState({projectFragments: data["projectFragments"],selectProjectType: data["project_type"]})
	    }).fail((jqXHR, textStatus, errorThrown) => {
	      var errorsReported = this.state.errorsReported;
	      errorsReported.push({message: "An unexpected error occured while fetching project fragments."})
	        this.setState({errorsReported: errorsReported});
	    })
	}

	selectProjectFragment = (e) =>{
		var projectFragmentId = e.currentTarget.id;
		this.setState({selectedProjectFragmentId: projectFragmentId, offsetHeightForData: e.currentTarget.offsetTop})
		var projectFragments = this.state.projectFragments;

		var fragment = projectFragments.filter(fragment => fragment.id==projectFragmentId)
		if(fragment.length > 0){
			if(this.state.selectProjectType == "BOOK" || this.state.selectProjectType == "FREESTYLE"){
				this.setState({pageData: fragment[0]["content"], questionData: []});
			}else{
				this.setState({questionData: fragment[0]["content"], pageData: []});
			}
		}
	}

	handleEditTestData = (e) => {
	 var questionData = this.state.questionData;
	 var component = e.currentTarget.name;
	 if(!component){
	   component = e.target.name;
	 }
	 var compArray = component.split('_');
	 //TODO error handling
	 if(compArray[0] == "options"){
	   for(var optionIndex in questionData["options"]){
	     if(questionData["options"][optionIndex].label==compArray[1]){
	       questionData["options"][optionIndex].content[compArray[2]] = e.currentTarget.value;
	     }
	   }
	 }else{
	   questionData[compArray[0]][compArray[1]][compArray[2]] = e.currentTarget.value;
	 }
	 this.setState({questionData})
	}

	handleRemoveImageComponent = (e) =>{
	    var questionData = this.state.questionData;
	    var component = e.currentTarget.name;
	    var compArray = component.split('_');
	    //TODO error handling
	    if(compArray[0] == "options"){
	      for(var optionIndex in questionData["options"]){
	        if(questionData["options"][optionIndex].label==compArray[1]){
	          questionData["options"][optionIndex].content[compArray[2]] = "";
	        }
	      }
	    }else{
	      questionData[compArray[0]][compArray[1]][compArray[2]]="";
	    }
	    this.setState({questionData});
    }

    toggleEditMode = (e) =>{
    	if(this.state.isEditMode){
    		this.saveFragment()
    	}
    	this.setState({isEditMode: !this.state.isEditMode})
    }

    saveFragment(){
    	const projectFragmentId = this.state.selectedProjectFragmentId;
    	var formData = new FormData();
 		formData.append('content', JSON.stringify(this.state.questionData));
 		$.ajax({
          type: 'PATCH',
          url: "/metaread_project_contents?id="+projectFragmentId,
          data: formData,
          cache: false,
          contentType: false,
          processData: false
        }).done((data, status, jqXHR)=>{
        	//Show something in snackbar
        }).fail((jqXHR, textStatus, errorThrown) => {
        	//Something went wrong
        })
    }
    
    getPageCompVariant(pageComponent, label_order){
    	/*const label = pageComponent["label"]
    	if (label_order.length == 1){
    		return "body1"
    	}else if(label_order.length == 2){
    		const index = label_order.indexOf(label)
    		if(index > 0){
    			return "subtitle1"
    		}else{
    			return "body1"
    		}
    	}else if (label_order.length == 3){
    		const index = label_order.indexOf(label)
    		if(index > 1){
    			return "h4"
    		}else if(index > 0){	
    			return "subtitle1"
    		}else{
    			return "body1"
    		}
    	}*/
    	return "body1"
    }

    goToMetaReadConvertPage = (e) => {
  		this.props.history.push('/metaread/beta');
  	}

  	downloadProjectCsv =(e) =>{
  		window.location.href = '/metaread/csv?project_id='+this.state.selectedProjectId;
  	}

	render(){
		const {projects,projectFragments,questionData,isEditMode,total_requests,pageData} = this.state;
		var compVariant = ""
		return(
			<React.Fragment>
				<div style={{minHeight: "100vh", backgroundColor: "white"}}>
        			<MetaReadHeader actionButtonLabel={"Run MetaRead"} goToLink={this.goToMetaReadConvertPage}/>
        			<Grid container style={{padding: "32px"}}>
        				<Grid item md={2}>
        					<div style={{marginRight: "6px"}}>
        					<Typography style={{marginLeft: "16px", textTransform: "capitalize"}} variant="h6" gutterBottom>
    					       Projects
    					    </Typography>
    					    <Typography style={{marginLeft: "16px", textTransform: "capitalize"}} variant="body1" gutterBottom>
    					       Usage: {total_requests} Credits
    					    </Typography>
        					<List component="nav">
					        	{projects.map((project, index) => 
						        	<ListItem button style={this.state.selectedProjectId==project.id ? {backgroundColor: "#E5E5E5"} : {}} key={index} id={project.id} onClick={this.selectProject}>
						          		<ListItemText primary={project.name} secondary={project.project_type}/>
						        	</ListItem>
						        )
					        	}
					      	</List>
					      	</div>
        				</Grid>
        				<Grid item md={2} style={{borderLeft: "1px solid #eeeeee"}}>
        					<div style={{marginLeft: "12px", marginRight: "12px"}}>
        					<Typography style={{marginLeft: "16px", textTransform: "capitalize"}} variant="body1" gutterBottom>
    					       Project fragments
    					    </Typography>
    					     {this.state.selectedProjectId &&
    					     <Button 
					          	  color="primary"
					          	  onClick={this.downloadProjectCsv}
					          	  style={{marginLeft: "16px", textTransform: "capitalize"}}
					          	  size="small"
					          	>
					          	 Download CSV
					          	</Button>
					         }
        					<List component="nav">
					        	{projectFragments.map((projectFragment, index) => 
						        	<ListItem button
						        	style={this.state.selectedProjectFragmentId==projectFragment.id ? {backgroundColor: "#E5E5E5"} : {}}
						        	 key={index} id={projectFragment.id} onClick={this.selectProjectFragment}>
						          		<ListItemText primary={projectFragment.content && projectFragment.content.isIncomplete ? projectFragment.part_number +" | Incomplete" : projectFragment.content && projectFragment.content.toReview ? projectFragment.part_number +" | To Review" : projectFragment.part_number} 
						          					secondary={formatDateShortYear((projectFragment.created_at))}
						          					primaryTypographyProps={{
						          						style: projectFragment.content && projectFragment.content.isIncomplete ? {color: "red"}: projectFragment.content && projectFragment.content.toReview ? {color: "orange"} :{}
						          					}}
						          					/>
						        	</ListItem>
						        )
					        	}
					        	{projectFragments && projectFragments.length == 0 &&
					        		<ListItem>
						          		<ListItemText secondary="No fragments found in the project"/>
						        	</ListItem>
					        	}
					      	</List>
					      	</div>
        				</Grid>
        				<Grid item md={7} style={{marginLeft: "8px"}}>
        					{questionData && (questionData["question"] || questionData["options"]) &&
					            <div style={{
					              border: "1px solid rgb(247,247,247)",
					              padding: "5%",
					              boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
					              transition: "all 300ms linear",
					              borderRadius: "10px",
					              marginTop: this.state.offsetHeightForData
					            }}>
					            <Button 
					          	  color="primary"
					          	  onClick={this.toggleEditMode}
					          	  style={{ color: "white", backgroundColor: "#3f51b5", marginLeft: "auto", display: "block", marginRight: "2%", marginBottom: "8px"}}	
					          	  size="small"
					          	>
					          	  {this.state.isEditMode? "Save" :"Edit"}
					          	</Button>
					            {questionData["question"] && questionData["question"].map((item, index) => (
					              <React.Fragment key={index}>
					              {item[0] ? 
					              	isEditMode ?
					              	 <QuestionTextInput text={item[0]}
					              	  handleEditTestData={this.handleEditTestData} 
					              	  name={"question_"+index+"_0"}
									  isSingleComponent={true}
									  noImageAttachment={true}
									  rows="2"/>
					              	:
					                <div>
					                <QuestionText text={item[0]}/>
					                </div>
					              : null}
					              { item[1] ?
					                <QuestionImages 
					                src={item[1]} 
					                name={"question_"+index+"_1"}
					                isEditMode={isEditMode}
					                handleRemoveImageComponent={this.handleRemoveImageComponent}
					                />
					              : null
					              }
					              </React.Fragment>
					              ))
					            }
					            { questionData["options"] && 
					                <SingleAnswerOptions
					                dontShowLabel
					                answer={""} 
					                options={questionData["options"]} 
					                isEditMode={isEditMode}
					                noImageAttachment={true}
					                handleEditTestData={this.handleEditTestData}/>
					            }
					            </div>  
					        }
					        {pageData && pageData["page_content"] && pageData["page_content"].length > 0 &&
					            <div style={{
					              border: "1px solid rgb(247,247,247)",
					              padding: "2%",
					              boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
					              transition: "all 300ms linear",
					              borderRadius: "10px",
					              marginTop: this.state.offsetHeightForData
					            }}>
					            <Button 
					          	  color="primary"
					          	  onClick={this.toggleEditMode}
					          	  style={{ color: "white", marginLeft: "auto", display: "block", marginRight: "2%", marginBottom: "8px"}}	
					          	  size="small"
					          	  disabled={true}
					          	>
					          	  {this.state.isEditMode? "Save" :"Edit"}
					          	</Button>
					            {pageData["page_content"].map((pageComponent, index) => (
					              <React.Fragment key={index}>
					              	{pageComponent["label"] != "IMG" ?
						              	<Typography style={{textTransform: "none"}} 
						              	variant={this.getPageCompVariant(pageComponent,pageData["label_order"])} gutterBottom>
					              	       {pageComponent["content"]}
					              	    </Typography>
					              	:
						              	<QuestionImages 
						                src={pageComponent["content"]} 
						                name={"page"+index+"_1"}
						                isEditMode={false}
						                handleRemoveImageComponent={this.handleRemoveImageComponent}
						                />
				              	   	}
					              </React.Fragment>
					              ))
					            }
					            </div>  
					        }
        				</Grid>
        			</Grid>
        		</div>
			</React.Fragment>
		);
	}

}

export default MetaReadContentStore;