

export function validateEmailFormat(emailField) {	
	var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) == false) 
    {
        return false;
    }
    return true;
}

export function validateUsernameFormat(username) {
	var reg = /^([a-zA-Z0-9_\_\.]{8,32})$/;
    if (reg.test(username) == false) 
    {
        return false;
    }
    return true;
}

export function validateNumberFormat(number) {	
	var reg = /^(\d{8,12})$/;
    if (reg.test(number) == false) 
    {
        return false;
    }
    return true;
}


export function validateNumberFormatForUpto2DecimalNumbers(number) {  
    //var reg = /^\d*(\.\d{0,2})?$/;
    //var reg = /^\s*-?(\d+(\.\d{1,2})?|\.\d{0,2})?\s*$/
    var reg = /^-?(\d+(\.\d{0,2})?|\.?\d{0,2})?\s*$/
    if (reg.test(number) == false) 
    {
        return false;
    }
    return true;
}


export function validateNumberFormatForIntegerNumbers(number) {  
    var reg = /^\d?$/;
    if (reg.test(number) == false) 
    {
        return false;
    }
    return true;
}