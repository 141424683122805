import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

/*import Button from "CustomButtons/Button.jsx";*/

import CatalogTestSeriesContainer from "components/catalog/CatalogTestSeriesContainer.jsx"

class UserSubscriptionsRegion extends React.Component {

	 goToHome = (e) =>{
  		this.props.history.push('/home');
  	}

	render() {
		 /*const { classes} = this.props;*/
		return (
			<div>	
			  	<GridContainer style={{margin: "auto"}} justify="center" alignItems="center" direction="row">
			 		<GridItem xs={12} sm={12} md={12}>
					 <div style={{height: "90vh", backgroundColor: "white"}}>
					 	<h3 >
			 	          My Subscriptions
			 	        </h3>
					 	<CatalogTestSeriesContainer />
					 </div>
			 		</GridItem>
			 	</GridContainer>
			 </div>	
			);
	}

}

export default UserSubscriptionsRegion;
