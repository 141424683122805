/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import CustomDropdown from "CustomDropdown/CustomDropdown.jsx";
import Button from "CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import Public from "@material-ui/icons/Public";


function HeaderLinks({ ...props }) {
  const { classes, profileName,handleLogoutUser } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.headerRightLinkslistItem}>
        <Button 
          color="white"
          style={{color: "white", backgroundColor: "inherit",
          boxShadow: "none", border: "1px solid"}}
          onClick={handleLogoutUser}
          size="sm"
        >
          Log out
        </Button>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
