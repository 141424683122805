import ReactDOM from "react-dom";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import regeneratorRuntime from "regenerator-runtime";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Button from "CustomButtons/Button.jsx";
import CustomInput from "CustomInput/CustomInput.jsx";
import HorizontalStepper from "components/misc/HorizontalStepper"
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import Avatar from '@material-ui/core/Avatar';

import "react-image-crop/dist/ReactCrop.css";

import MetadReadLogo from 'assets/img/images/metaread_logo.png'

import TextField from '@material-ui/core/TextField';
import { FilePond } from 'react-filepond';

//import "./App.css";
import Snackbar from '@material-ui/core/Snackbar';
//import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import LinearProgress from '@material-ui/core/LinearProgress';

import { Cell, Tooltip, PieChart, Pie, Label,ComposedChart, CartesianGrid,XAxis,YAxis, Legend, Bar} from 'recharts';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import qs from 'query-string';

import MetaReadHeader from 'metaread/MetaReadHeader';
import MetaReadCreateProjectDialog from 'metaread/MetaReadCreateProjectDialog';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import MetaReadDoubtsEmbed from 'metaread/MetaReadDoubtsEmbed';

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);



class MetaReadHome extends React.Component {
  
  state = {
    src: "",
    crop: {
      x: 0,
      y: 0,
      unit: "%",
      width: 100,
      height: 100, 
    },
    uploadedFile: "",
    uploadedFileItems: "",
    uploadedFileName: "",
    uploadStatus: "",
    errorsReported: [],
    fpage: "",
    lpage: "",
    srcImages: [],
    marginData: [],
    secondCrop: false,  
    qFreq: "30",
    testId: "",
    dirPath: "",
    log: [], 
    activeStep: 0,
    mathEnabled: true,
    layoutCode: "1S",
    optionLabelStyle: "OPT1",
    snackbarOpen: false,
    time_elapsed: "",
    completion_accuracy: 0,
    review_accuracy: 0,
    questions: [],
    incomplete_questions_list: [],
    review_questions_list: [],
    testSearchResults: [],
    jsonOutput: "",
    jsonHTMLOutput: "",
    mode: "",
    type: "QP",
    projectId: "",
    openCreateProjectDialog: false,
    projectSearchResults: [] 
  };

  componentDidMount(){
    const mode = qs.parse(this.props.location.search).mode;
    if(mode){
      this.setState({mode})
    }

    this.getProjects(this.state.type)

    /*$.ajax({
      type: 'GET',
      url: getBaseURL() + '/get-manage-tests.json?limit=5&global_program_id=7',
    }).done((data, status, jqXHR)=>{
      this.populateTestsTableFromResults(data);
    }).fail((jqXHR, textStatus, errorThrown) => {
      var errorsReported = this.state.errorsReported;
      errorsReported.push({message: "An Unexpected Error occured while searching for Test."})
        this.setState({errorsReported: errorsReported});
    })*/
  }

  getProjects(type){
    $.ajax({
      type: 'GET',
      url: '/metaread_projects.json?type='+type+'&limit=20',
    }).done((data, status, jqXHR)=>{
      this.populateProjectResults(data);
    }).fail((jqXHR, textStatus, errorThrown) => {
      var errorsReported = this.state.errorsReported;
      errorsReported.push({message: "An Unexpected Error occured while searching for Test."})
        this.setState({errorsReported: errorsReported});
    })
  }

  populateProjectResults(data){
    var projectsInfo = data["projects"];
    var projectSearchResults = []
    if(projectsInfo && projectsInfo.length > 0){
      for(var i in projectsInfo){
        projectSearchResults.push({
          id: projectsInfo[i].id, 
          name: projectsInfo[i].name,
        });
      }
      this.setState({projectSearchResults});
    }else{
      this.setState({projectSearchResults: []});
    }
  }

  populateTestsTableFromResults(data){
    var testInfo = data["test_info"];
    var testSearchResults = []
    if(testInfo && testInfo.length > 0){
      for(var i in testInfo){
        testSearchResults.push({
          id: testInfo[i].id, 
          name: testInfo[i].name,
        });
      }
      this.setState({testSearchResults});
    }else{
      this.setState({testSearchResults: []});
    }
    this.setState({errorsReported: []});
  }

  handleFileInput = (e) => {
    e.preventDefault();
    var uploadedFileName = e.currentTarget.files[0].name;
    var uploadedFile = e.currentTarget.files[0];
    this.setState({uploadedFileName,uploadedFile});
  }

  handleInputFiles = fileItems => {
      if(this.state.type != "SINGLE" && this.state.type != "FREESTYLE" && fileItems[0].fileType!='application/pdf'){
        this.setState({
        errorsReported: "Please provide a PDF file", 
        snackbarOpen: true,
        uploadedFile: "",
        uploadedFileItems: "",
        uploadedFileName: ""
      });
        return
      }

      if((this.state.type == "SINGLE" || this.state.type == "FREESTYLE") && (!fileItems[0].fileType.startsWith("image/"))){
        this.setState({
        errorsReported: "Please provide a Image (PNG/JPG/JPEG) file", 
        snackbarOpen: true,
        uploadedFile: "",
        uploadedFileItems: "",
        uploadedFileName: ""
        });
        return
      }

      if(fileItems[0].fileSize > 5000000){
        this.setState({errorsReported: "File is too big. Max File Size: 5MB", snackbarOpen: true})
      }
      // Set currently active file objects to this.state
      this.setState({
        uploadedFile: fileItems[0].file,
        uploadedFileItems: fileItems,
        uploadedFileName: fileItems[0].filename
      });
      
  }

  handleUploadFile = (e) => {
   e.preventDefault();
   const {fpage, lpage, qFreq, testId, uploadedFile, projectId} = this.state;
    if(!fpage || !lpage || !qFreq || !projectId || !uploadedFile){
      this.setState({errorsReported: "Project, File, First Page and Last Page fields are required", snackbarOpen: true})
      return false
    }
    var formData = new FormData();
    formData.append('doc', this.state.uploadedFile);
    formData.append('fpage', this.state.fpage);
    formData.append('lpage', this.state.lpage);
    formData.append('filetype', this.state.type);
    this.setState({uploadStatus: "Processing",errorsReported: "Processing", snackbarOpen: true});
    	$.ajax({
          type: 'POST',
          url: "/metaread/upload",
          data: formData,
          cache: false,
          contentType: false,
          processData: false
        }).done((data, status, jqXHR)=>{
          this.setState({
            uploadStatus: 'DONE',
            errorsReported: data["message"],
            snackbarOpen: true,
            srcImages: data["images"],
            src: data["images"][0],
            crop: data["crop"],
            dirPath: data["dirPath"],
            activeStep: 1
          });
        }).fail((jqXHR, textStatus, errorThrown) => {
          var errorsReported = ""
          var responseJson = jqXHR["responseJSON"]
          if (responseJson && responseJson["message"]){
            errorsReported = responseJson["message"]
          }else{
            errorsReported = 'FAILED'
          }
          this.setState({
            uploadStatus: 'FAILED',
            errorsReported: errorsReported,
            snackbarOpen: true
          });
        })
  }

  handleImageUpload = (e) => {
   e.preventDefault();
   const {uploadedFile, projectId} = this.state;
    if(!projectId || !uploadedFile){
      this.setState({errorsReported: "Project and File are required", snackbarOpen: true})
      return false
    }
    var formData = new FormData();
    formData.append('doc', this.state.uploadedFile);
    formData.append('filetype', this.state.type);
    this.setState({uploadStatus: "Processing",errorsReported: "Processing", snackbarOpen: true});
      $.ajax({
          type: 'POST',
          url: "/metaread/upload",
          data: formData,
          cache: false,
          contentType: false,
          processData: false
        }).done((data, status, jqXHR)=>{
          this.setState({
            uploadStatus: 'DONE',
            errorsReported: data["message"],
            snackbarOpen: true,
            srcImages: data["images"],
            src: data["images"][0],
            crop: data["crop"],
            dirPath: data["dirPath"],
            activeStep: 1
          });
        }).fail((jqXHR, textStatus, errorThrown) => {
          var errorsReported = ""
          var responseJson = jqXHR["responseJSON"]
          if (responseJson && responseJson["message"]){
            errorsReported = responseJson["message"]
          }else{
            errorsReported = 'FAILED'
          }
          this.setState({
            uploadStatus: 'FAILED',
            errorsReported: errorsReported,
            snackbarOpen: true
          });
        })
  }


  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>{
        this.setState({ src: reader.result });
        var image = new Image();
	    image.src = reader.result;
	    image.onload = function() {
	        console.log("img_widht: "+image.width);
        	console.log("img_height: "+image.height)
        	/*let crop={
        		x: 25,
        		y: 50,
        		unit: "px",
        		width: Math.floor(image.width/2)-100,
        		height: Math.floor(image.height/2)-150
        	}
        	this.setState({crop});*/
        	//this.makeClientCrop(crop);
	    }.bind(this);
        }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  handleCrop = (e) => {
  	let crop = this.state.crop;
  	var image = this.imageRef;
  	const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
  	var cropData = {
  			x: Math.floor(crop.x*scaleX),
  			y: Math.floor(crop.y*scaleY),
  			width: Math.floor(crop.width*scaleX),
  			height: Math.floor(crop.height*scaleY)
  		}
  	if(!this.state.secondCrop && this.state.srcImages.length > 1){
  		var marginData = []
  		marginData.push(cropData)
  		let src = this.state.srcImages[1]
  		this.setState({secondCrop: true,marginData,src})
  	}else{
  		var marginData = this.state.marginData;
  		marginData.push(cropData)
  		this.setState({marginData})
      if(this.state.type == "BOOK" || this.state.type == "FREESTYLE"){
        this.runMetaReadForBooks()
      }else{
  		  this.runMetaRead();
  	  }
    }
  }

  runMetaRead(){
    var fpage = this.state.fpage;
    var lpage = this.state.lpage;
    if(this.state.type == "SINGLE"){
      fpage = 1;
      lpage = 1;
    }
  	var formData = new FormData();
  	formData.append('marginData', JSON.stringify(this.state.marginData));
  	formData.append('fpage', fpage);
  	formData.append('lpage', lpage);
  	formData.append('qFreq', this.state.qFreq);
  	formData.append('testId', this.state.testId);
  	formData.append('dirPath', this.state.dirPath);
    formData.append('mathEnabled', this.state.mathEnabled);
    formData.append('layoutCode', this.state.layoutCode);
    formData.append('optionLabelStyle', this.state.optionLabelStyle);
    formData.append('integration', 'CONSOLE');
    formData.append('projectId', this.state.projectId);

    this.setState({activeStep: 2, 
      errorsReported: "MetaRead is processing your request..", 
      snackbarOpen: true,
      log: []})

	$.ajax({
      type: 'POST',
      url: "/metaread/run",
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    }).done((data, status, jqXHR)=>{
      this.setState({
        uploadStatus: 'DONE',
        errorsReported: data["message"],
        snackbarOpen: true,
        log: data["log"],
        jsonOutput: data["raw_output"],
        jsonHTMLOutput: data["html_output"], 
        time_elapsed: data["time_elapsed"],
        completion_accuracy: (data["completion_accuracy"] ? data["completion_accuracy"] : 0),
        review_accuracy: (data["review_accuracy"] ? data["review_accuracy"] : 0),
        questions: data["questions"],
        incomplete_questions_list: data["incomplete_questions_list"],
        review_questions_list: data["review_questions_list"],
      });
    }).fail((jqXHR, textStatus, errorThrown) => {
      var errorsReported = ""
      var responseJson = jqXHR["responseJSON"]
      if (responseJson && responseJson["message"]){
        errorsReported = responseJson["message"]
      }else{
        errorsReported = 'FAILED'
      }
      this.setState({
        activeStep: 1,
        uploadStatus: 'FAILED',
        errorsReported: errorsReported,
        snackbarOpen: true
      });
    })
  }

  runMetaReadForBooks(){
    var formData = new FormData();
    formData.append('marginData', JSON.stringify(this.state.marginData));
    formData.append('fpage', this.state.fpage);
    formData.append('lpage', this.state.lpage);
    formData.append('qFreq', this.state.qFreq);
    formData.append('dirPath', this.state.dirPath);
    formData.append('mathEnabled', this.state.mathEnabled);
    formData.append('layoutCode', this.state.layoutCode);
    formData.append('optionLabelStyle', this.state.optionLabelStyle);
    formData.append('integration', 'CONSOLE');
    formData.append('projectId', this.state.projectId);

    this.setState({activeStep: 2, 
      errorsReported: "MetaRead is processing your request..", 
      snackbarOpen: true,
      log: []})

  $.ajax({
      type: 'POST',
      url: "/metaread/books",
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    }).done((data, status, jqXHR)=>{
      this.setState({
        uploadStatus: 'DONE',
        errorsReported: data["message"],
        snackbarOpen: true,
        log: data["log"],
        jsonOutput: data["raw_output"],
        jsonHTMLOutput: data["html_output"],
        time_elapsed: data["time_elapsed"],
      });
    }).fail((jqXHR, textStatus, errorThrown) => {
      var errorsReported = ""
      var responseJson = jqXHR["responseJSON"]
      if (responseJson && responseJson["message"]){
        errorsReported = responseJson["message"]
      }else{
        errorsReported = 'FAILED'
      }
      this.setState({
        activeStep: 1,
        uploadStatus: 'FAILED',
        errorsReported: errorsReported,
        snackbarOpen: true
      });
    })
  }

   handleInput = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var obj ={};
    obj[id] = e.target.value;
    this.setState(obj);
  }

  activeStepChanged = (activeStep) => {  
    this.setState({activeStep});
  }

  validateSteps = (activeStep) =>{
    if(this.state.activeStep == 0){
      const {fpage, lpage, qFreq, testId, uploadedFile} = this.state;
      if(!fpage || !lpage || !qFreq || !testId || !uploadedFile){
        this.setState({errorsReported: "All fields are required", snackbarOpen: true})
        return false
      }
    }
    return true

  }

  toggleMath =(e) =>{
    this.setState({mathEnabled: !this.state.mathEnabled})
  }

  changeLayoutCode = (e)=>{
      this.setState({layoutCode: e.target.value})
  }

  toggleOptionLabel = (e) =>{
    this.setState({optionLabelStyle: e.target.value})
  }

  goToMetaReadTest = (e) => {
    const testId = this.state.testId;
    var win = window.open("/test_questions/new?mode=view&test_id="+testId,"_blank")
    win.focus()
  }

  goToMetaReadDataStore = (e) => {
    const projectId = this.state.projectId;
    var win = window.open("/metaread/store?projectId="+projectId,"_blank")
    win.focus()
  }

  handleSnackbarClose = (e) => {
    this.setState({snackbarOpen: false})
  }

  openCreateProjectDialog = (e) => {
    this.setState({openCreateProjectDialog: true})
  }

  closeCreateProjectDialog = (e) => {
    this.setState({openCreateProjectDialog: false})
  }

  onProjectCreationSuccess = (e)=>{
    this.setState({openCreateProjectDialog: false, errorsReported: "Project Created", snackbarOpen: true})
    this.getProjects(this.state.type);
  }

  showErrorMessage(msg){
    this.setState({errorsReported: msg, snackbarOpen: true})
  }

  goToMetaReadStorePage = (e) => {
      this.props.history.push('/metaread/store');
  }

  render() {
    const { crop, croppedImageUrl, src, errorsReported,activeStep,uploadedFile,snackbarOpen } = this.state;
    const {classes} = this.props;
    const {colors} = ["","green","red",]
    return (
      <div style={{minHeight: "100vh", backgroundColor: "white"}}>
        <MetaReadHeader actionButtonLabel={"Content Store"} goToLink={this.goToMetaReadStorePage}/>
       <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            open={snackbarOpen && errorsReported}
            onClose={this.handleSnackbarClose}
            key={ 'top' + 'right'}
            severity="warning"
            autoHideDuration={6000}            
          >
          <MySnackbarContentWrapper
            onClose={this.handleSnackbarClose}
            variant="warning"
            style={{fontWeight: "600"}}
            message={errorsReported}
          />
          {/* <Alert onClose={this.handleSnackbarClose} severity="warning">
              {errorsReported}
            </Alert>*/}
          </Snackbar>
      <GridContainer style={{
          backgroundColor: "white",
          paddingTop: "2%",
          paddingBottom: "20px",
          width: "100%", 
          margin: "10px 0px 0px",
          marginTop: "0px",
          height: "100%",
          }}
          spacing={24}>
         <GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px", paddingLeft: "10%"}}>
          <HorizontalStepper
          activeStepProp={activeStep} 
          steps={['Upload Test', 'Configure', 'MetaRead']}
          activeStepChanged={this.activeStepChanged}
          validateSteps={this.validateSteps}/>
        </GridItem>
        {/*<GridItem xs={12} sm={12} md={12}>
            <p style={{width: "fit-content",margin: "auto", fontSize: "16px", color: "#a75b00", whiteSpace: "pre-line"}} >{errorsReported ? errorsReported: ""}</p>
        </GridItem>*/}
        {activeStep == 0 &&
          <React.Fragment>
          <GridItem style={{margin: "auto"}} xs={6} sm={6} md={3}>
          <div style={{marginBottom: "15px"}}>
            <FormControl fullWidth>
              <InputLabel id="type">Type</InputLabel>
              <Select id="type" value={this.state.type}  name="type"  
              onChange={ 
                (event) => {
                this.setState({type: event.target.value, projectId: ""})
                this.getProjects(event.target.value)
                }
              }
                >
                  <MenuItem value="QP">Question Paper (PDF)</MenuItem>
                  <MenuItem value="SINGLE">Questions (Image) </MenuItem>
                  <MenuItem value="DOUBTS">Doubt (Hand written)</MenuItem>
                  <MenuItem value="BOOK">Book</MenuItem>
                  <MenuItem value="FREESTYLE">Freestyle (Image)</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{marginBottom: "10px"}}>
          <FormControl fullWidth>
            <InputLabel id="projectId">Project</InputLabel>
            <Select id="projectId" style={{textTransform: "capitalize"}} value={this.state.projectId}  name="projectId"  
            onChange={ 
              (event) => {this.setState({projectId: event.target.value})}
            }
              >
              { this.state.projectSearchResults.map((project, index) =>
                <MenuItem key={index} style={{textTransform: "capitalize"}}
                 value={project.id}>{project.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <Grid container>
            <Grid item xs>
              <Link onClick={this.openCreateProjectDialog} style={{float: "right", marginTop: "8px", fontWeight: "400"}}
              variant="body2">
                {"Create Project"}
              </Link>
            </Grid>
          </Grid>
          <MetaReadCreateProjectDialog open={this.state.openCreateProjectDialog} 
            onClose={this.closeCreateProjectDialog}
            onSuccess={this.onProjectCreationSuccess}
            onFailure={(msg) => this.showErrorMessage(msg)}/>
          </div>
          
          <div style={{marginBottom: "20px", marginTop: "10px"}}>
          </div>
          { this.state.type != "DOUBTS" &&
          <React.Fragment>
            <div>
                <div style={{marginTop: "10px", marginBottom: "5px"}}>
                      <div style={{paddingBottom: "10px"}}>
                        <span>Upload</span>
                      </div>  
                    <FilePond
                      files={this.state.uploadedFileItems}
                      onupdatefiles={this.handleInputFiles}           
                    />
                </div>
            </div>
          {(this.state.type == "QP" || this.state.type == "BOOK") &&
            <div>
            
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="fpage"
                label="First Page"
                name="fpage"
                autoFocus
                value={this.state.fpage}
                onChange={this.handleInput}
                helperText="Metaread will process from this page number"
              />
            </div>
          }
          {(this.state.type == "QP" || this.state.type == "BOOK") &&
          <div>
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lpage"
              label="Last Page"
              name="lpage"
              autoFocus
              value={this.state.lpage}
              onChange={this.handleInput}
              helperText="Metaread will process till this page number"
            />
          </div>
          }
          <Button
            //color="success"
            style={{width: "auto", margin: "auto", marginTop: "10px",
             backgroundColor: "#3f51b5"}}
            onClick={(this.state.type == "QP"  || this.state.type == "BOOK" )? this.handleUploadFile:this.handleImageUpload}>
                   Upload File
            </Button>
            </React.Fragment> 
          }
          </GridItem>
          <GridItem style={{margin: "auto"}} md={12}>
          { this.state.type == "DOUBTS" &&
            <MetaReadDoubtsEmbed projectId={this.state.projectId}/>
          }
          </GridItem>
          </React.Fragment>
        }
        {activeStep == 1 &&
          <React.Fragment>
          <GridItem xs={12} sm={12} md={12}>
          <div style={{margin: "auto", width: "fit-content", marginTop: "10px", marginBottom: "10px"}}>
          <div style={{display: "inline-block", margin: "20px"}}>
          
       <FormControl variant="outlined" style={{width: "100%"}}>
             {/* <InputLabel id="layout_label">
              Layout
          </InputLabel>*/}
          <InputLabel
            htmlFor="outlined-age-simple"
          >
            Layout
          </InputLabel>
            <Select
              value={this.state.layoutCode}
              onChange={this.changeLayoutCode}
              input={
              <OutlinedInput
                labelWidth={50}
                name="layout"
                id="outlined-age-simple"
              />
              }
              style={{fontSize: 'inherit', width: "150px"}}>
                <MenuItem  style={{fontSize: "14px"}} value="1S">1-side per page</MenuItem>
                <MenuItem style={{fontSize: "14px"}} value="2S">2-side per page</MenuItem>
            </Select>
          </FormControl>
          </div>
          {this.state.mode &&
            <React.Fragment>
            <div style={{display: "inline-block", margin: "20px"}}>
          
            <FormControl  variant="outlined"  style={{width: "100%", minWidth: "150px"}}>
              <InputLabel
              htmlFor="outlined-age-simple2">
              Option Label Style
              </InputLabel>
              <Select 
                label="Option Label Style"
                value={this.state.optionLabelStyle}
                onChange={this.toggleOptionLabel}
                style={{fontSize: 'inherit'}}
                 input={
                <OutlinedInput
                  labelWidth={130}
                  name="layout"
                  id="outlined-age-simple2"
                />
              }>
                  <MenuItem  style={{fontSize: "14px"}} value="OPT1">1) 2) 3) 4)</MenuItem>
                  <MenuItem  style={{fontSize: "14px"}} value="OPT2">(1) (2) (3) (4)</MenuItem>
                  <MenuItem  style={{fontSize: "14px"}} value="OPT3">A) B) C) D)</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{display: "inline-block", margin: "20px"}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.mathEnabled}
                  onChange={this.toggleMath}
                  name="checkedB"
                  color="primary"
                />
              }
              style={{color: "black", marginBottom: "-10px"}}
              label="Enable Math"
            />
          </div>
          </React.Fragment>}
          </div>
          </GridItem>
        <GridItem xs={12} sm={12} md={6}>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            style={{ maxWidth: "100%" }}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
        </GridItem>
        <GridItem xs={12} sm={12} md={4} >
        {croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: "100%", border: "1px dotted rgba(0, 128, 0, 0.5)" }}  src={croppedImageUrl} />
        )}
        </GridItem>
         <GridItem xs={12} sm={12} md={2} >
         {croppedImageUrl && (
        	<Button style={{background: "#41525c"}} onClick={this.handleCrop}>
        	    {this.state.secondCrop ? "Crop & Run" : "Crop & Next"}
        	</Button>
        	)}
        </GridItem>
        </React.Fragment>
        }
        {/*activeStep == 2 && this.state.log && this.state.log.length > 0 && 
        	<React.Fragment>
          <GridItem style={{marginLeft: "auto"}} xs={12} sm={12} md={12}>
            <div style={{width: "fit-content", margin: "auto"}}>
            <Button size="sm" style={{background: "#41525c"}} onClick={this.goToMetaReadTest}>
                Go To Test
            </Button>
            </div>
          </GridItem>
          <GridItem style={{margin: "auto", border: "1px solid lightgray", borderRadius: "10px", fontWeight: "400", paddingLeft: "15px"}} xs={12} sm={12} md={6}>
        	{this.state.log.map((logLine, index) => 
        		<p>{logLine}</p>
        	)}	
        	</GridItem>
          </React.Fragment>*/
        }
        {activeStep == 2 && this.state.log && this.state.log.length > 0 ?
          <React.Fragment>
          { this.state.type != "BOOK" && this.state.type != "FREESTYLE" &&
            <React.Fragment>
           <GridItem xs={10} sm={10} md={3}>
           </GridItem>
          <GridItem xs={10} sm={10} md={2}>
            <div>                
            <PieChart width={110} height={220} 
            style={{margin: "auto"}}
              margin={{
                top: 0, right: 0, left: 0, bottom: 0,
              }}>
                <Pie
                  data={[{name: "correct", value: this.state.completion_accuracy},{name: "incorrect", value: (100-this.state.completion_accuracy)}]}
                  cx={55}
                  cy={120}
                  innerRadius={40}
                  outerRadius={55}
                  fill="#8884d8"
                  paddingAngle={2}
                  dataKey="value"
                  labelLine={false}
                >
                  
                  <Cell fill={"green"} />
                  <Cell fill={"red"} />
                  <Label style={{fontWeight: "400", fontSize: "1.4rem"}} value={this.state.completion_accuracy + "%"} offset={0} position="center" />
                </Pie>
                 <Tooltip />

            </PieChart>
            <p style={{color: "black",  marginTop: "-30px", textAlign: "center", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>Completion Accuracy</p>                  
            </div>
          </GridItem>
         <GridItem xs={10} sm={10} md={2}>
            <div >                
            <PieChart width={110} height={220} 
            style={{margin: "auto"}}
              margin={{
                top: 0, right: 0, left: 0, bottom: 0,
              }}>
                <Pie
                  data={[{name: "correct", value: this.state.review_accuracy},{name: "incorrect", value: (100-this.state.review_accuracy)}]}
                  cx={55}
                  cy={120}
                  innerRadius={40}
                  outerRadius={55}
                  fill="#8884d8"
                  paddingAngle={2}
                  dataKey="value"
                  labelLine={false}
                >
                  
                  <Cell fill={"green"} />
                  <Cell fill={"red"} />
                  <Label style={{fontWeight: "400", fontSize: "1.4rem"}} value={this.state.review_accuracy + "%"} offset={0} position="center" />
                </Pie>
                 <Tooltip />

            </PieChart>
            <p style={{color: "black",  marginTop: "-30px", textAlign: "center", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>Review Accuracy</p>                  
            </div>
          </GridItem>
            <GridItem xs={10} sm={10} md={2} style={{marginTop: "auto"}}>
            <div style={{marginTop: "10%", fontFamily: "'Noto Serif', serif"}}>
              <ComposedChart
                layout="vertical"
                width={400}
                height={120}
                data={this.state.questions}
                margin={{
                  top: 0, right: 0, left: 40, bottom: 0,
                }}>
              >
                <XAxis type="number" hide domain={[0, 30]} />
                <YAxis dataKey="name" type="category" />
                <Bar dataKey="value" barSize={20} label={{fill: 'white'}}>
                  <Cell fill={"#413ea0"}/>
                  <Cell fill={"green"}/>
                  <Cell fill={"red"}/>
                  <Cell fill={"orange"}/>
                </Bar>
               {/* <Bar dataKey="correct" barSize={20} fill={"green"} label={{fill: 'white'}}/>
                <Bar dataKey="incorrect" barSize={20} fill={"red"} label={{fill: 'white'}}/>
                <Bar dataKey="review" barSize={20} fill={"orange"} label={{fill: 'white'}}/>*/}
              </ComposedChart>
              <p style={{color: "#666666", textAlign: "right", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>Questions</p>                  
            </div>
          </GridItem>
          </React.Fragment>
          }
          <GridItem xs={10} sm={10} md={6} style={{margin: "auto", paddingTop: "2%"}}>
            <p style={{color: "black", textAlign: "left", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
              {"Processing Time: "+this.state.time_elapsed+" seconds"}
            </p>
            <br />
           {/* <p style={{color: "black", textAlign: "left", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
              Total Questions: 
            </p>
            <p style={{color: "black", textAlign: "left", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
              Incomplete Questions: 
            </p>*/}
             {/*<p style={{color: "black", textAlign: "left", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
             {"Incomplete Questions List: "+this.state.incomplete_questions_list}
            </p>*/}
            {/*<p style={{color: "black", textAlign: "left", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
              Questions for Review:  
            </p>*/}
            {/*<p style={{color: "black", textAlign: "left", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
               {"Questions for Review List: "+this.state.review_questions_list}
            </p>*/}
            <div style={{width: "fit-content", margin: "auto", marginTop: "20px"}}>
             <Button style={{background: "#3f51b5", margin: "auto"}} onClick={this.goToMetaReadDataStore}>
                Go To Content Store
            </Button>
            </div>
          </GridItem>
          <GridItem style={{margin: "20px", border: "1px solid lightgray", borderRadius: "10px", fontWeight: "400", paddingLeft: "15px"}} xs={12} sm={12} md={12}>
            <Button style={{background: "#3f51b5"}} size="sm" href={"data:application/txt," + encodeURIComponent(this.state.jsonOutput)} download="latexOuput.json">
                Download JSON (latex)
            </Button>
            {this.state.jsonHTMLOutput && 
            <Button style={{background: "#3f51b5", marginLeft: "10px"}} size="sm" href={"data:application/txt," + encodeURIComponent(this.state.jsonHTMLOutput)} download="offlineHTMLOuput.json">
                Download JSON (Offline HTML)
            </Button>
            }
          <pre style={{whiteSpace: "pre-line"}}>{this.state.jsonOutput}</pre>
          </GridItem>
          {/*<GridItem style={{margin: "20px", border: "1px solid lightgray", borderRadius: "10px", fontWeight: "400", paddingLeft: "15px"}} xs={12} sm={12} md={12}>
          <pre style={{whiteSpace: "pre-line"}}>{this.state.jsonHTMLOutput}</pre>
          </GridItem>*/}
          </React.Fragment>
          : activeStep == 2 &&
          <GridItem style={{margin: "auto", paddingTop: "10%"}} xs={6} sm={6} md={3}>
            MetaRead is processing your request...
            <LinearProgress color="secondary" />
           
          </GridItem>
        }
      </GridContainer>
      </div>
    );
  }
}

export default MetaReadHome;

