import React from "react";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const getMuiThemeForTableAnswer = createMuiTheme({
	overrides: {
      	MuiTableCell: {
        	root: {
          		border: "none",
          		padding: "4px 18px 4px 18px",
          		textAlign: "center"
        	}
      	}
	}
	});


class MatrixGridAnswerComponent extends React.Component {

	constructor(props) {
	    super(props);
	     this.state = {
		    matrixCheckedArray: []
		  };
	  }

	componentDidMount(){
		const answer = this.props.answer;
		if(answer && answer.length > 0){
			this.setState({matrixCheckedArray: answer})
		}else{
			this.initMatrixAnswerData();
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.answer != prevProps.answer){
			const answer = this.props.answer;
			if(answer && answer.length > 0){
				this.setState({matrixCheckedArray: answer})
			}else{
				this.initMatrixAnswerData();
			}
		}
	}

	initMatrixAnswerData(){
	    var answersArray = []
	    if(this.props.list1 && this.props.list1.length > 0){
		    this.props.list1.map((item,index) =>(
		      answersArray.push([])
		    ))
		}
	    this.setState({matrixCheckedArray: answersArray})
   	}

	handleToggle(list1Index, list2Index) {
	    var matrixCheckedArray = this.state.matrixCheckedArray;
	    var value = (list2Index+1).toString();
	    if(matrixCheckedArray && matrixCheckedArray[list1Index]){
	    	const currentIndex = matrixCheckedArray[list1Index].indexOf(value);
	    
		    if (currentIndex === -1) {
		      matrixCheckedArray[list1Index].push(value);
		    } else {
		      matrixCheckedArray[list1Index].splice(currentIndex, 1);
		    }
	    	this.setState({matrixCheckedArray});
	    	this.props.answerChanged(matrixCheckedArray);
	    }
	  }

	render() {
		 const { classes,list1, list2, answer, answerChanged, AnswerKey, showSolutions,isDisabled} = this.props;
		 const {matrixCheckedArray} = this.state;
		return (
			<GridContainer>
				<GridItem xs={12} sm={3} md={3} lg={3} style={{margin: "15px"}}>
					<MuiThemeProvider theme={getMuiThemeForTableAnswer}>
					<Table>
						<TableHead>
							<TableRow>
				            	<TableCell></TableCell>
				            	{list1 && list2 && list1.length > 0 && list2.length > 0 && list2.map((row, index) => (
				            		<TableCell><strong>{String.fromCharCode(80+index)}</strong></TableCell>
				            	))}
				          	</TableRow>
						</TableHead>
						<TableBody>
						{list1 && list2 && list1.length > 0 && list2.length > 0 && list1.map((row, index) => (
							<TableRow key={index}>
							<TableCell><strong>{String.fromCharCode(65+index) + ") "}</strong></TableCell>
							{list1 && list2 && list2.map((row, index2) => (
								<TableCell>
									<Checkbox
								        checked={matrixCheckedArray && matrixCheckedArray[index] && matrixCheckedArray[index].includes((index2+1).toString()) ? true : false}
								        onChange={() => this.handleToggle(index,index2)}
								        name={"list_"+index}
								        color="primary"
								        disabled={showSolutions || isDisabled} 
								        style={showSolutions && AnswerKey && AnswerKey[index] && AnswerKey[index].includes((index2+1).toString()) ?
								        		{backgroundColor: "rgba(0, 128, 0, 0.2)"}:{}}
								    />
								</TableCell>
							))}
							</TableRow>
						))}
						</TableBody>
					</Table>
					</MuiThemeProvider>
				</GridItem>
				<GridItem xs={12} sm={9} md={9} lg={9}>
				</GridItem>
			</GridContainer>
		)
	}
}

export default MatrixGridAnswerComponent;
