import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";

import $ from 'jquery';
import Header from 'components/Header.jsx';
import HeaderLeftLinks from 'components/HeaderLeftLinks.jsx';
import HeaderRightLinks from 'components/HeaderRightLinks.jsx';

import withStyles from "@material-ui/core/styles/withStyles";
import userLandingPageStyle from "styles/UserLandingPageStyle.jsx";

//import AllProductSection from "views/LandingPage/Sections/AllProductSection.jsx";

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import Button from "CustomButtons/Button.jsx";


import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Menu from "@material-ui/icons/Menu";

import brand_white from "assets/img/images/brand_white.png";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';


import { FilePond } from 'react-filepond';
//import 'filepond/dist/filepond.min.css';
import AccountCircle from "@material-ui/icons/AccountCircle";



const dashboardRoutes = [];

class RegistrationLandingPage extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      username: "",
	      drawerOpen: true,
	      headerPresent: true,
	      files: "",
	      fileExtension: "",
	      uploadStatus: "--",
	      errorMessage: ""
	    };
	  }

	componentDidMount() {

		if(!isWidthUp('sm', this.props.width)){
			this.props.history.push('/');
			return
		}

	    $.ajax({
	          type: "GET",
	          url: getBaseURL() + '/react/test-login.json',
	          dataType: "JSON",
	          //headers: JSON.parse(localStorage.user)
	        }).done((data) => {
	   			this.setState({username: data.username});
	        });
  	}


  	handleLogoutUser =(e)=> {
		this.logoutUser();
  	}

  	logoutUser(){
  		$.ajax({
  		  type: 'GET',
  		  url: getBaseURL() + '/global_users/sign_out'
  		}).done((response, status, jqXHR)=>{
  		  sessionStorage.removeItem('as_username');
  		  sessionStorage.removeItem('user');
  		  this.props.history.push('/login');
  		}) 
  		//localStorage.removeItem("user");
  	}

  	setFiles = fileItems => {
	    // Set currently active file objects to this.state
	    this.setState({
	      files: fileItems.map(fileItem => fileItem.file),
	      fileExtension: fileItems.map(fileItem => fileItem.fileExtension)
	    });
	    
	}

	uploadFileData = (e) => {
		e.preventDefault();
		var files = this.state.files;
		if (files.length < 1){
			alert("Please select the file and upload");
			return
		}

		var fileExtension = this.state.fileExtension;
  		if (fileExtension.length>0 && fileExtension[0] != "csv")
  		{
  			alert("Only .csv format file is supported.");
  			return
  		}

		var jsonAllStudentFileData = []
		var reader = new FileReader();
		 reader.onload = function(file) {
		 	var content = file.target.result;
		 	var rows = file.target.result.split(/[\r\n|\n]+/);

		 	//i=0 is for Header row, with keys need to send
		 	var keysArray =  rows[0].split(',');
		 	for (var i = 1; i < rows.length-1; i++){
		 		var valueArr = rows[i].split(',');
		 		if(valueArr[0].trim()!="")
		 		{
			 		var jsonStudentData = {}
			 		for (var j = 0; j < keysArray.length; j++){
			 			if (valueArr[j].trim() != ""){
			 				jsonStudentData[(keysArray[j].trim()).toLowerCase()]=valueArr[j].trim();
			 			}else{
			 				//valueArr[0] assuming first column in the name
			 				alert("The Value "+ keysArray[j].trim() + " is not provided for user "+ valueArr[0].trim() )
			 			}
			 		}
			 		jsonAllStudentFileData.push(jsonStudentData);
			 	}
		 	}
		 	this.postJSONFileData(jsonAllStudentFileData);
		 }.bind(this)
		 //Only File is processed 
		reader.readAsText(this.state.files[0]);

	}

	postJSONFileData(jsonAllStudentFileData){
		$.ajax({
	      type: 'POST',
	      url: getBaseURL() + '/registration/testparticipant/uploadFileData',
	      data: JSON.stringify(jsonAllStudentFileData),
	      contentType: 'application/json; charset=utf-8',
      	  //headers: JSON.parse(localStorage.user)
	    }).done((response, status, jqXHR)=>{
	    	console.log("File processed successfully");	
	    	this.setState({uploadStatus: "Success"});
	    	this.setState({errorMessage: jqXHR["responseJSON"] && jqXHR["responseJSON"].message ? jqXHR["responseJSON"].message: "More information could not be retrieved."});
	    }).fail((jqXHR, textStatus, errorThrown) => {
	    	this.setState({uploadStatus: "Failure"});
        	this.setState({errorMessage: jqXHR["responseJSON"] && jqXHR["responseJSON"].message ?  jqXHR["responseJSON"].message : "Error Message could not be retrieved."});
    	})
	}

	render() {
		 const { classes} = this.props;
		return (
			<div>
			{this.state.drawerOpen || this.state.headerPresent ?
			<Header
			  color="dark"
			  routes={dashboardRoutes}
			  brand={brand_white}
			  fixed
			  //leftLinks={<HeaderLeftLinks />}
			  rightLinks={<HeaderRightLinks handleLogoutUser={this.handleLogoutUser} profileName={this.state.username} />}
			  // changeColorOnScroll={{
			  //   //height: 200,
			  //   //color: "white"
			  // }}
			/>
			:null
		}
			<div className={classes.main} style={this.state.drawerOpen ? {top: "50px"} : null}> 
		{/*THIS child SHOULD BE A INDIVIDUAL COMPONENT - NAVBAR*/}
				{this.state.drawerOpen ?
			  	<Drawer open={this.state.drawerOpen}
		 	         variant="permanent"
		 	        anchor={"left"}
		 	        classes={{
		 	          paper: classes.drawerPaper
		 	        }}
		 	      	>
		 	        <div className={classes.toolbar} style={{paddingTop: "20px", paddingBottom: "20px",  textAlign: "center"}}> 
		 	        <GridContainer style={{margin: "auto"}} justify="center" alignItems="center" direction="row">
          				<GridItem xs={12} sm={12} md={12}> 
		 	        		<AccountCircle style={{fontSize: "96px", width: "100%"}}/>
		 	        		<p className={classes.robotoFontRegular}>{this.state.username}</p>
		 	        	</GridItem> 
		 	         </GridContainer>	
		 	        </div>
		 	        
		 	        {/*<List className={classes.list}>
		 	        	<Button
					          color="transparent"
					          className={classes.navLink}
					        >
					        <Menu/> Single User Upload
					    </Button>
				    </List>*/}
					<List className={classes.list}>
				        <Button
				          color="transparent"
				          className={classes.navLinkActive}	
				        >
				         <Menu /> Student Registration
				        </Button>
				        
					</List> 
 	      		</Drawer>
 	      		: null}
			 	<div className={classes.containerCustom} style={this.state.drawerOpen ? null : {paddingLeft: "0px"}}>
			 		<GridContainer style={{margin: "auto"}} justify="center" alignItems="center" direction="row">
			 		
			 		<GridItem xs={12} sm={12} md={12}>
					 <div style={{height: "auto", backgroundColor: "white"}}>
					 	<h3 style={{padding: "20px", paddingBottom: "0px"}}
					 	className={classes.robotoFontBold}>
			 	          Student Registration
			 	    	</h3>
			 	    	<hr className={classes.lineBreak} />
					 	<div>
							<GridContainer style={{margin: "auto"}}>
								 <GridItem  xs={12} sm={12} md={12} style={{margin: "auto",  padding: "20px", paddingTop: "0px", paddingBottom: "10px"}}> 
								    <p className={classes.robotoFontRegular} >Multiple Students Registration</p>   
	      						</GridItem>
						      	<GridItem  xs={12} sm={12} md={5} style={{ padding: "20px", paddingTop: "0px"}}>
						      		<div style={{paddingBottom: "10px"}}>
								    	<span className={classes.robotoFontRegularLabel}>Upload student data file (.csv)</span>
								    </div>	
								    <FilePond
							            files={this.state.files}
							            onupdatefiles={this.setFiles}
							          />
		                            <Button
		        			          //color="success"
		        			          style={{width: "50%", margin: "auto", marginTop: "5px", boxShadow: "none",
		        			           backgroundColor: "#57849b"}}
		        			          onClick={this.uploadFileData}>
		                               Upload File
		                            </Button> 
						        </GridItem>
						        <GridItem  xs={12} sm={12} md={7} style={{ padding: "20px", paddingTop: "0px", paddingBottom: "10px"}}> 
								    <p className={classes.robotoFontRegular} >Upload Status: {this.state.uploadStatus}</p>
								    <p style={{whiteSpace: "normal"}} className={classes.robotoFontRegularLabel} >{this.state.errorMessage}</p>   
	      						</GridItem>
							</GridContainer>	
						</div>	
					 </div>
			 		</GridItem>
			 	</GridContainer>
			  	</div>
			</div>
			</div>
			);
	}

}

export default withWidth()(withStyles(userLandingPageStyle)(RegistrationLandingPage));
