import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import Button from "CustomButtons/Button.jsx";
import $ from 'jquery';


export default class TestModelActions extends React.Component {

		handleViewTestModel = (e) =>{
			e.preventDefault();
			var testModelId = e.currentTarget.name;
		    if(testModelId){
		    	this.props.history.push('/test_template_infos?test_model_id=' + testModelId +'&mode=view');
			}
		}

		handleEditTestModel = (e) =>{
			e.preventDefault();
			var testModelId = e.currentTarget.name;
		    if(testModelId){
		    	this.props.history.push('/test_template_infos?test_model_id=' + testModelId +'&mode=edit');
			}
		}
		
		render () {
			const{name,errorMessageCallback} = this.props;
			const searchButtonStyle = {
			  	textTransform: "none",
			    backgroundColor: "#3f51b5",
			    color: "white",
			    borderRadius: "3px",
			    padding: "0.2rem 0.75rem",
			    "&:hover,&:focus": {
			      color: "white",
			      background: "#57849b",
			    },
			}
			return (
				<React.Fragment>
		            	<Button
		            	  name={name}    
			              size="sm"
			              onClick={this.handleViewTestModel}
			              style={searchButtonStyle}>
			              View
			            </Button>
		            	<Button    
		            	  name={name}
			              size="sm"
			              onClick={this.handleEditTestModel}
			              style={searchButtonStyle}>
			              Edit
			            </Button>
	        	</React.Fragment>
	        );
		}
}