import React from 'react'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import ManageTests from  'components/test/ManageTests'
import ManageSchedules from  'components/schedule/ManageSchedules'
import LoginPage from 'components/LoginPage';
import AppLayout from 'components/AppLayout';
import TestForm from  'components/test/TestForm';
import ScheduleTestForm from  'components/schedule/ScheduleTestForm';
import TestModelForm from 'components/TestModelForm';
import TestAnalysisDashboard from 'components/analysis/TestAnalysisDashboard';
import UserTestAnalysisDashboard from 'components/analysis/UserTestAnalysisDashboard';
import TestPreviewRegion from 'components/test/view/TestRegion';
import ImportTest from 'components/test/ImportTest';
import OverallAnalysisRegion from 'components/analysis/OverallAnalysisRegion';

import DatasetMain from 'components/dataset/DatasetMain';

//import UserLoginPage from "user_components/components/LoginPage.jsx";
import UserLandingPage from "user_components/components/UserLandingPage.jsx"; 
import RegistrationLandingPage from "user_components/components/enterprise/registration/RegistrationLandingPage.jsx"; 
import UserRegistration from "user_components/components/user/UserRegistration.jsx"

import UserAnalysisDashboard from 'components/analysis/UserAnalysisDashboard';

import ImportDataset from 'components/dataset/upload/ImportDataset';

import MetaReadHome from 'metaread/MetaReadHome';
import MetaReadDoubts from 'metaread/MetaReadDoubts';
import MetaReadDiagrams from 'metaread/MetaReadDiagrams';
import MetaReadContentStore from 'metaread/MetaReadContentStore';

import ScheduleTestLiveUsers from 'components/schedule/ScheduleTestLiveUsers';

//import "assets/scss/material-kit-react.css?v=1.1.0";
import ResetPassword from 'components/ResetPassword';

const App = (props) => (
  <Router>
    <div>
      	<Route exact path='/' 
	      	render={(routeProps) => (
	    		<LoginPage {...routeProps}/>
	  	 	)}  />
      	<Route exact path='/manage-tests' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<ManageTests {...routeProps}/>} />
	  	 	)}  />
  	 	<Route exact path='/test_infos/new' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<TestForm {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/schedule-tests' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<ManageSchedules {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/scheduled_tests/new' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<ScheduleTestForm {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/test-live-users' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<ScheduleTestLiveUsers {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/test_template_infos' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<TestModelForm {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/total-test-analysis' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<TestAnalysisDashboard {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/test-paper-analysis' 
	      	render={(routeProps) => (
	    		<AppLayout {...routeProps} yieldComponent={<TestAnalysisDashboard {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/student-test-dashboard' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<UserTestAnalysisDashboard {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/test_questions/new' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<TestPreviewRegion {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/offline_test' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} yieldComponent={<ImportTest {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/overall_analysis' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} yieldComponent={<OverallAnalysisRegion {...routeProps}/>} />
	  	 	)}  />
	  	<Route exact path='/student-analysis-dashboard' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<UserAnalysisDashboard {...routeProps}/>} />
	  	 	)}  />
	  	 <Route exact path='/home/analytics/myanalysis' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<UserAnalysisDashboard role="student" {...routeProps}/>} />
	  	 	)}  />
	  	 {/*ROUTES FOR REACT - USER ACCESS -- STARTS HERE*/}
	  	 <Route exact path='/login' 
	      	render={(routeProps) => (
	    		<LoginPage {...routeProps}/> //UserLoginPage deprecated
	  	 	)}  />
	  	 <Route exact path='/reset-password' 
	      	render={(routeProps) => (
	    		<ResetPassword {...routeProps}/> 
	  	 	)}  />		
	  	 <Route path='/home' 
	      	render={(routeProps) => (
	    		<UserLandingPage {...routeProps}/>
	  	 	)}  />
	  	 {/*<Route exact path='/register' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<UserRegistration {...routeProps}/>} />
	  	 	)}  />*/}
	  	 <Route exact path='/registration/testparticipant/upload/bulk' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<RegistrationLandingPage {...routeProps}/>} />
	  	 	)}  />
	  	 {/*ROUTES FOR REACT - USER ACCESS -- ENDS HERE*/}
	  	 {/*ROUTES FOR REACT - DATASET ACCESS -- STARTS HERE*/}
	  	 <Route exact path='/dataset/preview' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<DatasetMain {...routeProps}/>} />
	  	 	)}  />	
	  	 <Route exact path='/dataset/preview/sample' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true}  yieldComponent={<DatasetMain hideTestHeader={true} {...routeProps}/>} />
	  	 	)}  />
	  	 <Route exact path='/import_dataset' 
	      	render={(routeProps) => (
	      		<AppLayout {...routeProps} hideNavigator={true} yieldComponent={<ImportDataset {...routeProps}/>} />
	  	 	)}  />	
	  	 {/*ROUTES FOR REACT - DATASET ACCESS -- ENDS HERE*/}

	  	{/*ROUTES FOR REACT - METAREAD ACCESS -- STARTS HERE*/}
  		 <Route exact path='/metaread/beta' 
  	    	render={(routeProps) => (
  	    	<AppLayout {...routeProps} hideNavigator={false} hideAppBar={true} yieldComponent={<MetaReadHome {...routeProps}/>} />
  		 	)}  />
  		 <Route exact path='/doubts/beta' 
  	    	render={(routeProps) => (
  	    	<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<MetaReadDoubts {...routeProps}/>} />
  		 	)}  />
  		 <Route exact path='/diagrams/beta' 
  	    	render={(routeProps) => (
  	    	<AppLayout {...routeProps} hideNavigator={true} hideAppBar={true} yieldComponent={<MetaReadDiagrams {...routeProps}/>} />
  		 	)}  />
  		 <Route exact path='/metaread/store' 
  	    	render={(routeProps) => (
  	    	<AppLayout {...routeProps} hideNavigator={false} hideAppBar={true} yieldComponent={<MetaReadContentStore {...routeProps}/>} />
  		 	)}  />	
	  	{/*ROUTES FOR REACT - METAREAD ACCESS -- ENDS HERE*/}

    </div>
  </Router>
)
export default App;