import React from "react";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import QuestionTextInput from "components/test/view/QuestionTextInput.jsx";
import  Toolbar from 'components/testModel/TestModelToolbar'
import ClearIcon from "@material-ui/icons/Clear";


const getMuiThemeForTable = createMuiTheme({
	overrides: {
      	MuiTableCell: {
        	root: {
          		border: "1px solid rgba(224, 224, 224, 1)",
          		padding: "4px 24px 4px 24px"
        	},
        	footer: {
        		borderLeft: "none",
        		borderRight: "none",
        		textAlign: "right"
        	}
      	}
	}
	});


class MatrixGridListsComponent extends React.Component {

	constructor(props) {
	    super(props);
	  }

	render() {
		 const { classes,list1, list2, isEditMode,handleEditTestData,
			handlePasteEvent,handleImageFileUpload,handleRemoveImageComponent,
			handleAddListComponent,handleRemoveListComponent  } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={2} md={2} lg={2}>
				</GridItem>
				<GridItem xs={12} sm={8} md={8} lg={8}>
					<MuiThemeProvider theme={getMuiThemeForTable}>
					<Table >
				        <TableHead>
				          <TableRow>
				            <TableCell>List-I</TableCell>
				            <TableCell>List-II</TableCell>
				          </TableRow>
				        </TableHead>
				        <TableBody>
				          {list1 && list2 && list2.length >= list1.length && list2.map((row, index) => (
				            <TableRow key={index}>
				              <TableCell>
				              	{list1[index] && list1[index].map((listPart, index2) => (
				              	<React.Fragment>	
					              	{isEditMode ?
									<React.Fragment>
									  <QuestionTextInput text={listPart[0]} name={"list1_"+index+"_"+index2+"_0"}
									  rows="1"
									  isSingleComponent={true}
									  label={String.fromCharCode(65+index)} 
									  handleEditTestData={handleEditTestData}
									  handlePasteEvent={handlePasteEvent}
									  handleImageFileUpload={handleImageFileUpload}
									  />
									 
									{ 	listPart[1] &&
										<QuestionImages 
										src={listPart[1]} 
										label={String.fromCharCode(65+index)} 
										name={"list1_"+index+"_"+index2+"_1"}
										isEditMode={isEditMode}
										handleRemoveImageComponent={handleRemoveImageComponent} 
										/>
									}
									</React.Fragment>
									: null}
									{!isEditMode ?
										<React.Fragment>
											<span><strong>{String.fromCharCode(65+index) + ") "}</strong></span>				              	
							              	{listPart && listPart[0] ? 
											 <QuestionText text={listPart[0]}/>
											: null}
											{listPart && listPart[1] ?
												<QuestionImages 
												src={listPart[1]} 
												name={"list1_"+index+"_1"}
												isEditMode={false}
												/>
											: null
											}
										</React.Fragment>
									: null}
									</React.Fragment>
									))}
				              </TableCell>
				              <TableCell>
				              {list2[index] && list2[index].map((listPart, index2) => (
				              	<React.Fragment>
					              	{isEditMode ?
									<React.Fragment>
									  <QuestionTextInput text={listPart[0]} name={"list2_"+index+"_"+index2+"_0"}
									  rows="1"
									  isSingleComponent={true}
									  label={String.fromCharCode(80+index)} 
									  handleEditTestData={handleEditTestData}
									  handlePasteEvent={handlePasteEvent}
									  handleImageFileUpload={handleImageFileUpload}
									  />
									 
									{ 	listPart[1] &&
										<QuestionImages 
										src={listPart[1]} 
										label={String.fromCharCode(80+index)} 
										name={"list2_"+index+"_"+index2+"_1"}
										isEditMode={isEditMode}
										handleRemoveImageComponent={handleRemoveImageComponent} 
										/>
									}
									</React.Fragment>
									: null}

									{!isEditMode ?
										<React.Fragment>
											<span><strong>{String.fromCharCode(80+index) + ") "}</strong></span>
							              	{listPart && listPart[0] ? 
											 <QuestionText text={listPart[0]}/>
											: null}
											{listPart && listPart[1] ?
												<QuestionImages 
												src={listPart[1]} 
												name={"list2_"+index+"_1"}
												isEditMode={false}
												/>
											: null
											}
										</React.Fragment>
									: null}
									</React.Fragment>
									))}
				              </TableCell>
				            </TableRow>
				          ))}
				        </TableBody>
				       	{isEditMode &&
					        <TableFooter>
					          <TableRow>
					            <TableCell>
					            </TableCell>
					            <TableCell>
					         		<Toolbar title={"Add List"} name={"list1_add"} handleToolbarAction={handleAddListComponent}/>
					            	<Toolbar title={"Clear List"} name={"list1_clear"} iconComponent={<ClearIcon />} handleToolbarAction={handleRemoveListComponent}/>
					            </TableCell>
					          </TableRow>
					        </TableFooter>
				    	}
				      </Table>
				      </MuiThemeProvider>
				</GridItem> 
				<GridItem xs={12} sm={2} md={2} lg={2}>
				</GridItem>
			</GridContainer>
		)
	}
}

export default MatrixGridListsComponent;
