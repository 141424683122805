import React from "react";

/*import Button from "CustomButtons/Button.jsx";
import Card from "Card/Card.jsx";
import CardBody from "Card/CardBody.jsx";*/

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import QuestionImages from "components/test/QuestionImages.jsx";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import customCheckboxRadioSwitch from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import TextField from '@material-ui/core/TextField';
import {validateNumberFormatForUpto2DecimalNumbers,validateNumberFormatForIntegerNumbers} from "components/helpers/InputFormatHelper.jsx";


/*import test_img1 from "assets/img/image1.png";*/

const styles = theme => ({
	  container1: {
	    display: 'flex',
	    flexWrap: 'wrap',
	    paddingLeft: "10px"
	  },
	  textField1: {
	    marginLeft: theme.spacing.unit,
	    marginRight: theme.spacing.unit
	  },
	  dense: {
	    marginTop: 16,
	  },
	  menu: {
	    width: 200,
	  },
	  notchedOutline: {
	  	borderColor: "#3C4858 !important"
	  },
	});


class NumericalAnswerComponent extends React.Component {

	constructor(props) {
	    super(props);
	     this.state = {
		    numericalInputValue: ""
		  };
	  }

	componentDidMount(){
		var answer = this.props.userData["answer"];
		 if(!answer ){
		 	this.setState({
	      	numericalInputValue: ""
	    	});
		 	return;
		 }
		this.setState({
	      numericalInputValue: answer
	    });
	}

	//Have to rely on componentDidUpdate as by the time componentDidMount is called userData might be empty
	componentDidUpdate(prevProps){
		if(this.props.userData["answer"] == "" ){
		 	if(this.state.numericalInputValue)
		 	{	this.setState({ numericalInputValue: ""});
			}
		 	return
		 }
		if(this.props.userData["question_no"] != prevProps.userData["question_no"]){
			var answer = this.props.userData["answer"];
			 if(!answer ){
			 	this.setState({
		      	numericalInputValue: ""
		    	});
			 	return;
			 }
			this.setState({
		      numericalInputValue: answer
		    });
		}
	}

	handleNumericalInput = (e) => {
		e.preventDefault();
		var numericalInputValue = e.target.value;
		if(this.props.patternType == "NUMERICAL"){
			if(validateNumberFormatForUpto2DecimalNumbers((numericalInputValue)))
			{	
				/*if(numericalInputValue && numericalInputValue[numericalInputValue.length -1]!="."){
		           numericalInputValue = parseFloat(numericalInputValue).toString();
		        }*/
			 	 this.setState({
		       		numericalInputValue: numericalInputValue,
		     	});
		    	this.props.answerChanged(numericalInputValue);
			}
		}else if (this.props.patternType == "INTEGER"){
	        if(validateNumberFormatForIntegerNumbers((numericalInputValue))){
	           this.setState({
		       		numericalInputValue: numericalInputValue,
		     	});
		    	this.props.answerChanged(numericalInputValue);
	        }
	    }
	}

	updateKeypad(key){
		var numericalInputValue = ""
		if(key == 'ce'){
			numericalInputValue = ""
		}else if(key == 'del'){
			numericalInputValue = this.state.numericalInputValue.slice(0,-1);
		}else{
			numericalInputValue = this.state.numericalInputValue + key;
		}

	  	if(this.props.patternType == "NUMERICAL"){
	        if(validateNumberFormatForUpto2DecimalNumbers((numericalInputValue))){	
			 	 this.setState({
		       		numericalInputValue: numericalInputValue,
		     	});
		    	this.props.answerChanged(numericalInputValue);
			}
		} else if (this.props.patternType == "INTEGER"){
	        if(validateNumberFormatForIntegerNumbers((numericalInputValue))){
	           this.setState({
		       		numericalInputValue: numericalInputValue,
		     	});
		    	this.props.answerChanged(numericalInputValue);
	        }
		}
	}

	
	render() {
		 const { classes,options, answer, answerChanged,showSolutions, AnswerKey,patternType, isDisabled} = this.props;
		 const keyStyle = {
		 	backgroundColor: "#00800033"
		 }
		 const style3={
		fontFamily: "serif",
		fontSize: "1rem",
		backgroundColor: "#00800033",
		padding: "10px"
		};
		const style4={
		fontFamily: "serif",
		fontSize: "1rem",
		backgroundColor: "#ffc1073d",
		padding: "5px",
		marginLeft: "10px"
		};
		return (
			<GridContainer>
				<GridItem xs={12} sm={4} md={4} lg={4}>
					<div className={classes.container1} onsubmit="return false;" noValidate autoComplete="off">
					{	isDisabled &&
						<p style={style4}>Maximum Questions Attempted in this Section</p>}
					<TextField
			         id="NumericalAnswerValue"
			         //label={patternType == "INTEGER" ? (showSolutions ? "" : "Enter Numerical Answer") : ""}
			         className={classes.textField1}
			         margin="normal"
			         variant="outlined"
			         disabled={showSolutions || isDisabled} 
			         value={this.state.numericalInputValue}
			         //onChange={patternType == "INTEGER" ? this.handleNumericalInput : null}
			         helperText={patternType == "INTEGER" ? "Enter Integer digits only Eg: 2 by clicking numpad" : "Enter upto 2 decimals Eg: 22.65 by clicking numpad"}
			         InputProps={{
	                   classes: {
			            root: classes.cssOutlinedInput,
			            notchedOutline: classes.notchedOutline
			          },
			          //readOnly: patternType == "INTEGER" ? false : true,
			          readOnly: true,
			          style:  showSolutions && AnswerKey && AnswerKey == this.state.numericalInputValue ? keyStyle : {} 
	                 }}
			       />
			       { showSolutions && AnswerKey && AnswerKey != this.state.numericalInputValue ?
					<h5 style={style3}>Correct Numerical Answer: <strong>{AnswerKey}</strong> </h5>
			       : null}
			       </div>
			       {(patternType != "INTEGER") &&
			       <div style={{marginLeft: "30px", backgroundColor: "#eeeeee", width: "fit-content"}}>
						<table>
		                	<tbody>
			                   <tr>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled} onClick={() => this.updateKeypad(7)} type="button" value="7"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input  disabled={showSolutions || isDisabled} onClick={() => this.updateKeypad(8)} type="button" value="8"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad(9)} type="button" value="9"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad('del')} type="button" value="Del"/>
			                     </td>
			                   </tr>
			                   <tr>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad(4)} type="button" value="4"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad(5)} type="button" value="5"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad(6)} type="button" value="6"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad('ce')} type="button" value="Clear"/>
			                     </td>
			                   </tr>
			                   <tr>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad(1)} type="button" value="1"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad(2)} type="button" value="2"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad(3)} type="button" value="3"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad('-')} type="button" value="-"/>
			                     </td>
			                   </tr>
			                   <tr>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad('.')} type="button" value="."/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input disabled={showSolutions || isDisabled}  onClick={() => this.updateKeypad(0)} type="button" value="0"/>
			                     </td>
			                   </tr>
			                </tbody>
			            </table>
			        </div>
					}
					{(patternType == "INTEGER") &&
			       <div style={{marginLeft: "30px", backgroundColor: "#eeeeee", width: "fit-content"}}>
						<table>
		                	<tbody>
			                   <tr>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(7)} type="button" value="7"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(8)} type="button" value="8"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(9)} type="button" value="9"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad('del')} type="button" value="Del"/>
			                     </td>
			                   </tr>
			                   <tr>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(4)} type="button" value="4"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(5)} type="button" value="5"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(6)} type="button" value="6"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad('ce')} type="button" value="Clear"/>
			                     </td>
			                   </tr>
			                   <tr>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(1)} type="button" value="1"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(2)} type="button" value="2"/>
			                     </td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(3)} type="button" value="3"/>
			                     </td>
			                   </tr>
			                   <tr>
			                   	<td style={{padding: "2px"}}>
			                   	</td>
			                     <td style={{padding: "2px"}}>
			                       <input   onClick={() => this.updateKeypad(0)} type="button" value="0"/>
			                     </td>
			                   </tr>
			                </tbody>
			            </table>
			        </div>
					}
				</GridItem> 
			</GridContainer>
		);
	}
}

export default withStyles(styles)(NumericalAnswerComponent);
