import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";


export default class QuestionTimeTakenText extends React.Component {

	render() {
		const { timeTakenInSeconds } = this.props;
		const style3={
		fontFamily: "serif",
		fontSize: "1rem"
		};
		return(
			<div>
			<GridContainer style={{margin: "auto"}}>
				<GridItem xs={12} sm={12} md={12} lg={12} >
				{ timeTakenInSeconds && (parseInt(timeTakenInSeconds) < 60) ?
					<h5 style={style3}>Time Spent on Question: <strong>{timeTakenInSeconds}s</strong> </h5>
				: timeTakenInSeconds ?
					<h5 style={style3}>Time Spent on Question: <strong>{Math.floor(timeTakenInSeconds/60)}m {timeTakenInSeconds - Math.floor(timeTakenInSeconds/60)*60}s</strong> </h5>
				: <h5 style={style3}>Time Spent on Question: <strong>0s</strong> </h5>
				 }
				</GridItem> 
			</GridContainer>	
			</div>
		);
	}

}