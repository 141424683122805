import { container, title, drawerWidth, boxShadow, transition } from "assets/jss/material-kit-react.jsx";
import TextStyle from "styles/TextStyle.jsx";

const userLandingPageStyle = {
containerCustom: {
  width: "100%",
  zIndex: "12",
  //backgroundColor: "rgba(73, 123, 52, 0.1)",
  //backgroundColor: "rgba(93, 134, 155, 0.7)",
  backgroundColor: "#eeeeee",
  paddingLeft: drawerWidth,
  minHeight: "93vh",
  height: "auto"
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px", 
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  drawerPaper: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    //...boxShadow,
    position: "fixed",
    display: "block",
    top: "60px",
    //height: "100vh",
    //maxHeight: "1200px",
    right: "auto",
    left: "0",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition
  },
  navLink:{
    width: "80%",
    display: "table-cell",
    margin: "auto",
    borderRadius: "5px",
    padding: "10px 20px",
    backgroundColor: "#eeeeee",
    "&:hover,&:focus": {
      color: "white",
      backgroundColor: "rgb(43, 78, 88, 0.6)"
    },
    fontWeight: "100",
    fontSize: "0.8rem",
    fontFamily: "'Noto Serif', serif",
    marginBottom: "5px",
    textTransform: "none"
  },
  navLinkActive: {
    width: "80%",
    display: "table-cell",
    margin: "auto",
    padding: "10px 20px",
    borderRadius: "5px",
    color: "white",
    "&:hover,&:focus": {
      color: "white",
      backgroundColor: "#57849b"
      /*backgroundColor: "rgb(43, 78, 88, 0.6)"*/
    },
    backgroundColor: "#57849b",
    fontWeight: "100",
    fontSize: "0.8rem",
    fontFamily: "'Noto Serif', serif",
    marginBottom: "5px",
    textTransform: "none"
  },
  list:{
    padding: "0px"
  },
  ...TextStyle
};

export default userLandingPageStyle;
