import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

/*import test_img1 from "assets/img/image1.png";*/

const style = {
  ...imagesStyle
};

 class QuestionImages extends React.Component {
/*
	constructor(props) {
	    super(props);
	  }
*/
render() {
	const { classes, src, label, content, imgWidth, maxWidth, backgroundColor } = this.props;
	const imageClasses = classNames(
      //classes.imgRaised,
      //classes.imgRoundedCircle,
      classes.imgFluid,
      classes.imgCardTop
    );
	return(
		<div>
		{ label && content ?
			<p>{label +") " +content}</p>
			: null
		}
			<img
			  style={{padding: "20px", height: "auto", maxHeight: "500px", width: imgWidth, maxWidth: maxWidth, display: "block", backgroundColor: backgroundColor}}
			  className={imageClasses}
			  src={getBaseURL()+src}
			  alt="Card-img-cap"
			/>
			<br />
		</div>
		);
}
}

QuestionImages.defaultProps = {
  imgWidth: "auto",
  maxWidth: "100%",
  backgroundColor: "white"
}

export default withStyles(style)(QuestionImages);