

export function isUserSessionActive() {
	
	var sessionActive = false
	if((localStorage.length != 0 && localStorage.user && (localStorage.user != null || localStorage.user != ''))){
			sessionActive = true
	}
	// return sessionActive
	//changing the authentication to rails server side
	return true
}