import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {}
};

class TestModelToolbar extends React.Component {

  render() {
    const { classes, handleToolbarAction, title,name, iconComponent } = this.props;
    const tooltipTextStyle = {
      fontSize: "15px",
      margin: "0px"
    };
    return (
      <React.Fragment>
        <Tooltip title={title ? title : "Add Section"}>
          <IconButton name={name ? name : ""} style={{paddingTop: "5px", paddingBottom: "5px",
          borderRadius: "5%"
        }}  onClick={handleToolbarAction}>
            {iconComponent ? iconComponent : <AddIcon /> } 
            <p style={tooltipTextStyle}>{title ? title : "Add Section"}</p>
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default  TestModelToolbar;
