import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import TestModelToolbar from './TestModelToolbar'

const defaultFooterStyles = {
};

class TestModelFooter extends React.Component {

  render() {
    const { classes, handleAddSection, title } = this.props;

    return (
    <TableFooter>
      <TableRow>
        <TableCell>
           <TestModelToolbar title={title} handleToolbarAction={handleAddSection}/>
        </TableCell>
      </TableRow>
    </TableFooter>
    );
  }

}

export default TestModelFooter;