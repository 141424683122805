import React from "react";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import {validateNumberFormatForUpto2DecimalNumbers,validateNumberFormatForIntegerNumbers} from "components/helpers/InputFormatHelper.jsx";


/*import test_img1 from "assets/img/image1.png";*/

const styles = theme => ({
	  container1: {
	    display: 'flex',
	    flexWrap: 'wrap',
	    paddingLeft: "10px"
	  },
	  textField1: {
	    marginLeft: theme.spacing.unit,
	    marginRight: theme.spacing.unit
	  },
	  dense: {
	    marginTop: 16,
	  },
	  menu: {
	    width: 200,
	  },
	  notchedOutline: {
	  	borderColor: "#3C4858 !important"
	  },
	});


class NumericalAnswerComponent extends React.Component {

	constructor(props) {
	    super(props);
	     this.state = {
		    numericalInputValue: ""
		  };
	  }

	handleNumericalInput = (e) => {
		e.preventDefault();
		var numericalInputValue = e.target.value;
		if(this.props.patternType == "NUMERICAL"){
			if(validateNumberFormatForUpto2DecimalNumbers((numericalInputValue)))
			{	
				/*if(numericalInputValue && numericalInputValue[numericalInputValue.length -1]!="."){
		           numericalInputValue = parseFloat(numericalInputValue).toString();
		        }*/
			 	 this.setState({
		       		numericalInputValue: numericalInputValue,
		     	});
		    	this.props.answerChanged(numericalInputValue);
			}
		}else if (this.props.patternType == "INTEGER"){
	        if(validateNumberFormatForIntegerNumbers((numericalInputValue))){
	           this.setState({
		       		numericalInputValue: numericalInputValue,
		     	});
		    	this.props.answerChanged(numericalInputValue);
	        }
	    }
	}
	
	render() {
		 const { classes,options, answer, answerChanged, AnswerKey, patternType} = this.props;
		 const keyStyle = {
		 	backgroundColor: "#00800033"
		 }
		 const showSolutions = true;
		 const style3={
		fontFamily: "serif",
		fontSize: "1rem",
		backgroundColor: "#00800033",
		padding: "10px"
		};
		return (
			<GridContainer>
				<GridItem xs={12} sm={4} md={4} lg={4}>
					<div className={classes.container1} noValidate autoComplete="off">
					<TextField
			         id="NumericalAnswerValue"
			         label={showSolutions ? "" :"Enter Numerical Answer"}
			         className={classes.textField1}
			         margin="normal"
			         variant="outlined"
			         disabled={showSolutions} 
			         
			         value={this.state.numericalInputValue}
			         onChange={this.handleNumericalInput}
			         helperText={patternType == "INTEGER"  ? "Enter Integer digits only Eg: 6" : "Enter upto 2 decimal digits Eg: 22.65"}
			         InputProps={{
	                   classes: {
			            root: classes.cssOutlinedInput,
			            notchedOutline: classes.notchedOutline
			          },
			          style:  showSolutions && AnswerKey && AnswerKey == this.state.numericalInputValue ? keyStyle : {} 
	                 }}
			       />
			       { showSolutions && AnswerKey && AnswerKey != this.state.numericalInputValue ?
					<h5 style={style3}>Correct Numerical Answer: <strong>{AnswerKey}</strong> </h5>
			       : null}
			       </div>
				</GridItem> 
			</GridContainer>
		);
	}
}

export default withStyles(styles)(NumericalAnswerComponent);
