import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import ErrorLogo from "@material-ui/icons/Error";
import testModelFormStyle from "components/styles/TestModelFormStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import Button from "CustomButtons/Button.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import $ from 'jquery';
import qs from 'query-string';

class ImportDataset extends React.Component {

  state = {
    errorMessages:[],
    testInfo: "",
    uploadedFileName: "",
    uploadedFile: "",
    testId: "",
    uploadStatus: "",
    errorsReported: ""
  }

  handleFileInput = (e) => {
    e.preventDefault();
    var uploadedFileName = e.currentTarget.files[0].name;
    var uploadedFile = e.currentTarget.files[0];
    this.setState({uploadedFileName,uploadedFile});
  }

  handleValidateAndImportFile = (e) => {
   e.preventDefault();
    var formData = new FormData();
    formData.append('offline[doc]', this.state.uploadedFile);
    var url =  e.currentTarget.name;
    this.setState({uploadStatus: "Processing",errorsReported: "Processing"});
    $.ajax({
          type: 'POST',
          url: url,
          data: formData,
          cache: false,
          contentType: false,
          processData: false
        }).done((data, status, jqXHR)=>{
          this.setState({
            uploadStatus: 'DONE',
            errorsReported: data["message"]
          });
        }).fail((jqXHR, textStatus, errorThrown) => {
          this.setState({
            uploadStatus: 'FAILED',
            errorsReported: jqXHR["responseJSON"]["message"]
          });
        })
  }


  render () {
    const {classes} = this.props;
    const {testInfo,uploadedFileName,uploadStatus,errorsReported} = this.state;
    const titleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "20px",
      paddingLeft: "20px",
    };
    const subtitleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "20px"
    };
    const subtitleStyle2={
      color: "rgb(34, 56, 69)",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "20px",
      textAlign: "center"
    };
    const smallButtonStyle = {
          textTransform: "none",
          backgroundColor: "#3f51b5",
          color: "white",
          borderRadius: "3px",
          padding: "0.3rem 0.75rem",
          "&:hover,&:focus": {
            color: "white",
            background: "#57849b",
          },
      }
      const paraStyle1={
        whiteSpace: "nowrap", 
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "14px", 
        color: "rgb(34, 56, 69)"
      }
    return (
      <React.Fragment>
      <GridContainer style={{
          backgroundColor: "white",
          paddingTop: "0px",
          paddingBottom: "20px",
          width: "100%", 
          margin: "10px 0px 0px",
          marginTop: "0px"}}
          spacing={24}>
          <GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            <h1 style={titleStyle} >Import Dataset</h1>
            <hr/>
          </GridItem>
        {this.state.errorMessages && this.state.errorMessages.length > 0 &&
        <React.Fragment>
          <GridItem  xs={12} sm={12} md={2} style={{paddingTop: "0px", paddingBottom: "0px"}}>
            </GridItem>
          <GridItem xs={12} sm={12} md={10} style={{paddingTop: "0px", paddingBottom: "0px"}}>
          {this.state.errorMessages && this.state.errorMessages.length > 0 ? this.state.errorMessages.map((errorMessage, index) => 
                  <div key={index}>
                  <ErrorLogo className={classes.icon} style={{verticalAlign: "middle", marginRight: "5px", color: "#f44336"}}></ErrorLogo>
                  <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit"}} className={classes.robotoFontRegular}>{errorMessage["message"]}</p> 
                  </div>
                  ): null
                }
              </GridItem>
          </React.Fragment>
          }
          <React.Fragment>
            <GridItem  xs={12} sm={12} md={11}  style={{paddingTop: "0px", paddingBottom: "0px", paddingLeft: "40px"}}>
              <div>
              <GridContainer spacing={8}>
                <React.Fragment>
                  <GridItem  xs={12} sm={12} md={12}  style={{paddingTop: "0px", paddingBottom: "0px"}}>
                    <h3 style={subtitleStyle} >Upload Dataset File</h3>
                  </GridItem>
                  <GridItem  xs={12} sm={12} md={7}>
                    <input
                      accept=".docx"
                      className={classes.input}
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={this.handleFileInput}
                    />
                    <label htmlFor="raised-button-file">
                      <Button component="span" style={{
                        textTransform: "none",
                        backgroundColor: "#607D8B",
                        padding: "8px 20px",
                        margin: "0px"
                      }}>
                        Upload
                      </Button>
                    </label> 
                    {uploadedFileName &&
                    <p style={{fontSize: "12px", color: "rgb(34, 56, 69)"}} >{uploadedFileName}</p>
                    }
                  {/*</GridItem>
                  <GridItem  xs={12} sm={12} md={8} >*/}
                  </GridItem>
                  <GridItem  xs={12} sm={12} md={5} >
                    <List className={classes.list} style={{padding: "0px"}}>
                      <ListItem className={classes.listItem} style={{paddingTop: "0px"}} >
                        <Button
                          name="dataset-upload-import"
                          color="transparent"
                          onClick={this.handleValidateAndImportFile}
                          className={classes.navLink}
                          style={{ 
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          paddingLeft: "20px",
                          paddingRight: "20px"}}
                          size="sm"
                        >
                           Import Test
                        </Button>
                      </ListItem>
                      <ListItem className={classes.listItem} style={{paddingTop: "0px"}}>
                        <Button
                          name="dataset-upload-validate"
                          onClick={this.handleValidateAndImportFile}
                          color="transparent"
                          style={{ 
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          paddingLeft: "20px",
                          paddingRight: "20px"}}
                          className={classes.navLink}
                          size="sm"
                        >
                           Validate File
                        </Button>
                      </ListItem>
                    </List>
                  </GridItem>
                  <GridItem  xs={12} sm={12} md={12} >
                    <div style={{paddingTop: "20px"}}/>
                    <InputLabel shrink htmlFor="material">
                        Upload Status
                    </InputLabel>
                    <p style={uploadStatus && uploadStatus == "FAILED" ? {fontSize: "16px", color: "#F44336"} :
                              uploadStatus && uploadStatus == "SUCCESS" ?  {fontSize: "16px", color: "#4CAF50"} :
                              {fontSize: "16px", color: "rgb(34, 56, 69)"}}>
                    {uploadStatus ? uploadStatus : "No Validation Performed"}</p>
                    <div style={{paddingTop: "10px"}}/>
                    <InputLabel shrink htmlFor="material">
                        Errors Reported
                    </InputLabel>
                    <div style={{paddingTop: "2px"}}/>
                    <p style={{fontSize: "16px", color: "rgb(34, 56, 69)", whiteSpace: "pre-line"}} >{errorsReported ? errorsReported: "No Validation Performed"}</p>
                  </GridItem>
                </React.Fragment>
              </GridContainer>
              </div>
            </GridItem>
          </React.Fragment>
    </GridContainer>
         
      </React.Fragment>
    );
  }
}

export default withStyles(testModelFormStyle)(ImportDataset);
