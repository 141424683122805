import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class DatasetQuestionLevels extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      levels: [],
    };
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  componentDidMount(){
    const topicId = this.props.topicId;
    if(topicId){
      $.ajax({
        type: 'GET',
        url: getBaseURL() + '/get-topic-levels.json?topic_id='+ topicId+'&source=dataset'
      }).done((response, status, jqXHR)=>{
          this.setState({
            levels: response
          });

      });
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.topicId != prevProps.topicId){
      const topicId = this.props.topicId;
      if(topicId){
        $.ajax({
          type: 'GET',
          url: getBaseURL() + '/get-topic-levels.json?topic_id='+ topicId+'&source=dataset'
        }).done((response, status, jqXHR)=>{
            this.setState({
              levels: response
            });

        });
      }
    }
  }

  getIdForLOV(value){
    const levels = this.state.levels;
    const filteredLevels = levels.filter(level => level.name == value);
    if (filteredLevels && filteredLevels.length > 0){
     return {id: filteredLevels[0].id};
    }
    return;
  }


  render() {
    const { name, value, index, change,formControlWidth, includeId, topicId } = this.props;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select value={value} name={name} onChange={includeId ? (e) => change(e,this.getIdForLOV(e.target.value)) :change} 
          style={{fontSize: 'inherit'}}>
          { this.state.levels.map((level, index) =>
            <MenuItem key={index} style={{fontSize: "14px"}} value={level.name}>{level.name}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

}


export default DatasetQuestionLevels;