import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Button from "CustomButtons/Button.jsx";

import Paper from '@material-ui/core/Paper';
//import Typography from '@material-ui/core/Typography';

import withStyles from "@material-ui/core/styles/withStyles";
import myHomeRegionStyle from "styles/MyHomeRegionStyle";

import ScrollMenu from 'react-horizontal-scrolling-menu';
import classNames from "classnames";

import exam_status_unattempted_icon from "assets/img/newicons/exam_status_unattempted.svg";
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import MUIDataTable from "mui-datatables";

import $ from 'jquery';
import qs from 'query-string';

import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";
import {formatDate, formatDuration, isDateOld, formatDateWithoutTime, formatDateWithoutTimeAndYear, offsetISTTimezone} from "components/helpers/DateTimeHelper.jsx";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";


class MyHomeRegion extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			testMarksData: [],
			nextUpcomingTest: "",
			upcomingTestTimerProgress: 0,
			upcomingTestTimerText: "",
			testSeriesList: [],
			recentTestsData: [],
			selectedTestTypeForQuickInsights: "",
			quickInsightsData:[]
		};
		this.upcomingTestTimer = null;
	}



	componentDidMount(){
		if(!isUserSessionActive())
		{
		 	this.props.history.push('/login');
		 	return
		}
		$.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-immediate-upcoming-tests.json',
          dataType: "JSON",
          //headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	var nextUpcomingTest = data["tests"] != null && data["tests"].length > 0 ? data["tests"][0] : ""
        	this.setState({nextUpcomingTest: nextUpcomingTest})
        	this.upcomingTestTimer = setInterval(function() {
			this.upcomingDateTimer(offsetISTTimezone(nextUpcomingTest.start_time), this.upcomingTestTimer)
			}.bind(this), 1000);
        });  
		//TODO error handling

		$.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-test-types.json',
          dataType: "JSON",
          //headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	this.setState({testSeriesList: data});
        
        	var defaultTestTypeId = null;
        	if (data && data["test_types"] && data["test_types"].length > 0){
        			defaultTestTypeId = data["test_types"][0].id 
        			if(defaultTestTypeId){
        				this.setState({ selectedTestTypeForQuickInsights: defaultTestTypeId.toString() });
        				this.handleQuickInsightsData(defaultTestTypeId);
        			}
        	}
        	
        	
        });  
		//TODO error handling

		$.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-attempted-tests-results?limit=5',
          dataType: "JSON",
          //headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	this.setState({recentTestsData: data});
        });  
	}

	handleQuickInsightsData(testTypeId){
		
		$.ajax({
          type: "GET",
          url: getBaseURL() + '/get-user-attempted-tests-results?test_type_id='+ testTypeId.toString() +'&limit=10',
          dataType: "JSON",
          //headers: JSON.parse(localStorage.user)
        }).done((data) => {
        	if(data && data["tests"] && data["tests"].length > 0){
				var quickInsightsData = [];
				var quickInsightsJsonData = {};
				var lineChartData = []
				var testData = {}
				var totalTestsCount = data["tests"].length;
				for(var index in data["tests"]){
					testData = data["tests"][totalTestsCount-index-1]
					lineChartData.push({category: formatDateWithoutTimeAndYear(testData.last_attempted_date), value: testData.awarded_marks});
				}
				
				quickInsightsJsonData["data"] = lineChartData;
				quickInsightsJsonData["name"] = data["tests"][0].test_type_name + " Marks"
				quickInsightsData.push(quickInsightsJsonData);
	        	this.setState({quickInsightsData: quickInsightsData});
        	} else{
        		this.setState({quickInsightsData: []});
        	}//TODO else part error handling on ELSE and AJAX Failure
        
        });  
	}

	
	upcomingDateTimer( startDate, timer) {
	  	var now = new Date();
	  	var startDate = new Date(startDate);
	  	var difference = startDate.getTime() - now.getTime();

	  	if (difference <= 0) {

		    // Timer done
		    clearInterval(timer);
		    this.setState({upcomingTestTimerText: "", upcomingTestTimerProgress: 100});

	  	} else {
	    
		    var seconds = Math.floor(difference / 1000);
		    var minutes = Math.floor(seconds / 60);
		    var hours = Math.floor(minutes / 60);
		    var days = Math.floor(hours / 24);

		    hours %= 24;
		    minutes %= 60;
		    seconds %= 60;

		    //Progress Bar related percentages
		    var daysDifferenceForProgressBar = 5;
		  	var progressBarStartDate = new Date(startDate);
		  	progressBarStartDate.setDate(startDate.getDate() - daysDifferenceForProgressBar);
		  	var progressBarCurrentDifference = now.getTime() - progressBarStartDate.getTime()
		  	var progressBarInitialDifference = startDate.getTime() - progressBarStartDate.getTime()
		  	var percentProgress = 0
		  	if(progressBarCurrentDifference <= 0 ){
		  		percentProgress = 0
		  	}
		  	else{
		  		percentProgress =  (progressBarCurrentDifference/progressBarInitialDifference)*100
		  		if(percentProgress >= 100){
		  			percentProgress = 100
		  		}
	  		}
	  	 
	    	var currentTimeString = days + "d " + hours + "h "+ minutes + "m " + seconds + "s "
			this.setState({upcomingTestTimerText: currentTimeString, upcomingTestTimerProgress: percentProgress})
		}
	   
	}

	// populateTestMarksData(data){
	// 	var subjMarksData = [];
	// 		var subjectsInfo =  (data.total_results.subjects_info);
	// 		//data.total_results.
	// 		var subjectData = ""
	// 		var totalStudents = data.total_results.no_of_students
	// 		for (var subj in subjectsInfo){
	// 			subjectData = data.total_results.subjects_info[subj]
	// 			var subjTotalMarks = data.total_results.sub_template_data[subj].sub_total_marks	
	// 			subjMarksData.push([subj, subjectData["marks"] +"/"+ subjTotalMarks,
	// 								this.calcPercentage(parseFloat(subjectData["marks"]),
	// 								parseFloat(subjTotalMarks)),subjectData["rank"] + "/" + totalStudents ,
	// 								this.calcPercentile(parseInt(subjectData["rank"]),parseInt(totalStudents))]);
	// 		}
	// 		subjMarksData.push(["OVERALL", data.total_results.total_marks +"/"+ data.total_results.test_marks,
	// 								this.calcPercentage(parseFloat(data.total_results.total_marks),
	// 								parseFloat(data.total_results.test_marks)),data.total_results.rank + "/" + totalStudents,
	// 								this.calcPercentile(parseInt(data.total_results.rank),parseInt(totalStudents))]);
	// 		this.setState({testMarksData: subjMarksData});
	// }

	listTabs = (recentTestsData) => recentTestsData && recentTestsData["tests"] && 
				recentTestsData["tests"].length > 0 && recentTestsData["tests"].map(el => {
	  const { name, id,total_papers, test_type_name, last_attempted_date, awarded_marks, rank } = el;
	  const { classes} = this.props;

	  return (
	  	<div key={id} className={classes.boxedContainer}>
		  	<GridContainer  className={classes.clipStyle} style={{marginTop: "10px", marginLeft: "10px"}}>
		  		{/*<GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
		  			<h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >{sub}</h1>
		  		</GridItem>*/}
		  		<GridItem xs={12} sm={12} md={7} >
      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular)}><strong>{name}</strong></h1>
      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>{test_type_name}</h1>
      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>{formatDateWithoutTime(last_attempted_date)}</h1>
      			</GridItem>
      			<GridItem xs={12} sm={12} md={5} >
      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular)}>Total Papers: <strong>{total_papers}</strong></h1>
      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Marks: <strong>{awarded_marks}</strong></h1>
      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Rank: <strong>{rank}</strong></h1>      			
      			</GridItem>
      			<GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
      				<Button
      				  id={id}
      				  onClick={this.goToRecentTest}
			          className={classNames(classes.robotoFontRegular)}
			          style={{
			          //width: "100%",
			          marginTop: "10px",
			          marginBottom: "0px",
			          backgroundColor: "#57849b", 
			          padding: "6px 16px",
			          textTransform: "none",
			          fontSize: "0.8rem"
			      	  }} 
			          >
	                   View Test
	                  </Button> 
	            </GridItem>
      			{/*<GridItem xs={12} sm={12} md={12} className={classes.clipBodyStyle}>
		  			<h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Start Test</h1>
      			</GridItem>*/}
			</GridContainer>
		</div> );
	});

	Arrow = ({ text, className }) => {
	  return (
	    <div
	      className={className}
	    >{text}</div>
	  );
	};

 	goToTest = (e) =>{
 		const test_number = 50;  //TODO HardCoded Values
  		this.props.history.push('/home/test/instructions?test_id=' + test_number);
  	}

  	goToRecentTest=(e) => {
  		e.preventDefault();
  		this.props.history.push('/home/enterprise/testHome?test_id=' + e.currentTarget.id);
  	}

  	goToUserSubscriptions = (e) =>{
  		this.props.history.push('/home/subscriptions');
  	}

  	goToUserTests = (e) =>{
  		this.props.history.push('/home/enterprise/mytests');
  	};

  	gotoUpcomingTest =(e)=>{
  		e.preventDefault();
	    this.props.history.push('/home/enterprise/testHome?test_id=' + e.currentTarget.id);
  	}

  	gotoTestSeries = (e) => {
  		e.preventDefault();
  		var seriesData = (e.currentTarget.id).split('_')
	    this.props.history.push('/home/enterprise/mytests?test_type_id='+seriesData[0]+'&index='+seriesData[1]);
  	}

  	 
  	 handleInsightTestTypeChange = (e) => {
  	 	var testTypeId = e.target.value;
    	this.setState({ selectedTestTypeForQuickInsights: testTypeId });

    	this.handleQuickInsightsData(testTypeId);
  	}	

	render() {
		const { classes} = this.props;
		var {nextUpcomingTest,
			upcomingTestTimerProgress,
			upcomingTestTimerText,
			testSeriesList,recentTestsData,
			quickInsightsData
		} = this.state;
		const listTabs = this.listTabs(recentTestsData);
		const ArrowLeft = this.Arrow({ text: '<', className: 'arrow-prev' });
		const ArrowRight = this.Arrow({ text: '>', className: 'arrow-next' });
		const testMarksColumns = [
			"Subjects",
			"Marks",
			"Percentage",
			"Rank",
			"Percentile"
			];
		const options = {
			filter: false,
			selectableRows: 'none',
			search: false,
			print: false,
			download: false,
			viewColumns: false,
			pagination: false,
			responsive: 'stacked',
			setRowProps: (row, rowIndex) => {
		        if(row[0] == "OVERALL"){
			        return {
			          style: {textAlign: "center", backgroundColor: "#f4f5f7"}
			        };
		        } else{
		        	return {style: {textAlign: "center"}};
		        }
		      },
		}
		return (
			<div>	
			  	<GridContainer style={{margin: "auto", marginTop: "20px"}} justify="center" alignItems="center" direction="row">
			 		{ nextUpcomingTest ? 
			 		<GridItem xs={12} sm={12} md={12} className={classes.containerHeaderSticker}>
				 				<h4 style={{fontSize: "0.9rem", margin: "5px", color: "white", transform: "skew(-15deg)"}} className={classNames(classes.robotoFontRegular)}><strong>Up Next</strong></h4>			 			
			 		</GridItem>
			 		: null}
			 		{ nextUpcomingTest ? 
			 		<GridItem xs={12} sm={12} md={12} className={classes.upcomingTestContainer}>
			 			<GridContainer style={{paddingTop: "15px", paddingBottom: "5px"}}>
			 				<GridItem xs={12} sm={12} md={1} style={{textAlign: "center", margin: "auto",width: "fit-content"}}>
				 				<img
			      				    className={
			      				      classes.imgRounded +
			      				      " " +
			      				      classes.imgFluid
			      				    }
			      				    style={{maxHeight: "50px"}}
			      				    src={exam_status_unattempted_icon}
			      				   alt=""
			      				  />
				 			</GridItem>
				 			<GridItem xs={12} sm={12} md={2} style={{padding: "0px", margin: "auto",width: "fit-content"}}>
				 				<h4 style={{marginBottom: "2px"}} className={classNames(classes.robotoFontRegular)}><strong>{nextUpcomingTest.name}</strong></h4>
				 				<h4 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>{nextUpcomingTest.test_type_name}</h4>
				 			</GridItem>
				 			<GridItem xs={12} sm={12} md={3} style={{padding: "0px", margin: "auto",width: "fit-content"}}>
				 				<h4 style={{fontSize: "0.8rem",marginBottom: "0px"}} className={classNames(classes.robotoFontRegular)}>Start Time: <strong>{formatDate(nextUpcomingTest.start_time)}</strong></h4>
				 				<h4 style={{fontSize: "0.8rem",marginTop: "5px"}} className={classNames(classes.robotoFontRegular)}>Total Papers: <strong>{nextUpcomingTest.total_papers}</strong></h4>
				 			</GridItem>
				 			{/*<GridItem xs={12} sm={12} md={2} style={{padding: "0px", margin: "auto"}}>
				 				<h4 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Identifier: <strong>{nextUpcomingTest.identifier}</strong></h4>
				 			</GridItem>*/}
				 			<GridItem xs={12} sm={12} md={4} style={{margin: "16px"}}>
				 				{ upcomingTestTimerText ? 
				 				<h4 style={{marginBottom: "2px"}} 
				 				className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Live in <strong>{upcomingTestTimerText}</strong></h4>
				 				: <h4 style={{marginBottom: "2px"}} 
				 				className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Live Now</h4>
				 				}
				 				<LinearProgress variant="determinate" value={upcomingTestTimerProgress}
				 				style={{height: "10px", borderRadius: "15px"}}
				 				color={upcomingTestTimerProgress == 100 ? "secondary": "primary"}/>
				 			</GridItem>
				 			{/*<GridItem xs={12} sm={12} md={1} style={{textAlign: "center", margin: "auto"}}>
				 				
				 			</GridItem>*/}
				 			<GridItem xs={12} sm={12} md={2} style={{textAlign: "center", margin: "auto"}}>
			       				<Button
			       				  id={nextUpcomingTest.id}
						          color="transparent"
						          style={{textTransform: "none", display: "block", padding: "0px", margin: "auto"}}
						          onClick={this.gotoUpcomingTest}
						          >
				 				<i className="material-icons" style={{paddingTop:"0px", fontSize: "30px"}}>
									launch
								</i>
				 				<h4 style={{fontSize: "0.8rem", margin: "0px", marginBottom: "10px"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Go to Test</h4>
				 				</Button>
				 			</GridItem>
				 		</GridContainer>
			 		</GridItem>
			 		: null}
			 		{testSeriesList && testSeriesList["test_types"] &&  testSeriesList["test_types"].length > 0 ?
			 		<GridItem xs={12} sm={12} md={12} className={classes.containerHeaderSticker} style={{marginTop: "0px", marginBottom: "-15px"}}>
				 				<h4 style={{fontSize: "0.9rem", margin: "5px", color: "white", transform: "skew(-15deg)"}} className={classNames(classes.robotoFontRegular)}><strong>Test Series</strong></h4>			 			
			 		</GridItem>
			 		: null}
			 		{testSeriesList && testSeriesList["test_types"] &&  testSeriesList["test_types"].length > 0 ?
			 		<GridItem xs={12} sm={12} md={12} className={classes.programTestContainer}>
			 			<GridContainer style={{paddingTop: "20px", paddingBottom: "10px"}}>
			 				{testSeriesList && testSeriesList["test_types"] &&  testSeriesList["test_types"].length > 0 ? testSeriesList["test_types"].map((testType, index) => (
				 				<GridItem xs={12} sm={12} md={4} style={{textAlign: "center", padding: "20px"}} className={index!=(testSeriesList["test_types"].length-1) && index < 3 ? classes.borderRight : index >= 3 ? classNames(classes.borderRight, classes.marginTop20) :null}>
 				       				<Button
 				       				  id={testType.id+"_"+index}
 							          color="transparent"
 							          style={{width: "100%", textTransform: "none", display: "block", padding: "0px", margin: "auto"}}
 							          onClick={this.gotoTestSeries}
 							          >
						 				<img
					      				    className={
					      				      classes.imgRounded +
					      				      " " +
					      				      classes.imgFluid
					      				    }
					      				    style={{maxHeight: "80px"}}
					      				    src={exam_status_unattempted_icon}
					      				   alt=""
					      				  />
						 				<h4 style={{fontSize: "1rem", marginBottom: "2px"}} className={classNames(classes.robotoFontBold)}>{testType.name}</h4>
						 				<h4 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular,classes.zeroMarginTop)}>{testType.total_tests_count} Tests</h4>
					 				</Button>
					 			</GridItem>
					 		)) : null}
				 		</GridContainer>
			 		</GridItem>
			 		:null}
			 		{recentTestsData && recentTestsData["tests"] &&  recentTestsData["tests"].length > 0 ?
			 		<GridItem xs={12} sm={12} md={12} className={classes.containerHeaderSticker} style={{marginTop: "20px", marginBottom: "-55px"}}>
				 				<h4 style={{fontSize: "0.9rem", margin: "5px", color: "white", transform: "skew(-15deg)"}} className={classNames(classes.robotoFontRegular)}><strong>Recent Tests</strong></h4>			 			
			 		</GridItem>
			 		: null}
			 		{recentTestsData && recentTestsData["tests"] &&  recentTestsData["tests"].length > 0 ?
			 		<GridItem xs={12} sm={12} md={12} style={{margin: "auto"}}>
			 			<GridContainer style={{paddingTop: "20px", paddingBottom: "0px", paddingLeft: "10px"}}>
				 			<GridItem xs={12} sm={12} md={12} style={{padding: "0px", paddingTop: "10px"}}>
					 			<ScrollMenu
			 				          data={listTabs}
			 				          alignCenter={false}
			 				          //selected={selected}
			 				          //onSelect={this.onSelect}
			 				        />
			 				</GridItem>
				 		</GridContainer>
			 		</GridItem>
			 		: null}
			 		{testSeriesList && testSeriesList["test_types"] &&  testSeriesList["test_types"].length > 0 ?
			 		<GridItem xs={12} sm={12} md={12} className={classes.containerHeaderSticker} style={{marginTop: "20px", marginBottom: "-15px"}}>
				 				<h4 style={{fontSize: "0.9rem", margin: "5px", color: "white", transform: "skew(-15deg)"}} className={classNames(classes.robotoFontRegular)}><strong>Quick Insights</strong></h4>			 			
			 		</GridItem>
			 		: null}
			 		{testSeriesList && testSeriesList["test_types"] &&  testSeriesList["test_types"].length > 0 ?
			 		<GridItem xs={12} sm={12} md={12} className={classes.programTestContainer}>
			 			<GridContainer style={{paddingTop: "20px", paddingBottom: "10px"}}>
			 				<GridItem xs={12} sm={12} md={6} style={{textAlign: "center", margin: "auto", padding: "20px", width:"100%", overflow: "auto"}}>
				 				{quickInsightsData && quickInsightsData.length > 0 ?
				 				<LineChart width={600} height={260} className={classes.smallRobotoFontRegular}>
			 				        <CartesianGrid strokeDasharray="3 3" />
			 				        <XAxis dataKey="category" type="category" allowDuplicatedCategory={true} />
			 				        <YAxis dataKey="value" />
			 				        <Tooltip />
			 				        <Legend verticalAlign="top" height={36} iconType="square"/>
			 				        {quickInsightsData.map(s => (
			 				          <Line dataKey="value" data={s.data} name={s.name} key={s.name} />
			 				        ))}
				 				</LineChart>
				 				: <p className={classes.smallRobotoFontRegular}>No data to display.</p>}
				 			</GridItem>
				 			<GridItem xs={12} sm={12} md={4}>
				 				<div >
				                  <h3 className={classes.robotoFontRegular}><strong>Test Series</strong></h3>
				                </div>
				 				{testSeriesList && testSeriesList["test_types"] &&  testSeriesList["test_types"].map(testType => (
					 				<div
					 				  className={
					 				    classes.checkboxAndRadio +
					 				    " " +
					 				    classes.checkboxAndRadioHorizontal
					 				  }
					 				>
					 				  <FormControlLabel
					 				    control={
					 				      <Radio
					 				        checked={this.state.selectedTestTypeForQuickInsights === (testType.id).toString()}
					 				        onChange={this.handleInsightTestTypeChange}
					 				        value={(testType.id).toString()}
					 				        name="radio button enabled"
					 				        aria-label="A"
					 				        icon={
					 				          <FiberManualRecord
					 				            className={classes.radioUnchecked}
					 				          />
					 				        }
					 				        checkedIcon={
					 				          <FiberManualRecord style={{color: "#5d869b", borderColor: "#5d869b"}} className={classes.radioChecked} />
					 				        }
					 				        classes={{
					 				          checked: classes.radio
					 				        }}
					 				      />
					 				    }
					 				    classes={{
					 				      label: classes.smallRobotoFontRegular
					 				    }}
					 				    label={testType.name}
					 				  />
					 				</div>
				 				))}
					      		{/*<MUIDataTable
				      			  title={<p className={classes.subtitleStyle} >Test Results Table</p>}
				      			  data={this.state.testMarksData}
				      			  columns={testMarksColumns}
				      			  options={options}
				      			/>*/}
				      		</GridItem>
				 		</GridContainer>
			 		</GridItem>
			 		:null}
			 		{/*<GridItem xs={12} sm={12} md={4}>
			 			<h1 className={classes.robotoFontRegular} >Next Upcoming Test</h1>
			 				  	<div className={classes.boxedContainer} style={{width: "350px"}}>
			 					  	<GridContainer  className={classes.clipStyle}>
			 					  		<GridItem xs={12} sm={12} md={12} className={classes.clipHeaderStyle}>
			 					  			<h1 className={classNames(classes.robotoFontRegular,classes.clipHeader)} >Test Name</h1>
			 					  		</GridItem>
			 					  		<GridItem xs={12} sm={12} md={6} className={classes.clipBodyStyle}>
			 			      				<img
			 			      				    className={
			 			      				      classes.imgRounded +
			 			      				      " " +
			 			      				      classes.imgFluid
			 			      				    }
			 			      				    style={{width: "40%", margin: "30px", marginBottom: "0px"}}
			 			      				    src={exam_status_unattempted_icon}
			 			      				   alt=""
			 			      				  />
			 			       				<Button
			 						          color="success"
			 						          style={{
			 						          //width: "100%",
			 						          margin: "10px",
			 						          marginBottom: "0px",
			 						          backgroundColor: "#57849b", 
			 						          padding: "6px 16px",
			 						      	  }} 
			 						          >
			 				                   Start Test
			 				                </Button>				
			 			      			</GridItem>
			 			      			<GridItem xs={12} sm={12} md={6} className={classes.clipBodyStyle}>
			 			      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular)}>Identifier: <strong>23</strong></h1>
			 			      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Status: <strong>Live</strong></h1>
			 			      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Topic: <strong>Geometry</strong></h1>
			 			      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Sub-Topic: <strong>Ellipse</strong></h1>
			 			      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular,classes.zeroMarginTop)}>Date: <strong>23/02/2019</strong></h1>
			 			      				<h1 style={{fontSize: "0.8rem"}} className={classNames(classes.robotoFontRegular, classes.zeroMarginTop)}>Questions: <strong>21</strong></h1>
			 			      			</GridItem>
			 			      			<GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}} className={classes.clipBodyStyle}>
			 			      				<h4>Timer Component</h4>
			 				            </GridItem>
			 						</GridContainer>
			 					</div>
			 		</GridItem>
			 		<GridItem xs={12} sm={12} md={8} >
			 			<h1 className={classes.robotoFontRegular} >Recent Attempted Tests</h1>
			 			<ScrollMenu
	 				          data={listTabs}
	 				          alignCenter={false}
	 				          //selected={selected}
	 				          //onSelect={this.onSelect}
	 				        />
			 		</GridItem>*/}
			 		{/*<GridItem xs={12} sm={12} md={12}>
			 			<h1 className={classes.robotoFontRegular} >To Do Practice Sheets</h1>
			 		</GridItem>
			 		<GridItem xs={12} sm={12} md={12}>
			 			
			 				<ScrollMenu
	 				          data={listTabs}
	 				          arrowLeft={ArrowLeft}
	 				          arrowRight={ArrowRight}
	 				          alignCenter={false}
	 				          //selected={selected}
	 				          //onSelect={this.onSelect}
	 				        />
					 	
			 		</GridItem>
			 		<GridItem xs={12} sm={12} md={12} >
			 			<h1 className={classes.robotoFontRegular} >Progress Overview</h1>
			 			<div className={classes.fullBoxedContainer}> 
			 			  	<p>kyber phakthunwa</p>
			 			</div>
			 		</GridItem>*/}

			 		
			 	{/*	<GridItem xs={12} sm={12} md={12}>
					 <div style={{height: "20vh", backgroundColor: "white"}}>
					 	



				        <h2 style={{textAlign: "center", padding: "20%", color: "darkgreen"}}> Overview Page Coming Soon..</h2>
					 </div>
			 		</GridItem>*/}
			 	</GridContainer>
			 </div>	
			);
	}

}

export default withStyles(myHomeRegionStyle)(MyHomeRegion);
