import React from "react";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class TestStatus extends React.Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  render() {
    const { name, value, index, change,formControlWidth } = this.props;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select value={value} name={name} onChange={change} 
          style={{fontSize: 'inherit'}}>
            <MenuItem key={0} style={{fontSize: "14px"}} value="Completed">Completed</MenuItem>
            <MenuItem key={1} style={{fontSize: "14px"}} value="Incomplete">Incomplete</MenuItem>
            <MenuItem key={2} style={{fontSize: "14px"}} value="Processing">Processing</MenuItem>
            <MenuItem key={2} style={{fontSize: "14px"}} value="Archived">Archived</MenuItem>
        </Select>
      </FormControl>
    );
  }

}


export default TestStatus;