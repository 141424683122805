import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Button from "CustomButtons/Button.jsx";
import $ from 'jquery';


const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MUIDataTableToolbarSelect:{
        title:{
          display: "none"
        },
        root:{
          backgroundColor: "none",
          boxShadow: "none",
          justifyContent: "normal"
        }
      },
      MuiTableCell:{
        root:{
          padding: "4px 4px 4px 4px",
          textAlign: "center"
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader:{
          zIndex: "none"
        },
        toolButton:{
          width: "fit-content",
          margin: "auto"
        }
      },
    typography: {
      useNextVariants: true,
    },
    },
    typography: {
      useNextVariants: true,
    }
  })


class UnattemptedStudentsList extends React.Component {

  state = {
    testResults:[],
    testResultsTableColumns: [],
  }

  componentDidMount(){

    const scheduled_test_paper_id = this.props.paperId;
    if(scheduled_test_paper_id){ //Process of duplicate only if test_id exists
       $.ajax({
          type: 'GET',
          url: getBaseURL() + '/get-unattempted-students?scheduled_test_paper_id=' + scheduled_test_paper_id,
        }).done((data, status, jqXHR)=>{
          this.populateTestResultDataTable(data);
        }).fail((jqXHR, textStatus, errorThrown) => {
            //TODO Empty
        })
    }
  }

  populateTestResultDataTable(data){
    var testResults=[];
    var results = data["results"];
    if(results.length > 0){
      //Push actual results data to table
      for(var i=0; i < results.length ; i++){
        var resultRow = results[i]; 
        var testResultRow = []
        testResultRow.push(resultRow["username"]);
        testResultRow.push(resultRow["fullname"]);
        testResultRow.push(resultRow["branch_name"]);
        testResultRow.push(resultRow["section_name"]);
        testResults.push(testResultRow);
      }

      //Push column names of table
      var testResultsTableColumns = []
      testResultsTableColumns.push({name: "Username"});
      testResultsTableColumns.push({name: "Full Name"});
      testResultsTableColumns.push({name: "Campus"});
      testResultsTableColumns.push({name: "Section"});
      this.setState({testResultsTableColumns,testResults});
    }
  }

  render () {
    const tableTitleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      padding: "0px", margin: "0px",
      paddingTop: "10px"
    };
    const testResultsTableOptions = {
      filter: false,
      selectableRows: 'none',
      search: true,
      print: false,
      download: false,
      viewColumns: false,
      responsive: 'stacked',
      expandableRows: false,
      downloadOptions: {
        filename: this.props.reportName+ "_UnattemptedStudents_"+ (new Date().toISOString().substring(0,16))+".csv",
        separator: ',',
      },
      download: true,
      onDownload: (buildHead, buildBody, columns, data) => 
        buildHead(columns) + buildBody(data),
    }
    return (
      <React.Fragment>
          <MuiThemeProvider theme={getMuiThemeForTable}>
            <MUIDataTable 
              title={<p style={tableTitleStyle} >Unattempted Students List</p>}
              options={testResultsTableOptions}
              columns={this.state.testResultsTableColumns}  
              data={this.state.testResults}              
            />
          </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default UnattemptedStudentsList;
