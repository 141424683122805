import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import $ from 'jquery';

class Programs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      programs: [],
    };
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    //index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired
  };

  componentDidMount(){

    $.ajax({
      type: 'GET',
      url: getBaseURL() + '/global_programs'
    }).done((response, status, jqXHR)=>{
        var programs = JSON.parse(response)["programs"];
        this.setState({
          programs: programs
        });
    });
  }

  getIdForLOV(value){
    const programs = this.state.programs;
    const filteredPrograms = programs.filter(program => program.short_name == value);
    if (filteredPrograms && filteredPrograms.length > 0){
     return {id: filteredPrograms[0].id};
    }
    return;
  }

  getValueFromId(id){
    const programs = this.state.programs;
    const filteredPrograms = programs.filter(program => program.id == id);
    if (filteredPrograms && filteredPrograms.length > 0){
     return filteredPrograms[0].short_name;
    }
    return;
  }

  render() {
    const { name, value, valueId, change, formControlWidth} = this.props;
    const { programs} = this.state;
    return (
      <FormControl style={ formControlWidth ? {width: formControlWidth} : null}>
        <Select value={value ? value : valueId ? this.getValueFromId(valueId) : null} name={name} onChange={(e) => change(e,this.getIdForLOV(e.target.value))} 
          style={{fontSize: 'inherit'}}>
          { this.state.programs.map((program, index) =>
            <MenuItem key={index} style={{fontSize: "14px"}} value={program.short_name}>{program.short_name}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

}


export default Programs;