import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/Lock";
import Person from "@material-ui/icons/Person";
import PhoneAndroid from "@material-ui/icons/PhoneAndroid";
import VpnKey from "@material-ui/icons/VpnKey";
import Assignment from "@material-ui/icons/Assignment";


/*import People from "@material-ui/icons/People";*/
// core components
import Header from "Header/Header.jsx";
import HeaderLinks from "Header/HeaderLinks.jsx";
import Footer from "Footer/Footer.jsx";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Button from "CustomButtons/Button.jsx";
import Card from "Card/Card.jsx";
import CardBody from "Card/CardBody.jsx";
import CardHeader from "Card/CardHeader.jsx";
import CardFooter from "Card/CardFooter.jsx";
import CustomInput from "CustomInput/CustomInput.jsx";

import loginPageStyle from "styles/LoginPageStyle.jsx";

//import image from "assets/img/bg7.jpg";
//import brand_black from "assets/img/images/brand_black.png";
import brand_white from "assets/img/images/brand_white.png";
import {isUserSessionActive} from "components/helpers/AuthenticationHelper.jsx";
import ErrorLogo from "@material-ui/icons/Error";
import {validateEmailFormat,validateUsernameFormat,validateNumberFormat} from "components/helpers/InputFormatHelper.jsx";



import $ from 'jquery';

const InlinefieldErrorComponent = (props) => {
  const {errorMessage, classes} =  props;
  return(
    <div >
    {errorMessage ? 
      <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8em", marginTop: "-20px"}} className={classes.robotoFontRegular}>
      {errorMessage}
      </p> 
      : null}
    </div>
    );

}

class UserRegistration extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username : "",
      password : "",
      password_confirmation: "",
      fullname: "",
      email: "",
      phone: "",
      usernameErrorMsg : "",
      passwordErrorMsg : "",
      passwordConfirmationErrorMsg: "",
      fullnameErrorMsg: "",
      emailErrorMsg: "",
      phoneErrorMsg: "",
      registrationErrorMessage: ""
    };
  }
  componentDidMount() {
    if(isUserSessionActive())
    {
      this.props.history.push('/home');
      return
    }

    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ 
          cardAnimaton: "",
          //"username" : this.state.username,
          //"password" : this.state.password
        });
      }.bind(this),
      700
    );
  }

  handleInput = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var obj ={};
    obj[id] = e.target.value;
    this.setState(obj);
    
    if(id=="fullname"){
      this.validateFullName(obj[id]);
    }
    if(id=="username"){
      this.validateUsername(obj[id]);
    }
    if(id=="email"){
      this.validateEmail(obj[id]);
    }
    if(id=="phone"){
      this.validatePhone(obj[id]);
    }
    if(id=="password"){
      this.validatePasswordAndConfirmation(obj[id],"");
    }
    if(id=="password_confirmation"){
      this.validatePasswordAndConfirmation("",obj[id]);
    }
  }

  // handleEnterKeyPress = (e) => {
  //   //e.preventDefault();
  //   if(e.key === 'Enter'){
  //     //this.handleLoginRequest();
  //   }
  // }

  validateFullName(fullname){
    if(!fullname){
      fullname = this.state.fullname;
    }
    if(!fullname.trim()){
      this.setState({fullnameErrorMsg: "Full Name is Required field"})
      return true
    }else{
      this.setState({fullnameErrorMsg: ""})
    }
    return false
  }

  validateUsername(username){
    if(!username){
      username = this.state.username;
    }
    //UserName validation
    if(!username.trim()){
      this.setState({usernameErrorMsg: "Username is Required field"})
      return true
    }else{
      if(username.trim().length < 8){
            this.setState({usernameErrorMsg: "Username needs to have minimum 8 characters"})
            return true
        }else{
          if(!validateUsernameFormat(username.trim())){
               this.setState({usernameErrorMsg: "Username cannot have any special characters."})
               return true
           } else{
             this.setState({usernameErrorMsg: ""})
           }
        }
    }
    return false
  }

  validateEmail(email){
    if(!email){
      email = this.state.email;
    }
    //Email validation
    if(!email.trim()){
      this.setState({emailErrorMsg: "Email is Required field"})
      return true
    }else{
      if(!validateEmailFormat(email.trim())){
        this.setState({emailErrorMsg: "Provide valid Email address"})
        return true
      } else{
        this.setState({emailErrorMsg: ""})
      }
    }
    return false
  }

  validatePhone(phone){
    if(!phone){
      phone = this.state.phone;
    }
    //Phone Validation
    if(!phone.trim()){
      this.setState({phoneErrorMsg: "Mobile contact is Required field"})
      return true
    }else{
      if(phone.trim().length < 8){
        this.setState({phoneErrorMsg: "Please provide valid mobile contact"})
        return true
      }else{
        if(!validateNumberFormat(phone.trim())){
          this.setState({phoneErrorMsg: "Provide valid mobile contact. Do not add '+91' code"})
          return true
        } else{
          this.setState({phoneErrorMsg: ""})
        }
      }
    }
    return false
  }

  validatePasswordAndConfirmation(password,password_confirmation){
    if(!password){
      password = this.state.password;
    }
    if(!password_confirmation){
      password_confirmation = this.state.password_confirmation;
    }
    //Password Validation
    if(!password.trim()){
      this.setState({passwordErrorMsg: "Password is Required field"})
      return true
    }else{
      if(password.trim().length < 7){
      this.setState({passwordErrorMsg: "Password needs to have minimum 8 characters"})
      return true
      }else{
        this.setState({passwordErrorMsg: ""})
      }
    }
    //Password confirmation
    if (password.trim() != password_confirmation.trim()){
      this.setState({passwordConfirmationErrorMsg: "Password did not match"})
      return true
    }else{
        this.setState({passwordConfirmationErrorMsg: ""})
      }
    return false
  }

  validateUserDataForRegistration(){

    var validationFail = false
    if(this.validateFullName("") ){
      validationFail = true
    }
    if(this.validateUsername("") ){
      validationFail = true
    }
    if(this.validateEmail("") ){
      validationFail = true
    }
    if(this.validatePhone("") ){
      validationFail = true
    }
    if(this.validatePasswordAndConfirmation("","") ){
      validationFail = true
    }
    return !(validationFail)
  }


  handleUserRegistration = (event) => {
    event.preventDefault();
    if(!this.validateUserDataForRegistration()){
      return
    }

    var fullname = this.state.fullname;
    var username = this.state.username;
    var email = this.state.email;
    var phone = this.state.phone;
    var password = this.state.password;
    var password_confirmation = this.state.password_confirmation;

    var jsonStudentData = {}
    jsonStudentData["name"]=fullname;
    jsonStudentData["username"]=username;
    jsonStudentData["email"]=email;
    jsonStudentData["phone"]=phone;
    jsonStudentData["password"]=password;
    jsonStudentData["password_confirmation"]=password_confirmation;

    $.ajax({
      type: 'POST',
      url: getBaseURL() + '/register_user.json',
      data: JSON.stringify(jsonStudentData),
      contentType: 'application/json; charset=utf-8',
    }).done((response, status, jqXHR)=>{
        
         this.props.history.push('/login?registration=success');
        //
    }).fail((jqXHR, textStatus, errorThrown) => { 
        this.setState({registrationErrorMessage: jqXHR["responseJSON"] && jqXHR["responseJSON"].message ?  jqXHR["responseJSON"].message : "Error Message could not be retrieved."});
    })  
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "linear-gradient(rgb(255, 255, 255), rgb(32, 54, 66))",//"url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        > 
          <div className={classes.container} style={{paddingTop: "5vh"}}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <img
                        name="home"
                        src={brand_white}
                        alt="..."
                        style={{height: "50px"}}
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.robotoFontRegular} >User Registration</h4>
                      {this.state.registrationErrorMessage ? 
                        <div >
                        <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit", whiteSpace: "normal"}} className={classes.robotoFontRegular}>{this.state.registrationErrorMessage}</p> 
                        </div>
                        : null
                      }
                      <CustomInput
                        labelText="Full Name"
                        id="fullname"
                        formControlProps={{
                          fullWidth: true
                        }}

                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Assignment className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          value: this.state.fullname,
                          onChange: this.handleInput,
                          //onKeyPress: this.handleEnterKeyPress
                        }}   
                      />
                      <InlinefieldErrorComponent errorMessage={this.state.fullnameErrorMsg} classes={classes}/>
                      <CustomInput
                        labelText="User Name"
                        id="username"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Person className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          value: this.state.username,
                          onChange: this.handleInput,
                          //onKeyPress: this.handleEnterKeyPress
                        }}   
                      />
                      <InlinefieldErrorComponent errorMessage={this.state.usernameErrorMsg} classes={classes}/>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          value: this.state.email,
                          onChange: this.handleInput,
                          //onKeyPress: this.handleEnterKeyPress
                        }}   
                      />
                      <InlinefieldErrorComponent errorMessage={this.state.emailErrorMsg} classes={classes}/>
                      <CustomInput
                        labelText="Mobile Contact"
                        id="phone"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <PhoneAndroid className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          value: this.state.phone,
                          onChange: this.handleInput,
                          //onKeyPress: this.handleEnterKeyPress
                        }}   
                      />
                      <InlinefieldErrorComponent errorMessage={this.state.phoneErrorMsg} classes={classes}/>
                      <CustomInput
                        labelText="Password"
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <VpnKey
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          ),
                          value: this.state.password,
                          onChange: this.handleInput,
                          //onKeyPress: this.handleEnterKeyPress
                        }}
                      />
                      <InlinefieldErrorComponent errorMessage={this.state.passwordErrorMsg} classes={classes}/>
                      <CustomInput
                        labelText="Confirm Password"
                        id="password_confirmation"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          ),
                          value: this.state.password_confirmation,
                          onChange: this.handleInput,
                          //onKeyPress: this.handleEnterKeyPress
                        }}
                      />
                      <InlinefieldErrorComponent errorMessage={this.state.passwordConfirmationErrorMsg} classes={classes}/>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button style={{background: "#41525c"}} size="lg" onClick={this.handleUserRegistration}>
                          Register
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(UserRegistration);
