import React from "react";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Grid from "@material-ui/core/Grid";
import Button from "CustomButtons/Button.jsx";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CheckCircle from "@material-ui/icons/CheckCircle";


		const style={
			 padding: "5px 5px", 
			 minWidth: "48px",
			 borderRadius: "0.2rem",
			 boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 0px 1px, rgba(0, 0, 0, 0.3) 0px 3px 1px -2px, rgba(0, 0, 0, 0.3) 0px 1px 5px 0px",
			 borderRadius:"10",
			 borderWidth: "1",
			 borderColor: "black",
			 color: "rgb(60, 72, 88)"
			 };
		const selectedStyle={
			 padding: "5px 5px", 
			 minWidth: "48px",
			 borderRadius: "0.2rem",
			 boxShadow: "rgba(125, 24, 24, 1) 0px 0px 0px 4px, rgba(125, 24, 24, 1) 0px 3px 1px -2px, rgba(125, 24, 24, 1) 0px 1px 5px 0px",
			 borderRadius:"10",
			 borderWidth: "1",
			 color: "rgb(60, 72, 88)"
			};
		const style2={
			fontWeight: "400",
			fontFamily: "serif",
			marginBottom: "0px",
			color: "rgb(60, 72, 88)",
			fontSize: "14px"
			};
		const style3={
			fontWeight: "400",
			fontFamily: "serif",
			color: "rgb(60, 72, 88)",
			fontSize: "14px",
			lineHeight: "1.55em",
			marginTop: "10px"
			};

 export default  class TestNavigator extends React.Component {

  	range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);

	shouldComponentUpdate(nextProps) {
    	return (this.props.currentQuestionNumber !== nextProps.currentQuestionNumber || 
    			this.props.data !== nextProps.data || this.props.navfocusSubjectIndex !== nextProps.navfocusSubjectIndex);
	}

render() {
	const { data, navToQ, currentQuestionNumber, navfocusSubjectIndex, handleNavfocusSubject} = this.props;
	const selectedButtonStyle = {
		paddingTop: "8px",
		paddingBottom: "8px",
		paddingLeft: "15px",
		paddingRight: "15px",
		textTransform: "capitalize",
		background: "#3f51b5",
    	lineHeight: "20px",
    	borderRadius: "3px",
    	margin: "2px"
	};
	const buttonStyle = {
		paddingTop: "8px",
		paddingBottom: "8px",
		paddingLeft: "15px",
		paddingRight: "15px",
		textTransform: "capitalize",
		backgroundColor: "white",
    	lineHeight: "20px",
    	borderRadius: "3px",
    	margin: "2px",
    	border: "1px solid #3f51b580",
    	color: "#3f51b5"
	};

	
	return(
		<div>
			<GridContainer style={{margin: "auto"}}>
				<GridItem  xs={12} sm={12} md={12} lg={12}>
					<h5 style={style3}>Questions Overview</h5>
					{/*<Tabs
			            value={navfocusSubjectIndex}
			            onChange={handleNavfocusSubject}
			            indicatorColor="primary"
			            textColor="primary"
			            fullWidth
			          >
			          {data.map((item, index) => (
						<Tab key={index} label={item.subject} style={{minWidth: "100px"}}/>
					))}	
		          	</Tabs>*/}
		          	<div style={{textAlign: "center"}}>
		          	 {data.map((item, index) => (
			          <Button key={index} onClick={handleNavfocusSubject} id={item && item.patterns && item.patterns[0] ?  item.patterns[0]["first_question"] : ""} name={index} style={navfocusSubjectIndex == index ? selectedButtonStyle :buttonStyle}>{item.subject ? item.subject.toLowerCase() : ""}</Button>
			          ))}
					</div>
				</GridItem>

				{data.map((subjectItem, index) => (
					 navfocusSubjectIndex==index && 
					<GridItem key={index}  xs={12} sm={12} md={12} lg={12} style={{height : "78vh", "overflowY": "scroll"}}>
						<h5 style={style3}>Questions - {subjectItem.subject}</h5>
						{subjectItem.patterns.map((item, index) => (
						<div key={index}>
						<h5 style={style3}>{item.pattern}</h5>
						<Grid container spacing={8}>
							{this.range(item["first_question"],item["last_question"]+1).map((item, index) => (
							<Grid key={index} item  >
								<Button id={item} 
								style={item==currentQuestionNumber ? selectedStyle : style} 
								// disabled={item==currentQuestionData["question_no"] ? true : false}
								size={"sm"} 
								color={'transparent'}
								onClick={navToQ}><p id={item} style={style2}>{item+' '} 
								</p></Button>
							</Grid>
							))}	
						</Grid>
						<hr/>
						</div>
						))}
					</GridItem>
				))}	
			</GridContainer>	
		</div>
		);
}

}