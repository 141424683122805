import ReactDOM from "react-dom";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import regeneratorRuntime from "regenerator-runtime";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Button from "CustomButtons/Button.jsx";
import CustomInput from "CustomInput/CustomInput.jsx";
import HorizontalStepper from "components/misc/HorizontalStepper"
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import Avatar from '@material-ui/core/Avatar';

import "react-image-crop/dist/ReactCrop.css";

import MetadReadLogo from 'assets/img/images/metaread_logo.png'

import TextField from '@material-ui/core/TextField';
import { FilePond } from 'react-filepond';

//import "./App.css";
import Snackbar from '@material-ui/core/Snackbar';
//import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import LinearProgress from '@material-ui/core/LinearProgress';

import { Cell, Tooltip, PieChart, Pie, Label,ComposedChart, CartesianGrid,XAxis,YAxis, Legend, Bar} from 'recharts';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import qs from 'query-string';

import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";

import Button2 from '@material-ui/core/Button';

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);



class MetaReadDiagrams extends React.Component {
  
  state = {
    uploadedFile: "",
    uploadedFileName: "",
    uploadStatus: "",
    errorsReported: "",
    snackbarOpen: false,
    time_elapsed: "",
    diagramData: [],
  };

  // componentDidMount(){
  //   if(window.MathJax)
  //   window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
  // }

  // componentDidUpdate(){
  //   if(window.MathJax)
  //   window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
  // }

  handleInputFiles = fileItems => {
      // Set currently active file objects to this.state
      this.setState({
        uploadedFile: fileItems[0].file,
        uploadedFileName: fileItems[0].filename
      });
      
  }

  handleImageUpload = (e) => {
   e.preventDefault();
   const {testId, uploadedFile} = this.state;
    if(!uploadedFile){
      this.setState({errorsReported: "Please provide an Image", snackbarOpen: true})
      return false
    }
    this.setState({diagramData: [],time_elapsed: ""});
    var formData = new FormData();
    formData.append('doc', this.state.uploadedFile);
    this.setState({uploadStatus: "Processing", errorsReported: "Processing", snackbarOpen: true});
      $.ajax({
          type: 'POST',
          url: "/metaread/diagrams",
          data: formData,
          cache: false,
          contentType: false,
          processData: false
        }).done((data, status, jqXHR)=>{
          this.setState({
            uploadStatus: 'DONE',
            errorsReported: data["message"],
            snackbarOpen: true,
            diagramData: data["diagrams"],
            time_elapsed: data["time_elapsed"],
          });
        }).fail((jqXHR, textStatus, errorThrown) => {
          this.setState({
            uploadStatus: 'FAILED',
            errorsReported: 'FAILED',
            snackbarOpen: true
          });
        })
  }

  handleSnackbarClose = (e) => {
    this.setState({snackbarOpen: false})
  }

  logoutUser =(e)=> {
    $.ajax({
      type: 'GET',
      url: getBaseURL() + '/global_users/sign_out'
    }).done((response, status, jqXHR)=>{
      sessionStorage.removeItem('as_username');
      sessionStorage.removeItem('as_isUserAnalyst');
      sessionStorage.removeItem('as_isUserMetaReadAdmin');
      sessionStorage.removeItem('user');
      this.redirectToLoginPage();
    }) 
  }

  redirectToLoginPage(){
    if(this.props.history){
      this.props.history.push("/");
    }else{
      window.location.href = getBaseURL() + "/"
    }
  }

  render() {
    const { errorsReported,uploadedFile,snackbarOpen,diagramData } = this.state;
    const {classes} = this.props;
    return (
      <div style={{minHeight: "100vh", backgroundColor: "white"}}>
        <React.Fragment>
          <div style={{display: "flex", paddingTop: "1%"}}>
          <Button2 
            color="secondary"
            style={{ backgroundColor: "inherit", marginLeft: "auto",
            boxShadow: "none", marginRight: "3%"}}
            onClick={this.logoutUser}
          >
            Log out
          </Button2>
          </div>
      </React.Fragment>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={snackbarOpen && errorsReported}
          onClose={this.handleSnackbarClose}
          key={ 'top' + 'right'}
          severity="warning"
          autoHideDuration={6000}>
          <MySnackbarContentWrapper
            onClose={this.handleSnackbarClose}
            variant="warning"
            message={errorsReported}
          />
        </Snackbar>
        <GridContainer style={{
          backgroundColor: "white",
          paddingBottom: "20px",
          width: "100%", 
          margin: "10px 0px 0px",
          marginTop: "0px",
          height: "100%",
          }}
          spacing={24}>
          <GridItem xs={12} sm={12} md={12} style={{marginBottom: "20px"}} >
            <Avatar style={{
              width: "fit-content",
              height: "fit-content",
              overflow: "visible",
              margin: "auto",
              backgroundColor: "white"
              }}
              >
              <img style={{width: "80%"}} src={MetadReadLogo}/>
            </Avatar>
          </GridItem>
          <GridItem style={{margin: "auto"}} xs={6} sm={6} md={3}>
           <div style={{marginTop: "10px", marginBottom: "10px"}}>
              <div style={{paddingBottom: "10px"}}>
                <span>Upload Image</span>
              </div>  
              <FilePond
                onupdatefiles={this.handleInputFiles}
              />
              <Button
                style={{width: "auto", margin: "auto",
                  backgroundColor: "#3f51b5"}}
                onClick={this.handleImageUpload}>
                     Run
              </Button> 
          </div>
          </GridItem>
          {/*<GridItem style={{margin: "0"}} xs={12} sm={12} md={12}>
          </GridItem>*/}
          <GridItem style={{margin: "auto"}} xs={12} sm={12} md={12}>
          {this.state.time_elapsed && 
          <React.Fragment>
          <p style={{color: "black", textAlign: "center", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
              {"Processing Time: "+this.state.time_elapsed+" seconds"}
          </p>
          {diagramData && diagramData.length > 1 &&
            <p style={{color: "red", textAlign: "center", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
              {"Only first 5 pages of PDF are processed in this demonstration"}
          </p>
          }
          </React.Fragment>
          }
          </GridItem>
          {diagramData && diagramData.length > 0 && diagramData.map((diagrams, pageIndex) => (
            <React.Fragment>
            {diagramData.length > 1 &&
              <GridItem style={{margin: "20px"}} xs={12} sm={12} md={12}>
                <hr style={{width: "80%", borderTop: "1px solid #eeeeee"}}/>
                
                <p style={{color: "black", textAlign: "center", fontWeight: "500px", fontSize: "1rem",fontFamily: "'Noto Serif', serif"}}>
                    {"Processed Page Number: "+(pageIndex+1)}
                </p>
              </GridItem>
            }
            {diagrams && diagrams.length > 0 && diagrams.map((item, index) => (
              <GridItem style={{margin: "20px", width: "fit-content"}}>
              <React.Fragment>
              <div style={{
                border: "1px solid rgb(247,247,247)",
                padding: "2%",
                boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                transition: "all 300ms linear",
                borderRadius: "10px",
                width: "fit-content",
                margin: "auto",
              }}>
                <React.Fragment key={index}>
                  <QuestionImages 
                  src={item} 
                  name={"diag_"+index+"_1"}
                  isEditMode={false}
                  />
                </React.Fragment>
              </div>
              </React.Fragment>
              </GridItem>
            ))}
            </React.Fragment>
          ))}
        </GridContainer>
      </div>
    );
  }
}

export default MetaReadDiagrams;

